import React from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

import Header from '../../component/header';
import PopUpInfo from '../../component/popUpInfo';

import { Usuarios } from '../../entity/usuarios';

import '../../css/style.css';

class ValidarCodigos extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      codigo: '',
      error: false,
      errorText: ''
    }

    this.onChange = this.onChange.bind(this);
    this.onValidar = this.onValidar.bind(this);

  }

  onChange(event) {
    this.setState({ codigo: event.target.value });
  }

  onValidar(event) {
    event.preventDefault();

    this.setState({ loading: true });

    Usuarios.validar({ codigo: this.state.codigo })
      .then(
        valid => {
          if (valid === true) {
            this.setState({ error: true, loading: false, errorText: "Código Validado" });
            setTimeout(() => {
              window.location.href = "/";
            }, 2000);
          }
          else {
            this.setState({ error: true, loading: false, errorText: valid.message });
          }
        }
      );

  }



  render() {
    const AlertloginFailed = <PopUpInfo messageInfo={this.state.errorText} onClose={() => { this.setState({ error: false, errorText: '' }) }} />

    return (
      <div className="main">

        <Header title="Validar Códigos" history={this.props.history}></Header>

        <div style={{ height: 40 }}></div>

        <Container maxWidth="xs">

          <Box p={1} borderRadius={5} style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}>

            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="codigo"
              label="Código"
              name="codigo"
              autoComplete="codigo"
              autoFocus
              inputProps={{ maxLength: 20 }}

              onChange={this.onChange}
            />
            <Button
              fullWidth
              variant="contained"
              disabled={this.state.loading}
              color="primary"
              onClick={this.onValidar}
            >
              Validar Código
          </Button>


            <div style={{ height: 20 }}></div>


            {this.state.error === true ? AlertloginFailed : ''}

            <div style={{ height: 20 }}></div>

          </Box>

        </Container>

      </div>
    );
  }
}

export default ValidarCodigos;