import React from 'react';
import Rating from '../Rating/Rating';
import "./ShowCard.css";

const MAX_RANKING = 5;

const ShowCard = (props) => {

	const {
		id,
		title,
		type,
		strip,
		text,
		image,
		top,
		ranking,
		techSheet
	} = props.info;

	const TopCalification = () => (
		<div className="top-calification">
			<img src="/images/top-calification.png" alt="" />
		</div>
	);

	const SimpleShowCard = () => (
		<div className="mp-show-card_text-sign">
			{
				top && <TopCalification />
			}
			<div className={`lateral ${strip.color}-bg-color`}>
				<span>{strip.text}</span>
			</div>
			<div className="poster-text">
				<div className="poster-text_info">
					<div className="poster-text_info-titles">
						<p className="title">{title}</p>
						<p className="genre">{type}</p>
					</div>
					<div className="poster-text_info-data">
						{
							techSheet.address ? 
								<p>
									{
										techSheet.address.place && <span className="bold">{techSheet.address.place}</span>
									}
									{
										techSheet.address.time && techSheet.address.time.length > 0 && <span> | {techSheet.address.time}</span>
									}
									{
										techSheet.address.calification && <span> | {techSheet.address.calification}</span>
									}
								</p> 
								: ''
						}
					</div>
				</div>
				<div className="poster-text_interaction">
					<Rating 
						completed={ranking === MAX_RANKING ? true : false} 
						filled={ranking < MAX_RANKING ? ranking : false}
					/>
					<span onClick={props.clickModal} className="more-info-btn">más info</span>
				</div>
			</div>
		</div>
	);

	const PrincipalShowCard = () => (
		<div className="mp-show-card_text big-poster">
			{
				top && <TopCalification />
			}
			<div className={`big-poster_belt ${strip.color}-bg-color`}>
				<div>
					<span>{strip.text}</span>
				</div>
			</div>
			<div className="big-poster_info">
				<div>
					<p className="title">{title}</p>
					<p className="description">{text}</p>
					<div className="big-poster_info-data">
						<p className="address">{techSheet.address.place} | {techSheet.address.time} | {techSheet.address.calification}</p>
					</div>
				</div>
				<div className="big-poster_info-interaction">
					<Rating 
						completed={ranking === MAX_RANKING ? true : false} 
						filled={ranking < MAX_RANKING ? ranking : false}
					/>
					<span onClick={props.clickModal} className="more-info-btn">más info</span>
				</div>
			</div>
			<div className="big-poster_interaction">
				<div className="big-poster_interaction-button primary-bg-color">
					<img src="/images/products/double-arrow-left.png" alt="" />
				</div>
			</div>
		</div>
	);
	
	return (
		<div id={id} className={`mp-show-card ${props.gridClass}`}>
			<div className="mp-show-card_image">
				<img src={image} alt="" />
			</div>
			{
				props.gridClass === 'full-card' ? 
					<PrincipalShowCard />
					:
					<SimpleShowCard />
			}
		</div>
	)
}

export default ShowCard;
