import React from 'react';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import IconButton from '@material-ui/core/IconButton';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import EmailIcon from '@material-ui/icons/Email';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import EditIcon from '@material-ui/icons/Edit';
import AutorenewIcon from '@material-ui/icons/Autorenew';

import moment from 'moment';

import '../../css/style.css';
import { Container, TextField } from '@material-ui/core';
import { Usuarios } from '../../entity/usuarios';

import PopUpConfirmacion from '../../component/popUpConfirmacion';
import PopUpInfo from '../../component/popUpInfo';

class ListadoParaCentros extends React.Component {

  constructor(props) {
    super(props);

    this.hiddenFileInput = React.createRef();


    this.state = {
      rowsPerPage: 10,
      page: 0,

      buscarCodigo: '',
      buscarGenerico: '',
      buscarEmpresa: '',
      buscarCentro: '',
      buscarEvento: '',
      buscarValidado: 'SI',
      buscarUsado: 'TODOS',
      buscarFecha: '',
      buscarHora: '',
      buscarNombre: '',
      buscarEmail: '',
      buscarLibre: 'TODOS',
      showPanelBusquedas: false,

      error: false,
      errorText: '',

      showConfirmation: false,
      elementSeleccionado: {},

      dataChanged: []

    }

    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);

    this.onChangeInput = this.onChangeInput.bind(this);
    this.onExport = this.onExport.bind(this);
    this.onCodigoUsado = this.onCodigoUsado.bind(this);

    this.onHideConfirmation = this.onHideConfirmation.bind(this);

    this.recargarDatos = this.recargarDatos.bind(this);

    this.showBusquedas = this.showBusquedas.bind(this);

    let searchDelay = {};

  }

  componentDidMount() {
  }

  handleChangePage(newPage) {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage(event) {
    this.setState({ rowsPerPage: parseInt(event.target.value), page: 0 });
  };

  recargarDatos() {
    this.props.onRefresh({
      codigo: this.state.buscarCodigo,
      generico: this.state.buscarGenerico,
      empresa: this.state.buscarEmpresa,
      centro: this.state.buscarCentro,
      evento: this.state.buscarEvento,
      validado: this.state.buscarValidado,
      usado: this.state.buscarUsado,
      fecha: this.state.buscarFecha,
      hora: this.state.buscarHora,
      nombre: this.state.buscarNombre,
      email: this.state.buscarEmail,
      libre: this.state.buscarLibre,
    });
  }

  onChangeInput(event) {

    this.setState({ [event.target.name]: event.target.value });

    clearTimeout(this.searchDelay);

    this.searchDelay = setTimeout(() => {
      this.recargarDatos();
    }, 1500);


  }

  onExport(event) {
    this.props.toExcel({
      codigo: this.state.buscarCodigo,
      generico: this.state.buscarGenerico,
      empresa: this.state.buscarEmpresa,
      centro: this.state.buscarCentro,
      evento: this.state.buscarEvento,
      validado: this.state.buscarValidado,
      usado: this.state.buscarUsado,
      fecha: this.state.buscarFecha,
      hora: this.state.buscarHora,
      nombre: this.state.buscarNombre,
      email: this.state.buscarEmail,
      libre: this.state.buscarLibre
    });
  }


  onCodigoUsado(element) {

    // si aún no está validado, no se puede continuar
    if (element.estavalidado === 'NO') {
      this.setState({ error: true, errorText: 'Esta entrada aún no está canjeada', showConfirmation: false });
      return;
    }


    // si ya está usado, no continuo
    if (element.estausado !== 'NO') {
      this.setState({ error: true, errorText: 'Esta entrada ya está Emitida', showConfirmation: false });
      return;
    }

    let formulario = {
      idcodigos: element.idcodigos,
      fechauso: moment().format('YYYY-MM-DD HH:mm:ss'),
    };


    this.setState({ error: true, errorText: 'Solicitud tramitada', showConfirmation: false });

    Usuarios.saveCodigo(formulario)
      .then(() => {
        this.recargarDatos();
      });

  }

  onHideConfirmation() {
    this.setState({ error: false, showConfirmation: false });
  }

  showBusquedas() {
    this.setState({ showPanelBusquedas: !this.state.showPanelBusquedas });
  }

  render() {

    const emptyRows = 1;

    const AlertloginFailed = <PopUpInfo messageInfo={this.state.errorText} onClose={() => { this.setState({ error: false, errorText: '' }) }} />

    const Buscador =
      (<Box display='flex' flexWrap='wrap'>

        <Box display='flex' style={{ width: '100%' }}>

          <ButtonGroup color="primary" aria-label="outlined primary button group">

            <Button
              variant="contained"
              color="primary"
              onClick={() => { this.showBusquedas(); }}
            >
              Buscar
          </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={this.onExport}
            >
              A Excel
          </Button>

          </ButtonGroup>

        </Box>

        <Collapse in={this.state.showPanelBusquedas} >

          <Box display='flex' style={{ width: '100%' }}>
            <TextField
              fullWidth
              style={{ margin: 5 }}
              margin="dense" size='small' variant='outlined'
              id="buscarCodigo" label="Codigo" name="buscarCodigo"
              value={this.state.buscarCodigo}
              onChange={this.onChangeInput}
            />
            <TextField
              fullWidth
              style={{ margin: 5 }}
              margin="dense" size='small' variant='outlined'
              id="buscarGenerico" label="Código Genérico" name="buscarGenerico"
              value={this.state.buscarGenerico}
              onChange={this.onChangeInput}
            />
            <TextField
              fullWidth
              style={{ margin: 5 }}
              margin="dense" size='small' variant='outlined'
              id="buscarEmpresa" label="Empresa" name="buscarEmpresa"
              value={this.state.buscarEmpresa}
              onChange={this.onChangeInput}
            />

            <TextField
              fullWidth
              style={{ margin: 5 }}
              margin="dense" size='small' variant='outlined'
              id="buscarEvento" label="Evento" name="buscarEvento"
              value={this.state.buscarEvento}
              onChange={this.onChangeInput}
            />

          </Box>

          <Box display='flex' style={{ width: '100%' }}>

            <FormControl variant="outlined" fullWidth size="small" margin='dense' style={{ margin: 5 }}>
              <Select
                labelId="buscarValidado"
                id="buscarValidado"
                name="buscarValidado"
                value={this.state.buscarValidado}
                onChange={this.onChangeInput}
              >
                <MenuItem value="TODOS"><em>Canjeado (Todos)</em></MenuItem>
                <MenuItem value='SI' key='SI'>Si</MenuItem>
                <MenuItem value='NO' key='NO'>No</MenuItem>
              </Select>
            </FormControl>

            <FormControl variant="outlined" fullWidth size="small" margin='dense' style={{ margin: 5 }}>
              <Select
                labelId="buscarUsado"
                id="buscarUsado"
                name="buscarUsado"
                value={this.state.buscarUsado}
                onChange={this.onChangeInput}
              >
                <MenuItem value="TODOS"><em>Emitido (Todos)</em></MenuItem>
                <MenuItem value='SI' key='SI'>Si</MenuItem>
                <MenuItem value='NO' key='NO'>No</MenuItem>
              </Select>
            </FormControl>

            <FormControl variant="outlined" fullWidth size="small" margin='dense' style={{ margin: 5 }}>
              <Select
                labelId="buscarLibre"
                id="buscarLibre"
                name="buscarLibre"
                value={this.state.buscarLibre}
                onChange={this.onChangeInput}
              >
                <MenuItem value="TODOS"><em>Libre (Todos)</em></MenuItem>
                <MenuItem value='SI' key='SI'>Si</MenuItem>
                <MenuItem value='NO' key='NO'>No</MenuItem>
              </Select>
            </FormControl>

            <TextField
              fullWidth
              id="buscarFecha"
              label="Fecha"
              name="buscarFecha"
              autoComplete="buscarFecha"
              size='small'
              value={this.state.buscarFecha}
              type='date'
              variant='outlined'
              InputLabelProps={{ shrink: true }}
              style={{ margin: 5 }}
              margin='dense'
              onChange={this.onChangeInput}
            />

            <TextField
              fullWidth
              id="buscarHora"
              label="Hora"
              name="buscarHora"
              autoComplete="buscarHora"
              size='small'
              value={this.state.buscarHora}
              variant='outlined'
              style={{ margin: 5 }}
              margin='dense'
              onChange={this.onChangeInput}
            />

          </Box>

          <Box display='flex' style={{ width: '100%' }}>

            <TextField
              fullWidth
              id="buscarNombre"
              label="Nombre"
              name="buscarNombre"
              autoComplete="buscarNombre"
              size='small'
              value={this.state.buscarNombre}
              variant='outlined'
              style={{ margin: 5 }}
              margin='dense'
              onChange={this.onChangeInput}
            />

          </Box>


        </Collapse>

      </Box>);


    return (
      <div>
        <Container maxWidth='lg'>

          {Buscador}

          <div style={{ height: 10 }}></div>

          <TableContainer component={Paper}>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell key={'codigo'}>
                    <TableSortLabel
                      direction={this.props.ordenar['codigo'] ? this.props.ordenar['codigo'] : 'asc'}
                      onClick={() => this.props.onOrdenar('codigo')}>
                      {'Codigo'}
                    </TableSortLabel>
                  </TableCell>

                  <TableCell key={'generico'}>
                    <TableSortLabel
                      direction={this.props.ordenar['generico'] ? this.props.ordenar['generico'] : 'asc'}
                      onClick={() => this.props.onOrdenar('generico')}>
                      {'Código Genérico'}
                    </TableSortLabel>
                  </TableCell>

                  <TableCell key={'empresa'}>
                    <TableSortLabel
                      direction={this.props.ordenar['empresa'] ? this.props.ordenar['empresa'] : 'asc'}
                      onClick={() => this.props.onOrdenar('empresa')}>
                      {'Empresa / Centro / Evento'}
                    </TableSortLabel>
                  </TableCell>

                  <TableCell key={'fechaevento'}>
                    <TableSortLabel
                      direction={this.props.ordenar['fechaeventooriginal'] ? this.props.ordenar['fechaeventooriginal'] : 'asc'}
                      onClick={() => this.props.onOrdenar('fechaeventooriginal')}>
                      {'Fecha Función'}
                    </TableSortLabel>
                  </TableCell>

                  <TableCell key={'nombre'}>
                    <TableSortLabel
                      direction={this.props.ordenar['nombre'] ? this.props.ordenar['nombre'] : 'asc'}
                      onClick={() => this.props.onOrdenar('nombre')}>
                      {'Nombre'}
                    </TableSortLabel>
                  </TableCell>

                  <TableCell key={'canjeado'}>
                    <TableSortLabel
                      direction={this.props.ordenar['validado'] ? this.props.ordenar['validado'] : 'asc'}
                      onClick={() => this.props.onOrdenar('validado')}>
                      {'Invitación Canjeada'}
                    </TableSortLabel>
                  </TableCell>

                  <TableCell key={'usado'}>
                    <TableSortLabel
                      direction={this.props.ordenar['usado'] ? this.props.ordenar['usado'] : 'asc'}
                      onClick={() => this.props.onOrdenar('usado')}>
                      {'Entrada Emitida'}
                    </TableSortLabel>
                  </TableCell>

                  <TableCell key={'emailenviado'}>
                    <TableSortLabel
                      direction={this.props.ordenar['emailenviado'] ? this.props.ordenar['emailenviado'] : 'asc'}
                      onClick={() => this.props.onOrdenar('emailenviado')}>
                      {'Email Env.'}
                    </TableSortLabel>
                  </TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {(this.state.rowsPerPage > 0
                  ? this.props.data.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                  : this.props.data
                ).map((element, index) => (
                  <Grow in={true} timeout={index < 50 ? 100 * index : 0} key={element.idcodigos} style={{ backgroundColor: (this.state.dataChanged.findIndex(x => x.idcodigos === element.idcodigos) >= 0 ? '#9E9E9E' : '') }}>
                    <TableRow>
                      <TableCell>
                        <Typography align="center">{element.codigo}</Typography>
                      </TableCell>

                      <TableCell>{element.generico}</TableCell>

                      <TableCell padding='default'>
                        <Container>
                          <div>{element.empresa}</div>
                          <div>{element.centro}</div>
                          <div>{element.evento}</div>
                        </Container>
                      </TableCell>
                      <TableCell>{element.fechaevento}</TableCell>

                      <TableCell>{element.nombre}</TableCell>

                      <TableCell>
                        {element.estavalidado === 'SI'
                          ? element.validado
                          : (
                            <ThumbDownIcon />
                          )}
                      </TableCell>

                      <TableCell>
                        {element.estausado === 'SI'
                          ? element.usado
                          : (
                            <IconButton onClick={() => this.setState({ showConfirmation: true, elementSeleccionado: element })}>
                              <ThumbDownIcon />
                            </IconButton>
                          )}
                      </TableCell>

                      <TableCell>
                        {element.emailenviado === 'SI'
                          ? (<EmailIcon />)
                          : ''}
                      </TableCell>

                    </TableRow>
                  </Grow>
                ))}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 25 * emptyRows }}>
                    <TableCell colSpan={9} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 20, 50, { label: 'All', value: -1 }]}
                    colSpan={9}
                    count={this.props.data.length}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onChangePage={(e, page) => this.handleChangePage(page)}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>

          {
            this.state.showConfirmation === true ?
              (<PopUpConfirmacion messageInfo={'¿Emitir entrada?'}
                onOk={() => this.onCodigoUsado(this.state.elementSeleccionado)}
                onCancel={this.onHideConfirmation} />)
              : ''
          }

          {
            this.state.error === true ? AlertloginFailed : ''
          }

        </Container>
      </div >);
  }

}

export default ListadoParaCentros;
