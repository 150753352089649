import React from 'react';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';


class Loading extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {

    return (
      <div>
        <Dialog open={true}>
          <Container style={{widows: 300, height: 80, padding: 20}}>
            <CircularProgress />
          </Container>
        </Dialog>
      </div>
    );
  }

}

export default Loading;
