import React from 'react';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import Collapse from '@material-ui/core/Collapse';

import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { Avatar, TextField, FormControl, FormControlLabel, Select, MenuItem, Checkbox, InputLabel } from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import EmailIcon from '@material-ui/icons/Email';
import CheckIcon from '@material-ui/icons/Check';

import Typography from '@material-ui/core/Typography';

import Header from '../../component/header';
import EditarUsuario from './editarUsuario';

import PopUpInfo from '../../component/popUpInfo';
import PopUpConfirmacion from '../../component/popUpConfirmacion';

import { Usuarios } from '../../entity/usuarios';
import { Bundle } from '../../entity/bundle';
import { EmailPlantilla } from '../../entity/emailplantilla';


import '../../css/style.css';

class GestionUsuarios extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      rowsPerPage: 10,
      page: 0,
      ordenar: [],

      idusuarios: '',
      nombre: '',
      movil: '',
      email: '',
      idperfiles: '',
      idempresas: '',
      idcentros: '',
      clave: '',
      clave2: '',
      validado: false,
      imagen: {},

      showPanelBusquedas: false,
      showPanelBundle: false,
      showPanelPlantillas: false,

      showConfirmationAsignarBundle: false,
      showConfirmationEnviarEmail: false,

      buscarNombre: '',
      buscarEmail: '',
      buscarGenerico: '',
      buscarValidado: 'SI',
      buscarConEntradas: 'TODOS',

      buscarBundle: '',
      buscarPlantilla: '',

      data: [],
      dataSeleccionados: [],
      selectAll: false,
      seleccionado: {},

      bundle: [],
      plantillas: [],

      showEmailPreview: false,

      loading: false,
      editando: false,
      error: false,
      errorText: ''
    }

    // gestionar la tabla
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.onOrdenar = this.onOrdenar.bind(this);

    this.onChangeInput = this.onChangeInput.bind(this);
    this.onSelectFile = this.onSelectFile.bind(this);
    this.onGuardar = this.onGuardar.bind(this);
    this.onNuevo = this.onNuevo.bind(this);
    this.onCargar = this.onCargar.bind(this);
    this.onBack = this.onBack.bind(this);
    this.onSeleccionarItem = this.onSeleccionarItem.bind(this);
    this.onBorrarItem = this.onBorrarItem.bind(this);

    this.showBusquedas = this.showBusquedas.bind(this);
    this.showBundle = this.showBundle.bind(this);
    this.showPlantillas = this.showPlantillas.bind(this);

    this.asignarBundle = this.asignarBundle.bind(this);
    this.onHideConfirmationAsignarBundle = this.onHideConfirmationAsignarBundle.bind(this);

    this.onHideConfirmationEnviarEmail = this.onHideConfirmationEnviarEmail.bind(this);

    this.enviarEmailUsuariosCodigos = this.enviarEmailUsuariosCodigos.bind(this);

    let searchDelay = {};

  }

  componentDidMount() {
    this.onCargar({});
  }

  handleChangePage(newPage) {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage(event) {
    this.setState({ rowsPerPage: parseInt(event.target.value), page: 0 });
  };

  onOrdenar(por) {

    if (por) {

      let tmpOrdenar = this.state.ordenar;
      if (!tmpOrdenar[por]) {
        tmpOrdenar[por] = 'asc';
      }
      else {
        tmpOrdenar[por] = tmpOrdenar[por] === 'asc' ? 'desc' : 'asc';
      }

      let datosOrdenados = this.state.data;

      // si quieremos ordenar la fecha de vento, entonces se hace un tratamiento especial, ya que es una fecha
      tmpOrdenar[por] === 'asc' ?
        datosOrdenados.sort((a, b) => (a[por] > b[por]) ? 1 : ((b[por] > a[por]) ? -1 : 0))
        : datosOrdenados.sort((a, b) => (a[por] < b[por]) ? 1 : ((b[por] < a[por]) ? -1 : 0));

      this.setState({ data: datosOrdenados, ordenar: tmpOrdenar });
    }
  }


  onChangeInput(event) {

    this.setState({ [event.target.name]: event.target.value });

    clearTimeout(this.searchDelay);

    this.searchDelay = setTimeout(() => {
      this.onCargar({
        nombre: this.state.buscarNombre,
        email: this.state.buscarEmail,
        generico: this.state.buscarGenerico,
        validado: this.state.buscarValidado,
        conentradas: this.state.buscarConEntradas,
      })
    }, 1500);
  }

  onCargar(formulario) {

    this.setState({ loading: true });

    Usuarios.getUsuarios(formulario)
      .then(
        data => {
          if (data.records) {
            this.setState({ data: data.records, loading: false, editando: false });
          }
        }
      );

    if (this.state.bundle.length === 0) {
      Bundle.getBundle(formulario)
        .then(
          data => {
            if (data.records) {

              // solo cargamos Bundles distintos
              let tmp = [];
              data.records.forEach((element) => {
                if (tmp.filter(x => x.idbundle === element.idbundle).length === 0) {
                  tmp.push(element);
                }
              });

              this.setState({ bundle: tmp });
            }
          }
        );
    }

    if (this.state.plantillas.length === 0) {
      EmailPlantilla.getPlantillas(formulario)
        .then(
          data => {
            if (data.records) {
              this.setState({ plantillas: data.records });
            }
          }
        );
    }


  }

  onGuardar(item) {

    // la clave y repetición de clave deben ser iguales
    if ((item.clave || item.clave2) && item.clave !== item.clave2) {
      this.setState({ error: true, errorText: "Indique claves iguales", errorLogin: true, loading: false });
      return;
    }

    // el usuario es obligatorio
    if (item.usuario === undefined || item.usuario.length < 4) {
      this.setState({ error: true, errorText: "Indique el usuario", errorLogin: true, loading: false });
      return;
    }

    this.setState({ loading: true });

    let formulario = new FormData()

    // solo mandamos la imagen en el caso de que se haya seleccionado alguna
    if (item.imagenData) {
      formulario.append('imagen', item.imagenData)
    }

    formulario.append('idusuarios', item.idusuarios);

    if (item.nombre) formulario.append('nombre', item.nombre);
    if (item.movil) formulario.append('movil', item.movil);
    if (item.email) formulario.append('email', item.email);
    if (item.idperfiles) formulario.append('idperfiles', item.idperfiles);
    if (item.idempresas) formulario.append('idempresas', item.idempresas);
    if (item.idcentros) formulario.append('idcentros', item.idcentros);
    if (item.clave) formulario.append('clave', item.clave);
    if (item.clave2) formulario.append('clave2', item.clave2);
    if (item.usuario) formulario.append('usuario', item.usuario);

    formulario.append('validado', item.validado);

    Usuarios.guardar(formulario)
      .then(
        valid => {
          if (valid === true) {
            this.onBack();
            this.onCargar({
              nombre: this.state.buscarNombre,
              email: this.state.buscarEmail,
              generico: this.state.buscarGenerico,
              validado: this.state.buscarValidado,
              conentradas: this.state.buscarConEntradas,
            });
          }
          else {
            this.setState({ error: true, loading: false, errorText: valid.message });
          }
        }
      );

  }


  onSelectFile(event) {
    console.log(event.target.files[0]);
    this.setState({ imagen: event.target.files[0] });
  }

  onSeleccionarItem(element) {
    this.setState({ seleccionado: element, editando: true, error: false });
  }

  onNuevo() {
    let item = {
      idusuarios: '',
      nombre: '',
      movil: '',
      email: '',
      idperfiles: '',
      idempresas: '',
      idcentros: '',
      clave: '',
      clave2: '',
      usuario: '',
      imagen: {}
    }

    this.setState({ seleccionado: item, editando: true, error: false });
  }

  onBack() {
    this.setState({ editando: false });
  }

  onBorrarItem(item) {

    this.setState({ loading: true });

    Usuarios.borrar(item.idusuarios)
      .then(
        valid => {
          if (valid === true) {
            this.onCargar({
              nombre: this.state.buscarNombre,
              email: this.state.buscarEmail,
              generico: this.state.buscarGenerico,
              validado: this.state.buscarValidado,
              conentradas: this.state.buscarConEntradas,
            });
          }
          else {
            this.setState({ error: true, loading: false, errorText: valid.message });
          }
        }
      );
  }

  showBusquedas() {
    this.setState({ showPanelBusquedas: !this.state.showPanelBusquedas });
  }

  showBundle() {
    this.setState({ showPanelBundle: !this.state.showPanelBundle });
  }

  showPlantillas() {
    this.setState({ showPanelPlantillas: !this.state.showPanelPlantillas });
  }


  asignarBundle() {

    this.setState({ showConfirmationAsignarBundle: false });

    // es obligatorio indicar un bundle
    if (this.state.buscarBundle.length === 0) {
      this.setState({ error: true, loading: false, errorText: "Debe indicar un Bundle" });
      return;
    }

    // debe indicar por lo menos un usuario al que asignar el Bundle
    if (this.state.dataSeleccionados.length === 0) {
      this.setState({ error: true, loading: false, errorText: "Seleccione al menos un usuario" });
      return;
    }

    let formulario = new FormData()
    formulario.append('idbundle', this.state.buscarBundle);

    // cargo los usuarios
    formulario.append('idusuarios', JSON.stringify(this.state.dataSeleccionados));

    Bundle.asignaBundleUsuarios(formulario)
      .then(
        valid => {
          if (valid === true) {
            this.onCargar({
              nombre: this.state.buscarNombre,
              email: this.state.buscarEmail,
              generico: this.state.buscarGenerico,
              validado: this.state.buscarValidado,
              conentradas: this.state.buscarConEntradas,
            });
          }

          this.setState({ error: true, loading: false, errorText: valid.message });
        }
      );

  }

  enviarEmailUsuariosCodigos() {

    this.setState({ showConfirmationEnviarEmail: false });

    // es obligatorio indicar la plantilla
    if (this.state.buscarPlantilla.length === 0) {
      this.setState({ error: true, loading: false, errorText: "Debe indicar una Plantilla" });
      return;
    }

    // debe indicar por lo menos un usuario al que asignar el Bundle
    if (this.state.dataSeleccionados.length === 0) {
      this.setState({ error: true, loading: false, errorText: "Seleccione al menos un usuario" });
      return;
    }

    let formulario = new FormData()
    formulario.append('idemail_plantilla', this.state.buscarPlantilla);

    // vemos si solicitamos una preview
    if (this.state.showEmailPreview) formulario.append('preview', 'si');

    // cargo los usuarios
    // si estamos solicitando un preview, solo pedimos el primero de la lista
    if (this.state.showEmailPreview) {
      formulario.append('idusuarios', JSON.stringify([this.state.dataSeleccionados[0]]));
    }
    else {
      formulario.append('idusuarios', JSON.stringify(this.state.dataSeleccionados));
    }


    EmailPlantilla.enviarEmailUsuariosCodigos(formulario)
      .then(
        valid => {
          if (valid === true) {
            this.onCargar({
              nombre: this.state.buscarNombre,
              email: this.state.buscarEmail,
              generico: this.state.buscarGenerico,
              validado: this.state.buscarValidado,
              conentradas: this.state.buscarConEntradas,
            });
          }

          this.setState({ error: true, loading: false, errorText: valid.message });
        }
      );

  }

  onHideConfirmationAsignarBundle() {
    this.setState({ showConfirmationAsignarBundle: false });
  }

  onHideConfirmationEnviarEmail() {
    this.setState({ showConfirmationEnviarEmail: false });
  }



  render() {

    const emptyRows = 1;

    const AlertloginFailed = <PopUpInfo messageInfo={this.state.errorText} onClose={() => { this.setState({ error: false, errorText: '' }) }} />

    const BundlePanel =
      <Collapse in={this.state.showPanelBundle} >
        <Box display='flex'>
          <FormControl variant="outlined" fullWidth size="small" margin='normal' required>
            <InputLabel>Bundle</InputLabel>
            <Select
              labelId="buscarBundle"
              id="buscarBundle"
              name="buscarBundle"
              value={this.state.buscarBundle}
              label="Bundle"
              onChange={(e) => this.setState({ buscarBundle: e.target.value })}
            >
              <MenuItem value="">
                <em>Seleccionar</em>
              </MenuItem>
              {
                this.state.bundle.map((element, index) => {
                  return (<MenuItem value={element.idbundle} key={element.idbundle}>{element.nombre}</MenuItem>);
                })
              }
            </Select>
          </FormControl>

          <Button
            variant="contained"
            size='small'
            color="primary"
            style={{ margin: '5px' }}
            onClick={() => { this.setState({ showConfirmationAsignarBundle: true }) }}
          >Asignar</Button>

        </Box>
      </Collapse>;

    const PlantillasPanel =
      <Collapse in={this.state.showPanelPlantillas} >
        <Box display='flex'>
          <FormControlLabel
            control={<Checkbox
              checked={this.state.showEmailPreview}
              onChange={(e) => this.setState({ showEmailPreview: e.target.checked })}
            />
            }
            label="Preview Email"
          />

          <FormControl variant="outlined" fullWidth size="small" margin='normal' required>
            <InputLabel>Plantillas</InputLabel>
            <Select
              labelId="buscarPlantilla"
              id="buscarPlantilla"
              name="buscarPlantilla"
              value={this.state.buscarPlantilla}
              label="Plantilla"
              onChange={(e) => this.setState({ buscarPlantilla: e.target.value })}
            >
              <MenuItem value="">
                <em>Seleccionar</em>
              </MenuItem>
              {
                this.state.plantillas.map((element, index) => {
                  return (<MenuItem value={element.idemail_plantilla} key={element.idemail_plantilla}>{element.nombre}</MenuItem>);
                })
              }
            </Select>
          </FormControl>

          <Button
            variant="contained"
            size='small'
            color="primary"
            style={{ margin: '5px' }}
            onClick={() => { this.setState({ showConfirmationEnviarEmail: true }) }}
          >Enviar Correo</Button>

        </Box>
      </Collapse>;

    const Buscador =
      (<Box>

        <Box>

          <ButtonGroup color="primary" aria-label="outlined primary button group">

            <Button
              variant="contained"
              color="primary"
              onClick={() => { this.showBusquedas(); }}
            >Buscar</Button>

            <Button
              variant="contained"
              color="primary"
              onClick={() => { this.showBundle(); }}
            >Bundle</Button>

            <Button
              variant="contained"
              color="primary"
              onClick={() => { this.showPlantillas(); }}
            >Plantillas</Button>

            <Button
              variant="contained"
              color="primary"
              onClick={this.onExport}
            >
              A Excel
  </Button>

            <Button
              fullWidth
              variant="contained"
              disabled={this.state.loading}
              color="primary"
              onClick={this.onNuevo}
            >
              Nuevo
          </Button>


          </ButtonGroup>

        </Box>

        <div style={{ height: 20 }}></div>

        <Collapse in={this.state.showPanelBusquedas} >

          <Box display='flex' style={{ width: '100%' }}>

            <TextField
              fullWidth
              id="buscarNombre"
              label="Nombre"
              name="buscarNombre"
              autoComplete="buscarNombre"
              size='small'
              value={this.state.buscarNombre}
              variant='outlined'
              style={{ margin: 5 }}
              margin='dense'
              onChange={this.onChangeInput}
            />

            <TextField
              fullWidth
              id="buscarEmail"
              label="Email"
              name="buscarEmail"
              autoComplete="buscarEmail"
              size='small'
              value={this.state.buscarEmail}
              variant='outlined'
              style={{ margin: 5 }}
              margin='dense'
              onChange={this.onChangeInput}
            />

            <TextField
              fullWidth
              id="buscarGenerico"
              label="Cod.Registro"
              name="buscarGenerico"
              autoComplete="buscarGenerico"
              size='small'
              value={this.state.buscarGenerico}
              variant='outlined'
              style={{ margin: 5 }}
              margin='dense'
              onChange={this.onChangeInput}
            />

            <FormControl variant="outlined" fullWidth size="small" margin='dense' style={{ margin: 5 }}>
              <Select
                labelId="buscarValidado"
                id="buscarValidado"
                name="buscarValidado"
                value={this.state.buscarValidado}
                onChange={this.onChangeInput}
              >
                <MenuItem value="TODOS"><em>Validado (Todos)</em></MenuItem>
                <MenuItem value='1' key='SI'>Si</MenuItem>
                <MenuItem value='0' key='NO'>No</MenuItem>
              </Select>
            </FormControl>

            <FormControl variant="outlined" fullWidth size="small" margin='dense' style={{ margin: 5 }}>
              <Select
                labelId="buscarConEntradas"
                id="buscarConEntradas"
                name="buscarConEntradas"
                value={this.state.buscarConEntradas}
                onChange={this.onChangeInput}
              >
                <MenuItem value="TODOS"><em>Con Entradas (Todos)</em></MenuItem>
                <MenuItem value='1' key='SI'>Si</MenuItem>
                <MenuItem value='0' key='NO'>No</MenuItem>
              </Select>
            </FormControl>

          </Box>


        </Collapse>

        {BundlePanel}

        {PlantillasPanel}


        <FormControlLabel
          control={<Checkbox
            checked={this.state.selectAll}
            onChange={(e) => {
              let tmp = [];

              if (e.target.checked) {
                this.state.data.forEach((element) => tmp.push(element.idusuarios));
              }

              this.setState({ dataSeleccionados: tmp, selectAll: !this.state.selectAll });
            }
            }
          />
          }
          label={`Marcar/Desmarcar ${this.state.dataSeleccionados.length > 0 ? `(${this.state.dataSeleccionados.length})` : ''}`}
        />


      </Box>);

    const Registros =

      <Container maxWidth='lg'>

        {Buscador}

        <Box>

          <div style={{ height: 20 }}></div>

          <Box style={{ backgroundColor: 'black' }} borderRadius={5}>

            <TableContainer component={Paper}>
              <Table size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell key={'acciones'}>
                      {''}
                    </TableCell>

                    <TableCell key={'validado'}>
                      {'Validado'}
                    </TableCell>

                    <TableCell key={'generico'}>
                      <TableSortLabel
                        direction={this.state.ordenar['generico'] ? this.state.ordenar['generico'] : 'asc'}
                        onClick={() => this.onOrdenar('generico')}>
                        {'Cod.Registro'}
                      </TableSortLabel>
                    </TableCell>

                    <TableCell key={'idusuarios'}>
                      <TableSortLabel
                        direction={this.state.ordenar['nombre'] ? this.state.ordenar['nombre'] : 'asc'}
                        onClick={() => this.onOrdenar('nombre')}>
                        {'Usuario'}
                      </TableSortLabel>
                    </TableCell>

                    <TableCell key={'entradas'}>
                      <TableSortLabel
                        direction={this.state.ordenar['entradas'] ? this.state.ordenar['entradas'] : 'asc'}
                        onClick={() => this.onOrdenar('entradas')}>
                        {'Entradas'}
                      </TableSortLabel>
                    </TableCell>

                    <TableCell key={'movil'}>
                      <TableSortLabel
                        direction={this.state.ordenar['movil'] ? this.state.ordenar['movil'] : 'asc'}
                        onClick={() => this.onOrdenar('movil')}>
                        {'Telefono'}
                      </TableSortLabel>
                    </TableCell>

                    <TableCell key={'email'}>
                      <TableSortLabel
                        direction={this.state.ordenar['email'] ? this.state.ordenar['email'] : 'asc'}
                        onClick={() => this.onOrdenar('email')}>
                        {'Email'}
                      </TableSortLabel>
                    </TableCell>

                    <TableCell key={'idperfiles'}>
                      <TableSortLabel
                        direction={this.state.ordenar['idperfiles'] ? this.state.ordenar['idperfiles'] : 'asc'}
                        onClick={() => this.onOrdenar('idperfiles')}>
                        {'Perfil'}
                      </TableSortLabel>
                    </TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {(this.state.rowsPerPage > 0
                    ? this.state.data.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                    : this.state.data
                  ).map((element, index) => (
                    <Grow in={true} timeout={index < 50 ? 100 * index : 0} key={index}>
                      <TableRow>

                        <TableCell>
                          <Box display='flex' alignItems='center'>

                            <Checkbox
                              checked={this.state.dataSeleccionados.indexOf(element.idusuarios) >= 0 ? true : false}
                              onChange={(e) => {
                                let tmp = this.state.dataSeleccionados;

                                if (e.target.checked) {
                                  tmp.push(element.idusuarios);
                                }
                                else {
                                  tmp.splice(tmp.indexOf(element.idusuarios), 1);
                                }

                                this.setState({ dataSeleccionados: tmp });

                                console.log(tmp);
                              }
                              }
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />

                            <IconButton onClick={() => this.onSeleccionarItem(element)} title="Editar registro">
                              <EditIcon />
                            </IconButton>
                            <IconButton onClick={() => this.onBorrarItem(element)} title="Borrar registro">
                              <DeleteIcon />
                            </IconButton>

                            <Avatar
                              alt={element.nombre}
                              src={element.imagen}
                            />
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box display="flex" justifyContent='center'>
                            {
                              element.validado.toString() === "0"
                                ? <div></div>
                                : <CheckIcon />
                            }
                          </Box>
                        </TableCell>

                        <TableCell>{element.generico}</TableCell>

                        <TableCell>{element.nombre}</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>{element.entradas}</TableCell>
                        <TableCell>{element.movil}</TableCell>
                        <TableCell>{element.email}</TableCell>
                        <TableCell>{element.idperfiles}</TableCell>

                      </TableRow>
                    </Grow>
                  ))}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 25 * emptyRows }}>
                      <TableCell colSpan={8} />
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[10, 20, 50, { label: 'All', value: -1 }]}
                      colSpan={8}
                      count={this.state.data.length}
                      rowsPerPage={this.state.rowsPerPage}
                      page={this.state.page}
                      onChangePage={(e, page) => this.handleChangePage(page)}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>

          </Box>
        </Box>

      </Container>;

    return (
      <div>

        {
          this.state.showConfirmationAsignarBundle === true ?
            (<PopUpConfirmacion messageInfo={`¿Asignar Bundle a ${this.state.dataSeleccionados.length} usuarios seleccionados?`}
              onOk={this.asignarBundle}
              onCancel={this.onHideConfirmationAsignarBundle} />)
            : ''
        }

        {
          this.state.showConfirmationEnviarEmail === true ?
            (<PopUpConfirmacion messageInfo={`¿Enviar email a ${this.state.dataSeleccionados.length} usuarios seleccionados?`}
              onOk={this.enviarEmailUsuariosCodigos}
              onCancel={this.onHideConfirmationEnviarEmail} />)
            : ''
        }

        <Header title="Usuarios" history={this.props.history}></Header>

        <div style={{ height: 40 }}></div>

        <Typography align="center" variant="h5" style={{ color: 'black' }}>Gestión de Usuarios</Typography>

        <div style={{ height: 20 }}></div>

        {this.state.editando ? <EditarUsuario item={this.state.seleccionado} onGuardar={this.onGuardar} onBack={this.onBack} /> : Registros}

        <div style={{ height: 20 }}></div>

        {this.state.error === true ? AlertloginFailed : ''}


      </div>
    );
  }

}

export default GestionUsuarios;
