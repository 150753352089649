import React from 'react';
import './Strip.css';

class Strip extends React.Component {

  render() {
    return (
      <div className="strip-wrapper">
        <div className="strip">
          <div className="strip-header">
            <h1><span className="primary-color p-5">+</span> valorados</h1>
            <p><span className="bold">musical pass</span> es la forma más cómoda de asistir a las experiencias musicales del momento.</p>
          </div>
          <div className="strip-info">
            <div className="strip-info_cards">
              <div className="strip-info_card-item">
                <div className="strip-info_card-item-number">
                  <img src="/images/strip/number-1.png" alt="" title="One" />
                </div>
                <div className="strip-info_card-item-data">
                  <img src="/images/products/pinocho/cartel.jpg" alt="" title="Juan Sin Miedo" />
                  <div className="strip-info_card-item-data-info">
                    <div className="strip-info_card-item-data-info-rating">
                      <img src="/images/strip/silver-star.png" alt="" title="Puntuación" />
                      <img src="/images/strip/silver-star.png" alt="" title="Puntuación" />
                      <img src="/images/strip/silver-star.png" alt="" title="Puntuación" />
                      <img src="/images/strip/silver-star.png" alt="" title="Puntuación" />
                      <img src="/images/strip/silver-star.png" alt="" title="Puntuación" />
                    </div>
                    <h3>Pinocho</h3>
                    <span>12:30</span>
                  </div>
                </div>
              </div>
              <div className="strip-info_card-item">
                <div className="strip-info_card-item-number">
                  <img src="/images/strip/number-2.png" alt="" title="Two" />
                </div>
                <div className="strip-info_card-item-data">
                  <img src="/images/products/fama/cartel.jpg" alt="" title="Juan Sin Miedo" />
                  <div className="strip-info_card-item-data-info">
                    <div className="strip-info_card-item-data-info-rating">
                      <img src="/images/strip/silver-star.png" alt="" title="Puntuación" />
                      <img src="/images/strip/silver-star.png" alt="" title="Puntuación" />
                      <img src="/images/strip/silver-star.png" alt="" title="Puntuación" />
                      <img src="/images/strip/silver-star.png" alt="" title="Puntuación" />
                      <img src="/images/strip/silver-star.png" alt="" title="Puntuación" />
                    </div>
                    <h3>Fama</h3>
                    <span>Octubre 2021</span>
                  </div>
                </div>
              </div>
              <div className="strip-info_card-item">
                <div className="strip-info_card-item-number">
                  <img src="/images/strip/number-3.png" alt="" title="Three" />
                </div>
                <div className="strip-info_card-item-data">
                  <img src="/images/products/grease/cartel.jpg" alt="" title="Juan Sin Miedo" />
                  <div className="strip-info_card-item-data-info">
                    <div className="strip-info_card-item-data-info-rating">
                      <img src="/images/strip/silver-star.png" alt="" title="Puntuación" />
                      <img src="/images/strip/silver-star.png" alt="" title="Puntuación" />
                      <img src="/images/strip/silver-star.png" alt="" title="Puntuación" />
                      <img src="/images/strip/silver-star.png" alt="" title="Puntuación" />
                      <img src="/images/strip/silver-star.png" alt="" title="Puntuación" />
                    </div>
                    <h3>Grease</h3>
                    <span>A partir 14 Octubre</span>
                  </div>
                </div>
              </div>
              <div className="strip-info_card-item">
                <div className="strip-info_card-item-number">
                  <img src="/images/strip/number-4.png" alt="" title="Four" />
                </div>
                <div className="strip-info_card-item-data">
                  <img src="/images/products/the-royal-gag/cartel.jpg" alt="" title="Juan Sin Miedo" />
                  <div className="strip-info_card-item-data-info">
                    <div className="strip-info_card-item-data-info-rating">
                      <img src="/images/strip/silver-star.png" alt="" title="Puntuación" />
                      <img src="/images/strip/silver-star.png" alt="" title="Puntuación" />
                      <img src="/images/strip/silver-star.png" alt="" title="Puntuación" />
                      <img src="/images/strip/silver-star.png" alt="" title="Puntuación" />
                      <img src="/images/strip/silver-star.png" alt="" title="Puntuación" />
                    </div>
                    <h3>The Royal Gag</h3>
                    <span>Proximamente</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Strip;