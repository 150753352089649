import React from 'react';
import "./Rating.css";

const ITEMS_NUMBER = 5;

const Rating = ({ number, completed, filled }) => {

	const totalItems = number ? number : ITEMS_NUMBER;

	const defaultCalification = () => {
		if (completed) {
			return totalItems;
		} else if (filled) {
			return filled;
		} else {
			return 0;
		}
	};

	const [calification, setCalification] = React.useState(defaultCalification);

	const getFilled = (param) => {
		const value = (param <= calification) ? 'filled' : '';
		return value;
	};

	const RatingItem = ({position}) => (
		<span 
			className="star-wrapper" 
			onClick={() => handleClick(position)}
		>
			<span className={`star-item ${getFilled(position)}`}>
				<svg width="20" height="22" className="star">
					<polygon points="9.9, 1.1, 3.3, 21.78, 19.8, 8.58, 0, 8.58, 16.5, 21.78" style={{ fillRule:'nonzero' }} />
				</svg>
			</span>
		</span>
	);

	const handleClick = (param) => {
		setCalification(param);
	};

	React.useEffect(() => {}, [calification]);
	
	return (
		<div 
			className={`rating ${ completed && !filled ? 'completed not-enabled' : '' }`} 
			data-calification={calification}
		>
			{[...Array(totalItems)].map(
				(item, index) => (
					<RatingItem 
						id={`rating-${index}`} 
						key={`rating-${index}`}  
						position={index + 1} 
					/>
				)
      		)}
        </div>
	)
}

export default Rating;
