import React from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { Usuarios } from '../../entity/usuarios';

import PopUpInfo from '../../component/popUpInfo';

import '../../css/style.css';


class Login extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      usuario: '',
      clave: '',
      disable: false,
      error: false,
      errorText: ''
    }

    this.onLogin = this.onLogin.bind(this);
    this.onChangeUsuario = this.onChangeUsuario.bind(this);
    this.onChangeClave = this.onChangeClave.bind(this);
    this.onRegistrar = this.onRegistrar.bind(this);
  }

  onLogin(event) {
    event.preventDefault();

    this.setState({ loading: true, error: false });

    Usuarios.login(this.state.usuario, this.state.clave)
      .then(
        user => {
          if (user.records) {

            const { from } = this.props.location.state || { from: { pathname: "/main" } };
            this.props.history.push(from);
          }
          else {
            this.setState({ errorText: user.message }, () => {
              this.setState({ error: true, loading: false });
            })
          }
        }
      );

  }

  onChangeUsuario(event) {
    this.setState({ usuario: event.target.value });
  }

  onChangeClave(event) {
    this.setState({ clave: event.target.value });
  }

  onRegistrar(event) {
    event.preventDefault();

    //const { from } = this.props.location.state || { from: { pathname: "/registro" } };
    //this.props.history.push({ pathname: "/registro" });
    window.location.href = "/registro";
  }

  render() {
    const AlertloginFailed = <PopUpInfo messageInfo={this.state.errorText} onClose={() => { this.setState({ error: false, errorText: '' }) }} />

    return (
      <div>

        <div className="login">


          <div style={{ height: 100 }}></div>

          <Container maxWidth="xs">

            <Box p={2} className='box-blanco'>

              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="usuario"
                label="Usuario"
                name="usuario"
                autoComplete="usuario"
                autoFocus
                inputProps={{ maxLength: 15 }}

                onChange={this.onChangeUsuario}
              />

              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="clave"
                label="Clave"
                type="password"
                id="clave"
                autoComplete="current-password"
                inputProps={{ maxLength: 20 }}

                onChange={this.onChangeClave}
              />

              <Button
                fullWidth
                variant="contained"
                disabled={this.state.loading}
                color="primary"
                onClick={this.onLogin}
              >
                Acceder
            </Button>

              {this.state.error === true ? AlertloginFailed : ''}

              <div style={{ height: 20 }}></div>

              <Box display="flex" justifyContent="center">
                <Typography>
                  <a href="/recuperarcuenta">¿Has olvidado tu contraseña?</a>
                </Typography>
              </Box>

              <div style={{ height: 40 }}></div>


              <Button
                fullWidth
                variant="contained"
                disabled={this.state.loading}
                color="primary"
                onClick={this.onRegistrar}
              >Registrate</Button>

            </Box>

            <div style={{ height: 10 }}></div>

            <img src="./images/musical_pass.png" width="100%" alt="Musical Pass" />

          </Container>

        </div>
        <div className="login-steps">
          <Container className="login-steps__container">
            <Box className="login-steps__header">
              <h3 className="login-steps__header-title">
                ¡Canjéalo cuanto antes!
              </h3>
              <p className="login-steps__header-footer">
                Es muy sencillo:
              </p>
            </Box>
            <Box className="login-steps__steps">
              <Box className="login-steps__step">
                <img src="/images/1.png" alt="" className="login-steps__step-img" />
                <p className="login-steps__step-paragraph">Rellena los datos de acceso y escoge fecha.</p>
              </Box>
              <Box className="login-steps__step">
                <img src="/images/2.png" alt="" className="login-steps__step-img" />
                <p className="login-steps__step-paragraph">Descarga las invitaciones en pdf o passbook</p>
              </Box>
              <Box className="login-steps__step">
                <img src="/images/3.png" alt="" className="login-steps__step-img" />
                <p className="login-steps__step-paragraph">Presenta esas invitaciones a passbook en la entrada del recinto para acceder.</p>
              </Box>
            </Box>
            <Box className="login-steps__footer">
              <p className="login-steps__footer-paragraph">Una vez registrado pertenerás a la comunidad musicalpass y obtendrás información de todas nuestras producciones para poder acceder con <span className="login-steps__footer-highlight">descuentos y ventajas exclusivas.</span></p>
              <p className="login-steps__footer-paragraph">¡Muy pronto te informaremos de todas las <span className="login-steps__footer-highlight">novedades!</span></p>
            </Box>
          </Container>
        </div>
        <div className="login-footer">
          <Container className="login-footer__container">
            <Box>
              <p className="login-footer__paragraph">¡IMPORTANTE! EL acto se llevará a cabo con el aforo permitido, bajo estrictas medidas de seguridad recomendadas para el COVID-19, las butacas mantendrán la distancia de seguridad entre ellas y se tomarán las medidas higiénicas y de desinfección pertinentes antes y durante el espectáculo.</p>
            </Box>
            <Box className="login-footer__box">
              <div className="login-footer__logo">
                <img src="/images/Logo-M-Black.png" alt="" className="login-footer__logo-img" />
                <p className="login-footer__logo-text">¡Te esperamos en el teatro!</p>
              </div>
              <div className="login-footer__wallet">
                <p>Disponible para:</p>
                <img src="/images/wallet.png" alt="" />
              </div>
            </Box>
          </Container>
        </div>
      </div>
    );
  }
}

export default Login;