import React from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grow from '@material-ui/core/Grow';
import { Paper } from '@material-ui/core';


import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import { Empresas } from '../../entity/empresas';
import { Centros } from '../../entity/centros';
import { Eventos } from '../../entity/eventos';
import { Bundle } from '../../entity/bundle';

import PopUpInfo from '../../component/popUpInfo';

import '../../css/style.css';

class EditarBundle extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      rowsPerPage: 10,
      page: 0,
      ordenar: [],

      idbundle: this.props.item.idbundle,
      nombre: this.props.item.nombre,
      idempresas: this.props.item.idempresas,

      ideventos: '',
      idcentros: '',
      entradas_por_usuario: 1,

      lineas: [],

      empresas: [],
      centros: [],
      eventos: [],

    }

    // gestionar la tabla
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.onOrdenar = this.onOrdenar.bind(this);

    this.onChangeInput = this.onChangeInput.bind(this);
    this.onChangeInputSelect = this.onChangeInputSelect.bind(this);

    this.borrar = this.borrar.bind(this);

    this.cargarLineas = this.cargarLineas.bind(this);
    this.guardarLinea = this.guardarLinea.bind(this);
    this.borrarLinea = this.borrarLinea.bind(this);

    this.cargarSelect = this.cargarSelect.bind(this);
    this.cargarEmpresas = this.cargarEmpresas.bind(this);
    this.cargarCentros = this.cargarCentros.bind(this);
    this.cargarEventos = this.cargarEventos.bind(this);
  }

  componentDidMount() {
    this.cargarSelect();
    this.cargarLineas();
  }

  handleChangePage(newPage) {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage(event) {
    this.setState({ rowsPerPage: parseInt(event.target.value), page: 0 });
  };

  onOrdenar(por) {

    if (por) {

      let tmpOrdenar = this.state.ordenar;
      if (!tmpOrdenar[por]) {
        tmpOrdenar[por] = 'asc';
      }
      else {
        tmpOrdenar[por] = tmpOrdenar[por] === 'asc' ? 'desc' : 'asc';
      }

      let datosOrdenados = this.state.lineas;

      // si quieremos ordenar la fecha de vento, entonces se hace un tratamiento especial, ya que es una fecha
      tmpOrdenar[por] === 'asc' ?
        datosOrdenados.sort((a, b) => (a[por] > b[por]) ? 1 : ((b[por] > a[por]) ? -1 : 0))
        : datosOrdenados.sort((a, b) => (a[por] < b[por]) ? 1 : ((b[por] < a[por]) ? -1 : 0));

      this.setState({ data: datosOrdenados, ordenar: tmpOrdenar });
    }
  }

  onChangeInput(event) {
    this.setState({ [event.target.id]: event.target.value });
  }

  onChangeInputSelect(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  onGuardar = () => {
    this.props.onGuardar({
      idbundle: this.state.idbundle,
      nombre: this.state.nombre,
      idempresas: this.state.idempresas,
    });
  }

  onBack = () => {
    this.props.onBack();
  }

  async cargarSelect() {

    this.setState({ loading: true });

    await this.cargarEmpresas();
    await this.cargarCentros();
    await this.cargarEventos();

    this.setState({ loading: false });
  }

  borrar() {
    let formulario = new FormData()
    formulario.append('id', this.state.idbundle);

    Bundle.borrar(formulario)
      .then(
        valid => {
          if (valid === true) {
            this.onBack();
          }
          else {
            this.setState({ error: true, loading: false, errorText: valid.message });
          }
        }
      );
  }

  async cargarLineas() {
    Bundle.getBundleLineas(this.state.idbundle)
      .then(
        data => {
          if (data.records) {
            this.setState({ lineas: data.records });
          }
        }
      );
  }

  guardarLinea() {

    // el evento es obligatorio
    if (this.state.ideventos.length === 0) {
      this.setState({ error: true, loading: false, errorText: 'El evento es oblitatorio' });
      return;
    }

    // el centro es obligatorio
    if (this.state.idcentros.length === 0) {
      this.setState({ error: true, loading: false, errorText: 'El centro es oblitatorio' });
      return;
    }

    // si el evento y el centro ya están, no dejo continuar
    let yaEsta = this.state.lineas.filter(x => x.ideventos === this.state.ideventos && x.idcentros === this.state.idcentros);
    console.log(yaEsta);
    console.log(this.state.lineas);
    if (yaEsta.length > 0) {
      this.setState({ error: true, loading: false, errorText: 'El centro y evento indicados ya están' });
      return;
    }



    let formulario = new FormData()
    formulario.append('idbundle', this.state.idbundle);
    formulario.append('ideventos', this.state.ideventos);
    formulario.append('idcentros', this.state.idcentros);
    formulario.append('entradas_por_usuario', this.state.entradas_por_usuario);

    Bundle.guardarLinea(formulario)
      .then(
        valid => {
          if (valid === true) {
            this.cargarLineas();
          }
          else {
            this.setState({ error: true, loading: false, errorText: valid.message });
          }
        }
      );
  }

  borrarLinea(element) {
    let formulario = new FormData()
    formulario.append('id', element.idbundle_lineas);

    Bundle.borrarLinea(formulario)
      .then(
        valid => {
          if (valid === true) {
            this.cargarLineas();
          }
          else {
            this.setState({ error: true, loading: false, errorText: valid.message });
          }
        }
      );
  }

  async cargarEmpresas() {
    Empresas.getEmpresas()
      .then(
        data => {
          if (data.records) {
            this.setState({ empresas: data.records });
          }
        }
      );
  }

  async cargarCentros() {
    Centros.getCentros()
      .then(
        data => {
          if (data.records) {
            this.setState({ centros: data.records });
          }
        }
      );
  }

  async cargarEventos() {
    Eventos.getEventos()
      .then(
        data => {
          if (data.records) {
            this.setState({ eventos: data.records });
          }
        }
      );
  }

  render() {

    const emptyRows = 1;

    const AlertloginFailed = <PopUpInfo messageInfo={this.state.errorText} onClose={() => { this.setState({ error: false, errorText: '' }) }} />

    const Lineas =
      <TableContainer component={Paper}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell key={'acciones'}>
                {'#'}
              </TableCell>
              <TableCell key={'centro'}>
                <TableSortLabel
                  direction={this.state.ordenar['centro'] ? this.state.ordenar['centro'] : 'asc'}
                  onClick={() => this.onOrdenar('centro')}>
                  {'Centro'}
                </TableSortLabel>
              </TableCell>
              <TableCell key={'evento'}>
                <TableSortLabel
                  direction={this.state.ordenar['evento'] ? this.state.ordenar['evento'] : 'asc'}
                  onClick={() => this.onOrdenar('evento')}>
                  {'Evento'}
                </TableSortLabel>
              </TableCell>
              <TableCell key={'entradas_por_usuario'}>
                <TableSortLabel
                  direction={this.state.ordenar['entradas_por_usuario'] ? this.state.ordenar['entradas_por_usuario'] : 'asc'}
                  onClick={() => this.onOrdenar('entradas_por_usuario')}>
                  {'Entradas/Usuario'}
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(this.state.rowsPerPage > 0
              ? this.state.lineas.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
              : this.state.lineas
            ).map((element, index) => (
              <Grow in={true} timeout={index < 50 ? 100 * index : 0} key={index}>
                <TableRow>
                  <TableCell>
                    <Box display='flex' alignItems='center'>
                      <IconButton onClick={() => this.borrarLinea(element)} title="Borrar linea">
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </TableCell>

                  <TableCell>{element.centro}</TableCell>
                  <TableCell>{element.evento}</TableCell>
                  <TableCell style={{ textAlign: 'right' }}>{element.entradas}</TableCell>
                </TableRow>
              </Grow>
            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 25 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50, { label: 'All', value: -1 }]}
                colSpan={6}
                count={this.state.lineas.length}
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                onChangePage={(e, page) => this.handleChangePage(page)}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>;

    const NuevaLinea =
      <Box style={{ backgroundColor: 'rgba(255, 255, 255, 0.2)', padding: '10px' }}>

        <FormControl variant="outlined" fullWidth size="small" margin='normal' required>
          <InputLabel>Centro</InputLabel>
          <Select
            labelId="idcentros"
            id="idcentros"
            name="idcentros"
            value={this.state.idcentros}
            label="Centro"
            onChange={this.onChangeInputSelect}
          >
            <MenuItem value="">
              <em>Seleccionar</em>
            </MenuItem>
            {
              this.state.centros.map((element, index) => {
                return (<MenuItem value={element.idcentros} key={element.idcentros}>{element.nombre}</MenuItem>);
              })
            }
          </Select>
        </FormControl>

        <FormControl variant="outlined" fullWidth size="small" margin='normal' required>
          <InputLabel>Evento</InputLabel>
          <Select
            labelId="ideventos"
            id="ideventos"
            name="ideventos"
            value={this.state.ideventos}
            label="Evento"
            onChange={this.onChangeInputSelect}
          >
            <MenuItem value="">
              <em>Seleccionar</em>
            </MenuItem>
            {
              this.state.eventos.map((element, index) => {
                return (<MenuItem value={element.ideventos} key={index}>{element.nombre}</MenuItem>);
              })
            }
          </Select>
        </FormControl>

        <Box display='flex'>
          <TextField
            fullWidth
            margin="normal"
            variant="outlined"
            name="entradas_por_usuario"
            label="Entras / Usuario"
            type="number"
            id="entradas_por_usuario"
            size='small'
            InputProps={{ inputProps: { min: 1, max: 500 } }}
            value={this.state.entradas_por_usuario}
            style={{ margin: 5 }}

            onChange={this.onChangeInput}
          />

          <Button
            fullWidth
            variant="contained"
            color="primary"
            style={{ margin: 5 }}
            onClick={this.guardarLinea}
          >
            Añadir
          </Button>
        </Box>
      </Box>;


    return (
      <Container maxWidth='md'>

        <Grow in={true} timeout={500}>

          <Box display='flex'>

            <Box className="box-blanco">

              <TextField
                margin="dense"
                required
                fullWidth
                autoFocus
                id="nombre"
                label="Nombre"
                name="nombre"
                autoComplete="nombre"
                size='small'
                value={this.state.nombre}

                onChange={this.onChangeInput}
              />

              <FormControl variant="outlined" fullWidth size="small" margin='normal' required>
                <InputLabel>Empresa</InputLabel>
                <Select
                  labelId="idempresas"
                  id="idempresas"
                  name="idempresas"
                  value={this.state.idempresas}
                  label="Empresa"
                  onChange={this.onChangeInputSelect}
                >
                  <MenuItem value="">
                    <em>Seleccionar</em>
                  </MenuItem>
                  {
                    this.state.empresas.map((element, index) => {
                      return (<MenuItem value={element.idempresas} key={element.idempresas}>{element.nombre}</MenuItem>);
                    })
                  }
                </Select>
              </FormControl>

              <div style={{ height: 20 }}></div>

              <Box display="flex" justifyContent="center">

                <Button
                  fullWidth
                  variant="contained"
                  disabled={this.state.loading}
                  color="primary"
                  style={{ margin: 5 }}
                  onClick={this.onGuardar}
                >
                  Guardar
                </Button>

                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{ margin: 5 }}
                  onClick={this.onBack}
                >
                  Volver
                </Button>

                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{ margin: 5 }}
                  onClick={this.borrar}
                >
                  Borrar
                </Button>

              </Box>

              <div style={{ height: 20 }}></div>

              {this.state.idbundle.length > 0 ? NuevaLinea : <div></div>}

            </Box>

            <Box>

              {this.state.idbundle.length > 0 ? Lineas : <div></div>}

            </Box>


          </Box>
        </Grow>

        {this.state.error === true ? AlertloginFailed : ''}

      </Container>
    );
  }

}

export default EditarBundle;
