import React from 'react';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import Collapse from '@material-ui/core/Collapse';

import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import {TextField } from '@material-ui/core';

import Typography from '@material-ui/core/Typography';

import Header from '../../component/header';
import PopUpInfo from '../../component/popUpInfo';
import PopUpConfirmacion from '../../component/popUpConfirmacion';

import { Constantes } from '../../util/constantes';

import { Usuarios } from '../../entity/usuarios';


import '../../css/style.css';

class Reservas extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      rowsPerPage: 10,
      page: 0,
      ordenar: [],

      showPanelBusquedas: false,

      buscarCodigo: '',
      buscarEmpresa: '',
      buscarCentro: '',
      buscarEvento: '',
      buscarNombre: '',
      buscarEmail: '',

      data: [],
      seleccionado: {},

      loading: false,
      error: false,
      errorText: '',

      showConfirmationConfirmar: false,
      showConfirmationDenegar: false,
    }

    // gestionar la tabla
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.onOrdenar = this.onOrdenar.bind(this);

    this.onChangeInput = this.onChangeInput.bind(this);

    this.onCargar = this.onCargar.bind(this);

    this.showBusquedas = this.showBusquedas.bind(this);
    this.onSave = this.onSave.bind(this);

    this.onHideConfirmation = this.onHideConfirmation.bind(this);

    let searchDelay = {};

  }

  componentDidMount() {
    this.onCargar({});
  }

  handleChangePage(newPage) {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage(event) {
    this.setState({ rowsPerPage: parseInt(event.target.value), page: 0 });
  };

  onOrdenar(por) {

    if (por) {

      let tmpOrdenar = this.state.ordenar;
      if (!tmpOrdenar[por]) {
        tmpOrdenar[por] = 'asc';
      }
      else {
        tmpOrdenar[por] = tmpOrdenar[por] === 'asc' ? 'desc' : 'asc';
      }

      let datosOrdenados = this.state.data;

      // si quieremos ordenar la fecha de vento, entonces se hace un tratamiento especial, ya que es una fecha
      tmpOrdenar[por] === 'asc' ?
        datosOrdenados.sort((a, b) => (a[por] > b[por]) ? 1 : ((b[por] > a[por]) ? -1 : 0))
        : datosOrdenados.sort((a, b) => (a[por] < b[por]) ? 1 : ((b[por] < a[por]) ? -1 : 0));

      this.setState({ data: datosOrdenados, ordenar: tmpOrdenar });
    }
  }


  onChangeInput(event) {

    this.setState({ [event.target.name]: event.target.value });

    clearTimeout(this.searchDelay);

    this.searchDelay = setTimeout(() => {
      this.onCargar({
        codigo: this.state.buscarCodigo,
        empresa: this.state.buscarEmpresa,
        centro: this.state.buscarCentro,
        evento: this.state.buscarEvento,
        nombre: this.state.buscarNombre,
        email: this.state.buscarEmail,
      })
    }, 1500);
  }

  onCargar(formulario) {

    this.setState({ loading: true });

    Usuarios.getReservas(formulario)
      .then(
        data => {
          if (data.records) {
            this.setState({ data: data.records, loading: false, editando: false });
          }
        }
      );
  }


  onSave(formulario) {

    this.setState({ loading: true });

    Usuarios.saveReserva(formulario)
      .then(data => this.setState({ error: true, errorText: data.message, loading: false, showConfirmationDenegar: false, showConfirmationConfirmar: false, seleccionado: {} }));
  }


  showBusquedas() {
    this.setState({ showPanelBusquedas: !this.state.showPanelBusquedas });
  }

  onHideConfirmation() {
    this.setState({ showConfirmationConfirmar: false, showConfirmationDenegar: false, seleccionado: {} });
  }


  render() {

    const emptyRows = 1;

    const AlertloginFailed = <PopUpInfo messageInfo={this.state.errorText} onClose={() => { this.setState({ error: false, errorText: '' }) }} />

    const Buscador =
      (<Box display='flex' flexDirection='column'>

        <ButtonGroup color="primary" aria-label="outlined primary button group">

          <Button
            variant="contained"
            color="primary"
            onClick={() => { this.showBusquedas(); }}
          >
            Buscar
          </Button>


        </ButtonGroup>

        <Collapse in={this.state.showPanelBusquedas} >

          <Box display='flex' style={{ width: '100%' }}>
            <TextField
              fullWidth
              style={{ margin: 5 }}
              margin="dense" size='small' variant='outlined'
              id="buscarCodigo" label="Codigo" name="buscarCodigo"
              value={this.state.buscarCodigo}
              onChange={this.onChangeInput}
            />
            <TextField
              fullWidth
              style={{ margin: 5 }}
              margin="dense" size='small' variant='outlined'
              id="buscarEmpresa" label="Empresa" name="buscarEmpresa"
              value={this.state.buscarEmpresa}
              onChange={this.onChangeInput}
            />
            <TextField
              fullWidth
              style={{ margin: 5 }}
              margin="dense" size='small' variant='outlined'
              id="buscarCentro" label="Centro" name="buscarCentro"
              value={this.state.buscarCentro}
              onChange={this.onChangeInput}
            />
            <TextField
              fullWidth
              style={{ margin: 5 }}
              margin="dense" size='small' variant='outlined'
              id="buscarEvento" label="Evento" name="buscarEvento"
              value={this.state.buscarEvento}
              onChange={this.onChangeInput}
            />

          </Box>

          <Box display='flex' style={{ width: '100%' }}>

            <TextField
              fullWidth
              id="buscarNombre"
              label="Nombre"
              name="buscarNombre"
              autoComplete="buscarNombre"
              size='small'
              value={this.state.buscarNombre}
              variant='outlined'
              style={{ margin: 5 }}
              margin='dense'
              onChange={this.onChangeInput}
            />

            <TextField
              fullWidth
              id="buscarEmail"
              label="Email"
              name="buscarEmail"
              autoComplete="buscarEmail"
              size='small'
              value={this.state.buscarEmail}
              variant='outlined'
              style={{ margin: 5 }}
              margin='dense'
              onChange={this.onChangeInput}
            />

          </Box>


        </Collapse>

      </Box>);

    const botonesConfirmarDenegar = (element) => {
      return <Box>
        {
          Constantes.getUserLogged() && Constantes.getUserLogged().idperfiles !== 'USUARIO'
            ? element.confirmada === "0" && element.denegada === "0"
              ?
              (<ButtonGroup color="primary" aria-label="outlined primary button group">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => { this.setState({ seleccionado: element, showConfirmationConfirmar: true }) }}
                >Confirmar</Button>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => { this.setState({ seleccionado: element, showConfirmationDenegar: true }) }}
                >Denegar</Button>
              </ButtonGroup>)
              : <Box>{
                element.confirmada === "1" ? (<Box><div>CONFIRMADA</div><div>{element.fechaconfirmada}</div></Box>) : (<Box><div>DENEGADA</div><div>{element.fechadenegada}</div></Box>)
              }</Box>
            : <Box>{element.confirmada === "0" && element.denegada === "0"
              ? <Box>PENDIENTE</Box>
              : <Box>{
                element.confirmada === "1" ? (<Box><div>CONFIRMADA</div><div>{element.fechaconfirmada}</div></Box>) : (<Box><div>DENEGADA</div><div>{element.fechadenegada}</div></Box>)
              }</Box>
            }</Box>
        }

      </Box>
    }
      ;



    const Registros =

      <Container maxWidth='lg'>

        {Buscador}

        <Box>

          <div style={{ height: 20 }}></div>

          <Box style={{ backgroundColor: 'black' }} borderRadius={5}>

            <TableContainer component={Paper}>
              <Table size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      #
                    </TableCell>
                    <TableCell key={'codigo'}>
                      <TableSortLabel
                        direction={this.state.ordenar['codigo'] ? this.state.ordenar['codigo'] : 'asc'}
                        onClick={() => this.onOrdenar('codigo')}>
                        {'Codigo'}
                      </TableSortLabel>
                    </TableCell>

                    <TableCell key={'fechasolicitud'}>
                      <TableSortLabel
                        direction={this.state.ordenar['fechasolicitud'] ? this.state.ordenar['fechasolicitud'] : 'asc'}
                        onClick={() => this.onOrdenar('fechasolicitud')}>
                        {'Fecha Solicitud'}
                      </TableSortLabel>
                    </TableCell>

                    <TableCell key={'empresa'}>
                      <TableSortLabel
                        direction={this.state.ordenar['empresa'] ? this.state.ordenar['empresa'] : 'asc'}
                        onClick={() => this.onOrdenar('empresa')}>
                        {'Empresa / Centro / Evento'}
                      </TableSortLabel>
                    </TableCell>

                    <TableCell key={'fechaevento'}>
                      <TableSortLabel
                        direction={this.state.ordenar['fechaeventooriginal'] ? this.state.ordenar['fechaeventooriginal'] : 'asc'}
                        onClick={() => this.onOrdenar('fechaeventooriginal')}>
                        {'Fecha Función'}
                      </TableSortLabel>
                    </TableCell>

                    <TableCell key={'nombre'}>
                      <TableSortLabel
                        direction={this.state.ordenar['nombre'] ? this.state.ordenar['nombre'] : 'asc'}
                        onClick={() => this.props.onOrdenar('nombre')}>
                        {'Nombre'}
                      </TableSortLabel>
                    </TableCell>

                    <TableCell key={'email'}>
                      <TableSortLabel
                        direction={this.state.ordenar['email'] ? this.state.ordenar['email'] : 'asc'}
                        onClick={() => this.onOrdenar('email')}>
                        {'Email'}
                      </TableSortLabel>
                    </TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {(this.state.rowsPerPage > 0
                    ? this.state.data.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                    : this.state.data
                  ).map((element, index) => (
                    <Grow in={true} timeout={index < 50 ? 100 * index : 0} key={element.idcodigos} >
                      <TableRow>
                        <TableCell>
                          <Box>
                            {
                              botonesConfirmarDenegar(element)
                            }
                          </Box>
                        </TableCell>

                        <TableCell>
                          <Box display='flex' alignItems='center'>
                            <Typography align="center">{element.codigo}</Typography>
                          </Box>
                        </TableCell>

                        <TableCell>{element.fechasolicitud}</TableCell>

                        <TableCell padding='default'>
                          <Container>
                            <div>{element.empresa}</div>
                            <div>{element.centro}</div>
                            <div>{element.evento}</div>
                          </Container>
                        </TableCell>

                        <TableCell>{element.fechaevento}</TableCell>

                        <TableCell>{element.nombre}</TableCell>

                        <TableCell>{element.email}</TableCell>

                      </TableRow>
                    </Grow>
                  ))}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 25 * emptyRows }}>
                      <TableCell colSpan={8} />
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[10, 20, 50, { label: 'All', value: -1 }]}
                      colSpan={8}
                      count={this.state.data.length}
                      rowsPerPage={this.state.rowsPerPage}
                      page={this.state.page}
                      onChangePage={(e, page) => this.handleChangePage(page)}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>

          </Box>
        </Box>

      </Container>;





    return (
      <div className="background" >

        <Header title="Usuarios" history={this.props.history}></Header>

        <div style={{ height: 40 }}></div>

        <Typography align="center" variant="h5">Gestión de Reservas</Typography>

        <div style={{ height: 20 }}></div>

        { Registros}

        <div style={{ height: 20 }}></div >

        { this.state.error === true ? AlertloginFailed : ''}

        {
          this.state.showConfirmationConfirmar === true && this.state.seleccionado.codigo ?
            (<PopUpConfirmacion messageInfo={'¿Confirmar solicitud de entrada?'}
              onOk={() => {
                let formulario = {
                  codigo: this.state.seleccionado.codigo,
                  confirmada: "1"
                };

                this.onSave(formulario);
              }}
              onCancel={this.onHideConfirmation} />)
            : ''
        }

        {
          this.state.showConfirmationDenegar === true && this.state.seleccionado.codigo ?
            (<PopUpConfirmacion messageInfo={'Denegar solicitud de entrada?'}
              onOk={() => {
                let formulario = {
                  codigo: this.state.seleccionado.codigo,
                  denegada: "1"
                };

                this.onSave(formulario);
              }}
              onCancel={this.onHideConfirmation} />)
            : ''
        }

      </div >
    );
  }

}

export default Reservas;
