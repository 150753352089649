import React from 'react';
import Rating from '../Rating/Rating';
import LinkButton from '../LinkButton/LinkButton';
import "./ShowDataSheet.css";

const MAX_RANKING = 5;
const TO_BE_CONFIRMED = 'Por confirmar';

const ShowDataSheet = (props) => {

	const {
		images,
		title,
		subtitle,
		text,
		address,
		sheet,
		ranking
	} = props.info;

	const Cast = () => (
		sheet.cast.map(
			(item) => (
				<p key={item.artist}>
					<span className="bold">{item.artist}</span> {item.character && <span> / {item.character}</span>}
				</p>
			)
		)
	);

	const Cartel = () => (
		sheet.cartel.map(
			(item) => (
				<p key={item.artist}>
					<span className="bold">{item.artist}</span> {item.character && <span> / {item.character}</span>}
				</p>
			)
		)
	);

	const Tour = () => (
		sheet.tour.map(
			(item) => (
				<p key={item.city}><span className="bold">{item.city}</span> / {item.date}</p>
			)
		)
	);

	const getCrowd = (param) => {
		if (Object.keys(param).length === 1) {
			const prop = Object.keys(param).toString();
			if (prop === 'company') {
				return (
					<React.Fragment>
						<h4>Elenco:</h4>
						<p>Por confirmar</p>
					</React.Fragment>
				);
			} else if (prop === 'cartel') {
				return (
					<React.Fragment>
						<h4>Cartel:</h4>
						<Cartel />
					</React.Fragment>
				);
			} else if (prop === 'tour') {
				return (
					<React.Fragment>
						<h4>Fechas confirmadas:</h4>
						<Tour />
					</React.Fragment>
				);
			}
		} else {
			return (
				<React.Fragment>
					<h4>Elenco:</h4>
					<Cast />
				</React.Fragment>
			);
		}
	};

	const Company = () => (
		sheet.company.map(
			(item) => (
				<p key={item.function}><span className="bold">{item.function}</span> / {item.name}</p>
			)
		)
	);
	
	return (
		<div className="show-data-sheet-content">
			<div className="show-data-sheet-content_image">
				<div className="show-data-sheet-content_image-container">
					<img src={images.header} alt="" />
				</div>
			</div>
			<div className="show-data-sheet-content_info">
				<div className="show-data-sheet-content_info-cover">
					<img src={images.cover} alt="" />
				</div>
				<div className="show-data-sheet-content_info-data">
					<div className="show-data-sheet-content_info-header">
						<Rating 
							completed={ranking === MAX_RANKING ? true : false} 
							filled={ranking < MAX_RANKING ? ranking : false}
						/>
						<h1>{title}</h1>
						<h2>{subtitle}</h2>
						<p style={{whiteSpace: 'pre-line', textAlign: 'justify'}}>{text}</p>
					</div>
					<div className="show-data-sheet-content_info-body">
						{
							address ? 
								<h3>
									{
										address.place && address.place
									}
									{
										address.time && address.time.length > 0 && <span> | {address.time}</span>
									}
									{
										address.calification && <span> | {address.calification}</span>
									}
								</h3> 
								: ''
						}
						<div className="show-data-sheet-content_info-body-interaction">
							<div className="show-data-sheet-content_info-body-interaction-rrss">
								<img src="/images/publication-icons.png" alt="" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="show-data-sheet-content_data-sheet">
				<span className="show-data-sheet-content_data-sheet-header primary-bg-color p-5">Ficha técnica</span>
				<div className="show-data-sheet-content_data-sheet-body">
					<div>
						{
							address ? 
								<h3>
									{
										address.place && address.place
									}
									{
										address.time && address.time.length > 0 && <span> {address.time}</span>
									}
									{
										address.calification && <span> {address.calification}</span>
									}
								</h3> 
								: ''
						}
						{
							getCrowd(sheet)
						}
					</div>
					<div>
						{
							sheet.company && <Company />
						}
					</div>
				</div>
			</div>
		</div>
	)
}

export default ShowDataSheet;
