import React from 'react';
import './InfoGrid.css';

class InfoGrid extends React.Component {

  render() {
    return (
      <div className="mp-info">
        <div className="mp-info-wrapper">
          <article>
            <div className="cabecera">
              <h1 className='texto-cabecera1 bold'>!El pasaporte que une</h1>
              <h1 className='texto-cabecera2 bold'>empresa <span className="text-white">y</span> cultura!</h1>
            </div>
          </article>
          
          <article id="card_1" className="mp-info__card">
            <div className="mp-info__card_wrapper">
              <div className="mp-info__card-content">
                <div className="mp-info__card-content-title">
                  <span className="primary-bg-color p-5">Fideliza</span> a tus clientes, socios y empleados regalando cultura.
                </div>
                <div className="mp-info__card-content-text">
                  <span className="bold">musical pass</span> es la forma más cómoda de premiar con cultura y obtener ventajas y descuentos para las experiencias musicales del momento.
                </div>
                <div className="mp-info__card-content-highlight-group">
                  <div className="bold p-5 primary-bg-color">Teatro musical</div>
                  <div className="bold p-5 secondary-bg-color">Conciertos</div>
                  <div className="bold p-5 tertiary-bg-color">Festivales</div>
                </div>
                <div className="mp-info__card-content-highlight p-5 primary-bg-color">
                  <span className="bold">¡Fideliza a tu comunidad!</span>
                </div>
              </div>
              <div className="mp-info__card-image">
                <img src="/images/info-cards/tarjetas.png" alt="" title="Tarjetas" />
              </div>
            </div>
          </article>
          <article id="card_2" className="mp-info__card">
            <div className="mp-info__card_wrapper">
              <div className="mp-info__card-content">
                <div className="mp-info__card-content-title">
                  ¿Qué es <span className="primary-bg-color p-5">musical pass?</span>
                </div>
                <div className="mp-info__card-content-text">
                  <span className="bold">musical pass</span> es un pasaorte personal que te da acceso y ventajas exclusivas para la comunidad a los mejores espectáculos musicales.
                </div>
                <div className="mp-info__card-content-highlight-ticket">
                  <img src="/images/info-cards/icono-logo-negro.png" alt="" title="Tarjetas" />
                  <span className="bold p-5 light-grey-bg-color">¡Un regalo perfecto!</span>
                </div>
                <div className="mp-info__card-content-highlight p-5">
                  Premia a tus afiliados con cultura, definimos contigo tu programa de puntos.
                </div>
              </div>
              <div className="mp-info__card-image">
                <img src="/images/info-cards/tarjeton.png" alt="" title="Tarjetón" />
              </div>
            </div>
          </article>
          <article id="card_3" className="mp-info__card">
            <div className="mp-info__card_wrapper">
              <div className="mp-info__card-content">
                <div className="mp-info__card-content-title">
                  <span className="primary-bg-color p-5">¿Cómo funciona?</span>
                </div>
                <div className="mp-info__card-content-text">
                  <p>Las empresas tienen <span className="bold">la oportunidad de regalar cultura</span> para enriquecer las relaciones o los detalles con clientes, socios o empleados.</p>
                  <p>Las personas que lo reciben pueden elegir el espectáculo al que quieren acudir con su <span className="bold">musical pass</span> y a partir de ese momento podrán beneficiarse de descuentos y precios especiales para la comunidad musical pass para otros eventos y espectáculos futuros.</p>
                </div>
                <div className="mp-info__card-content-wizard">
                  <div className="mp-info__card-content-wizard-item">
                    <div className="mp-info__card-content-wizard-item-number">
                      <img src="/images/info-cards/numero-1.png" alt="" title="Uno" />
                    </div>
                    <div className="mp-info__card-content-wizard-item-step">
                      <div className="mp-info__card-content-wizard-item-step-image">
                        <img src="/images/info-cards/paso-1.png" alt="" title="Paso uno" />
                      </div>
                      <div className="mp-info__card-content-wizard-item-step-explanation">
                        <p className="bold">Entra en musical-pass.com, rellena los datos y escoge la fecha.</p>
                      </div>
                    </div>
                  </div>
                  <div className="mp-info__card-content-wizard-item">
                    <div className="mp-info__card-content-wizard-item-number">
                      <img src="/images/info-cards/numero-2.png" alt="" title="Dos" />
                    </div>
                    <div className="mp-info__card-content-wizard-item-step">
                      <div className="mp-info__card-content-wizard-item-step-image">
                        <img src="/images/info-cards/paso-2.png" alt="" title="Paso dos" />
                      </div>
                      <div className="mp-info__card-content-wizard-item-step-explanation">
                        <p className="bold">Elige tu espectáculo y descarga tu pass en pdf o passbook.</p>
                      </div>
                    </div>
                  </div>
                  <div className="mp-info__card-content-wizard-item">
                    <div className="mp-info__card-content-wizard-item-number">
                      <img src="/images/info-cards/numero-3.png" alt="" title="Tres" />
                    </div>
                    <div className="mp-info__card-content-wizard-item-step">
                      <div className="mp-info__card-content-wizard-item-step-image">
                        <img src="/images/info-cards/paso-3.png" alt="" title="Paso tres" />
                      </div>
                      <div className="mp-info__card-content-wizard-item-step-explanation">
                        <p className="bold">Presenta tu pass en la entrada del recinto para acceder al espectáculo.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mp-info__card-content-text">
                  <p>Una vez registrado pertenecerás a la comunidad <span className="bold">musical pass</span> y obtendrás información de todas nuestras producciones para poder acceder con nuestros descuentos y ventajas exclusivas.</p>
                  <p className="bold">¡Muy pronto te informaremos de todas las novedades!</p>
                </div>
              </div>
              <div className="mp-info__card-image">
                <img src="/images/info-cards/m-amarilla.png" alt="" title="Musical pass" />
              </div>
            </div>
          </article>
          <article id="card_4" className="mp-info__card">
            <div className="mp-info__card_wrapper">
              <div className="mp-info__card-content">
                <div className="mp-info__card-content-title">
                  ¡Tu <span className="white-bg-color p-5">pasaporte</span> a la cultura!
                </div>
                <div className="mp-info__card-content-text">
                  Conecta tu empresa a <span className="bold">experiencias únicas</span>.
                </div>
                <div className="mp-info__card-content-wallets">
                  <div className="mp-info__card-content-wallets-images">
                    <span>
                      <img src="/images/wallet-google.png" alt="" title="Wallet Google" />
                    </span>
                    <span>
                      <img src="/images/wallet-apple.png" alt="" title="Wallet Apple" />
                    </span>
                  </div>
                  <p>Soporte digital de musical pass descargable en tu teléfono o passbook.</p>
                </div>
                <div className="mp-info__card-content-highlight-ticket">
                  <img src="/images/info-cards/icono-logo-blanco.png" alt="" title="Tarjetas" />
                  <span className="bold p-5">¡Regala cultura!</span>
                </div>
              </div>
              <div className="mp-info__card-image">
                <img src="/images/info-cards/general.png" alt="" title="Pasaporte cultural" />
              </div>
            </div>
          </article>
        </div>
      </div>
    );
  }
}

export default InfoGrid;