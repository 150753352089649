import React from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Grow from '@material-ui/core/Grow';
import Typography from '@material-ui/core/Typography';

import IconButton from '@material-ui/core/IconButton';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';

import PopUpInfo from '../../component/popUpInfo';


import '../../css/style.css';

class EditarCentro extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      idcentros: this.props.item.idcentros,
      nombre: this.props.item.nombre,
      telefono: this.props.item.telefono,
      email: this.props.item.email,
      direccion: this.props.item.direccion,
      image: this.props.item.imagen,
    }

    this.onChangeInput = this.onChangeInput.bind(this);
    this.onSelectFile = this.onSelectFile.bind(this);
  }

  componentDidMount() {
  }

  onChangeInput(event) {
    this.setState({ [event.target.id]: event.target.value });
  }

  onSelectFile(event) {
    console.log(event.target.files[0]);
    this.setState({ imagen: event.target.files[0] });
  }

  onGuardar = () => {
    this.props.onGuardar({
      idcentros: this.state.idcentros,
      nombre: this.state.nombre,
      telefono: this.state.telefono,
      email: this.state.email,
      direccion: this.state.direccion,
      imagen: this.state.imagen
    });
  }

  onBack = () => {
    this.props.onBack();
  }

  render() {

    const AlertloginFailed = <PopUpInfo messageInfo={this.state.errorText}/>

    return (
      <Grow in={true} timeout={500}>
        <Box className="box-blanco">

          <Typography align="center" variant="h5">Centros</Typography>

          <div style={{ height: 20 }}></div>

          <Box display="flex" alignItems="center">

            <input accept="image/*" id="centros-upload-imagen" type="file" style={{ display: "none" }} onChange={this.onSelectFile} />
            <label htmlFor="centros-upload-imagen">
              <IconButton color="primary" component="span" style={{ border: '1px solid white', marginRight: 20 }}>
                <PhotoCameraIcon />
              </IconButton>
            </label>

            <TextField
              margin="dense"
              required
              fullWidth
              autoFocus
              id="nombre"
              label="Nombre"
              name="nombre"
              autoComplete="nombre"
              size='small'
              value={this.state.nombre}

              onChange={this.onChangeInput}
            />

          </Box>

          <TextField
            margin="dense"
            required
            fullWidth
            id="telefono"
            label="Telefono"
            name="telefono"
            autoComplete="telefono"
            size='small'
            value={this.state.telefono}

            onChange={this.onChangeInput}
          />

          <TextField
            margin="dense"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            size='small'
            value={this.state.email}

            onChange={this.onChangeInput}
          />

          <TextField
            margin="dense"
            required
            fullWidth
            id="direccion"
            label="Direccion"
            name="direccion"
            autoComplete="direccion"
            size='small'
            value={this.state.direccion}

            onChange={this.onChangeInput}
          />

          <div style={{ height: 20 }}></div>

          <Box display="flex" justifyContent="center">

            <Button
              fullWidth
              variant="contained"
              disabled={this.state.loading}
              color="primary"
              style={{ margin: 5 }}
              onClick={this.onGuardar}
            >
              Guardar
          </Button>

            <Button
              fullWidth
              variant="contained"
              color="primary"
              style={{ margin: 5 }}
              onClick={this.onBack}
            >
              Volver
          </Button>

          </Box>

          <div style={{ height: 20 }}></div>

          {this.state.error === true ? AlertloginFailed : ''}

        </Box>
      </Grow>
    );
  }

}

export default EditarCentro;
