import { Constantes } from '../util/constantes';


export const Usuarios = {
    login,
    registro,
    logout,

    guardar,
    recuperarcuenta,
    getUsuarios,
    getUsuariosMiCuenta,
    borrar,

    validar,
    asignarFechaEventoACodigo,
    generarCodigos,
    solicitaCodigos,
    getCodigos,
    saveCodigo,
    liberarEntrada,
    getCodigosResumen,
    getFechasDisponibles,
    getPasaportes,

    getReservas,
    saveReserva,

    ExportToCSV,
    ImportFromCSV,

    getPerfiles,
    getProvincias,

    getOrdenCompra,
    saveOrdenCompra,

    getOrdenCompraEventosCategorias,
    saveOrdenCompraEventosCategorias,
    borrarOrdenCompraEventosCategorias,

    getEventosParaCambio,
    setCambioEvento,

    getEntradasCategorias,

    getEvetosDisponiblesCategoria,
    obtenerEntradaDeCategoriaDisponible

};

async function login(usuario, clave) {

    const requestOptions = {
        method: 'post',
        headers: {
        },

        body: JSON.stringify({
            usuario: usuario,
            clave: clave,
        }),
    };

    const response = await fetch(`${Constantes.API_URL}usuarios/login.php`, requestOptions)
        .then((data) => {

            if (data) {
                return data.json()
            }
            else {
                return false;
            }

        })
        .then((info) => {

            if (info.records) {
                localStorage.setItem('userLogged', JSON.stringify(info.records[0]));
            }
            return info;
        })
        .catch((error) => {
            console.log(error);
            return false;
        });

    return response;
}

async function registro(formulario) {

    const requestOptions = {
        method: 'post',
        headers: {
        },
        redirect: 'follow',

        body: JSON.stringify(formulario),
    };

    const response = await fetch(`${Constantes.API_URL}usuarios/registro.php`, requestOptions)
        .then((data) => {

            if (data.status !== 200) {
                return data.json()
            }
            else {
                return true;
            }

        })
        .catch((error) => {
            console.log(error);
            return { message: error };
        });

    return response;
}


function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('userLogged');
    localStorage.removeItem('userToken');
}

async function guardar(formulario) {

    const requestOptions = {
        method: 'post',
        headers: Constantes.getAuthHeadersUpload(),
        body: formulario,
    };

    const response = await fetch(`${Constantes.API_URL}usuarios/save.php`, requestOptions)
        .then((data) => {
            if (data.status !== 200) {
                return data.json()
            }
            else {
                return true;
            }
        })
        .catch((error) => {
            console.log(error);
            return { message: error };
        });

    return response;

}

async function validar(formulario) {

    const requestOptions = {
        method: 'post',
        headers: Constantes.getAuthHeaders(),
        body: JSON.stringify(formulario),
    };

    const response = await fetch(`${Constantes.API_URL}codigos/validar.php`, requestOptions)
        .then((data) => {
            if (data.status !== 200) {
                return data.json()
            }
            else {
                return true;
            }
        })
        .catch((error) => {
            console.log(error);
            return { message: error };
        });

    return response;

}

async function asignarFechaEventoACodigo(formulario) {

    const requestOptions = {
        method: 'post',
        headers: Constantes.getAuthHeaders(),
        body: JSON.stringify(formulario),
    };

    const response = await fetch(`${Constantes.API_URL}codigos/savefechaevento.php`, requestOptions)
        .then((data) => {

            if (data.status !== 200) {
                return data.json()
            }
            else {
                return true;
            }
        })
        .catch((error) => {
            console.log(error);
            return { message: error };
        });

    return response;

}


async function getCodigos(formulario) {

    const requestOptions = {
        method: 'post',
        headers: Constantes.getAuthHeaders(),
        body: JSON.stringify(formulario),
    };

    const response = await fetch(`${Constantes.API_URL}codigos/read.php`, requestOptions)
        .then((data) => {
            return data.json()
        })
        .catch((error) => {
            console.log(error);
            return { message: error };
        });

    return response;
}

async function ExportToCSV(formulario) {

    const requestOptions = {
        method: 'post',
        headers: Constantes.getAuthHeaders(),
        body: JSON.stringify(formulario),
    };

    const response = await fetch(`${Constantes.API_URL}codigos/exporttocsv.php`, requestOptions)
        .then((data) => {
            if (data.status === 200) {
                return data.text()
            }
            else {
                return false;
            }
        })
        .catch((error) => {
            console.log(error);
            return { message: error };
        });

    return response;
}

async function ImportFromCSV(file) {

    const requestOptions = {
        method: 'post',
        headers: Constantes.getAuthHeadersUpload(),
        body: file,
    };

    const response = await fetch(`${Constantes.API_URL}codigos/importfromcsv.php`, requestOptions)
        .then((data) => {
            if (data.status === 200) {
                return data.text()
            }
            else {
                return false;
            }
        })
        .catch((error) => {
            console.log(error);
            return { message: error };
        });

    return response;
}

async function getCodigosResumen(formulario) {

    const requestOptions = {
        method: 'post',
        headers: Constantes.getAuthHeaders(),
        body: JSON.stringify(formulario),
    };

    const response = await fetch(`${Constantes.API_URL}codigos/readresumen.php`, requestOptions)
        .then((data) => {
            return data.json()
        })
        .catch((error) => {
            console.log(error);
            return { message: error };
        });

    return response;
}

async function getUsuarios(formulario) {

    const requestOptions = {
        method: 'post',
        headers: Constantes.getAuthHeaders(),
        body: JSON.stringify(formulario)
    };

    const response = await fetch(`${Constantes.API_URL}usuarios/read.php`, requestOptions)
        .then((data) => {
            return data.json()
        })
        .catch((error) => {
            console.log(error);
            return { message: error };
        });

    return response;

}

async function getUsuariosMiCuenta() {

    const requestOptions = {
        method: 'post',
        headers: Constantes.getAuthHeaders(),
        body: ''
    };

    const response = await fetch(`${Constantes.API_URL}usuarios/readMiCuenta.php`, requestOptions)
        .then((data) => {
            return data.json()
        })
        .catch((error) => {
            console.log(error);
            return { message: error };
        });

    return response;

}

async function recuperarcuenta(email) {

    const requestOptions = {
        method: 'post',
        headers: Constantes.getAuthHeaders(),
        body: JSON.stringify(email),
    };

    const response = await fetch(`${Constantes.API_URL}usuarios/recuperarcuenta.php`, requestOptions)
        .then((data) => {
            return (data.status == 200)
        })
        .catch((error) => {
            console.log(error);
            return { message: error };
        });

    return response;

}


async function generarCodigos(formulario) {

    const requestOptions = {
        method: 'post',
        headers: Constantes.getAuthHeaders(),
        body: JSON.stringify(formulario),

    };

    const response = await fetch(`${Constantes.API_URL}codigos/generarcodigos.php`, requestOptions)
        .then((data) => {
            return data.json()
        })
        .catch((error) => {
            console.log(error);
            return { message: error };
        });

    return response;

}

async function solicitaCodigos(formulario) {

    const requestOptions = {
        method: 'post',
        headers: Constantes.getAuthHeaders(),
        body: JSON.stringify(formulario),

    };

    const response = await fetch(`${Constantes.API_URL}codigos/solicitacodigos.php`, requestOptions)
        .then((data) => {
            return data.json()
        })
        .catch((error) => {
            console.log(error);
            return { message: error };
        });

    return response;

}

async function borrar(id) {

    const requestOptions = {
        method: 'post',
        headers: Constantes.getAuthHeaders(),
        body: JSON.stringify({ id: id }),
    };

    const response = await fetch(`${Constantes.API_URL}usuarios/delete.php`, requestOptions)
        .then((data) => {

            if (data.status !== 200) {
                return data.json()
            }
            else {
                return true;
            }
        })
        .catch((error) => {
            console.log(error);
            return { message: error };
        });

    return response;

}

async function getPerfiles() {

    const requestOptions = {
        method: 'post',
        headers: Constantes.getAuthHeaders(),
        body: ''
    };

    const response = await fetch(`${Constantes.API_URL}usuarios/perfiles.php`, requestOptions)
        .then((data) => {
            return data.json()
        })
        .catch((error) => {
            console.log(error);
            return { message: error };
        });

    return response;
}

async function getProvincias() {

    const requestOptions = {
        method: 'post',
        headers: Constantes.getAuthHeaders(),
        body: ''
    };

    const response = await fetch(`${Constantes.API_URL}usuarios/provincias.php`, requestOptions)
        .then((data) => {
            return data.json()
        })
        .catch((error) => {
            console.log(error);
            return { message: error };
        });

    return response;
}

async function getFechasDisponibles(codigo) {

    const requestOptions = {
        method: 'post',
        headers: Constantes.getAuthHeaders(),
        body: JSON.stringify(codigo),
    };

    const response = await fetch(`${Constantes.API_URL}codigos/fechasdisponibles.php`, requestOptions)
        .then((data) => {
            return data.json()
        })
        .catch((error) => {
            console.log(error);
            return { message: error };
        });

    return response;

}


async function saveCodigo(formulario) {

    const requestOptions = {
        method: 'post',
        headers: Constantes.getAuthHeaders(),
        body: JSON.stringify(formulario),
    };

    const response = await fetch(`${Constantes.API_URL}codigos/save.php`, requestOptions)
        .then((data) => {

            if (data.status !== 200) {
                return data.json()
            }
            else {
                return true;
            }
        })
        .catch((error) => {
            console.log(error);
            return { message: error };
        });

    return response;
}

async function liberarEntrada(formulario) {

    const requestOptions = {
        method: 'post',
        headers: Constantes.getAuthHeaders(),
        body: JSON.stringify(formulario),
    };

    const response = await fetch(`${Constantes.API_URL}codigos/liberarentrada.php`, requestOptions)
        .then((data) => {

            if (data.status !== 200) {
                return data.json()
            }
            else {
                return true;
            }
        })
        .catch((error) => {
            console.log(error);
            return { message: error };
        });

    return response;
}




async function getOrdenCompra(formulario) {

    const requestOptions = {
        method: 'post',
        headers: Constantes.getAuthHeaders(),
        body: JSON.stringify(formulario),
    };

    const response = await fetch(`${Constantes.API_URL}ordencompra/read.php`, requestOptions)
        .then((data) => {
            return data.json()
        })
        .catch((error) => {
            console.log(error);
            return { message: error };
        });

    return response;
}

async function saveOrdenCompra(formulario) {

    const requestOptions = {
        method: 'post',
        headers: Constantes.getAuthHeaders(),
        body: JSON.stringify(formulario),
    };

    const response = await fetch(`${Constantes.API_URL}ordencompra/save.php`, requestOptions)
        .then((data) => {

            if (data.status !== 200) {
                return data.json()
            }
            else {
                return true;
            }
        })
        .catch((error) => {
            console.log(error);
            return { message: error };
        });

    return response;
}

async function getOrdenCompraEventosCategorias(formulario) {

    const requestOptions = {
        method: 'post',
        headers: Constantes.getAuthHeaders(),
        body: JSON.stringify(formulario),
    };

    const response = await fetch(`${Constantes.API_URL}ordencompraEventosCategorias/read.php`, requestOptions)
        .then((data) => {
            return data.json()
        })
        .catch((error) => {
            console.log(error);
            return { message: error };
        });

    return response;
}

async function saveOrdenCompraEventosCategorias(formulario) {

    const requestOptions = {
        method: 'post',
        headers: Constantes.getAuthHeaders(),
        body: JSON.stringify(formulario),
    };

    const response = await fetch(`${Constantes.API_URL}ordencompraEventosCategorias/save.php`, requestOptions)
        .then((data) => {

            if (data.status !== 200) {
                return data.json()
            }
            else {
                return true;
            }
        })
        .catch((error) => {
            console.log(error);
            return { message: error };
        });

    return response;
}

async function borrarOrdenCompraEventosCategorias(formulario) {

    const requestOptions = {
        method: 'post',
        headers: Constantes.getAuthHeaders(),
        body: JSON.stringify(formulario),
    };

    const response = await fetch(`${Constantes.API_URL}ordencompraEventosCategorias/delete.php`, requestOptions)
        .then((data) => {

            if (data.status !== 200) {
                return data.json()
            }
            else {
                return true;
            }
        })
        .catch((error) => {
            console.log(error);
            return { message: error };
        });

    return response;

}


async function getPasaportes() {

    const requestOptions = {
        method: 'get',
        headers: Constantes.getAuthHeaders(),
    };

    const response = await fetch(`${Constantes.API_URL}usuarios/readPasaportes.php`, requestOptions)
        .then((data) => {
            return data.json()
        })
        .catch((error) => {
            console.log(error);
            return { message: error };
        });

    return response;
}


async function getReservas(formulario) {

    const requestOptions = {
        method: 'post',
        headers: Constantes.getAuthHeaders(),
        body: JSON.stringify(formulario),
    };

    const response = await fetch(`${Constantes.API_URL}codigos/readReservas.php`, requestOptions)
        .then((data) => {
            return data.json()
        })
        .catch((error) => {
            console.log(error);
            return { message: error };
        });

    return response;
}

async function saveReserva(formulario) {

    const requestOptions = {
        method: 'post',
        headers: Constantes.getAuthHeaders(),
        body: JSON.stringify(formulario),
    };

    const response = await fetch(`${Constantes.API_URL}codigos/saveReserva.php`, requestOptions)
        .then((data) => {
            return data.json()
        })
        .catch((error) => {
            console.log(error);
            return { message: error };
        });

    return response;
}



async function getEventosParaCambio(formulario) {

    const requestOptions = {
        method: 'post',
        headers: Constantes.getAuthHeaders(),
        body: JSON.stringify(formulario),
    };

    const response = await fetch(`${Constantes.API_URL}codigos/eventosparacambioentrada.php`, requestOptions)
        .then((data) => {
            return data.json()
        })
        .catch((error) => {
            console.log(error);
            return { message: error };
        });

    return response;

}

async function setCambioEvento(formulario) {

    const requestOptions = {
        method: 'post',
        headers: Constantes.getAuthHeaders(),
        body: JSON.stringify(formulario),
    };

    const response = await fetch(`${Constantes.API_URL}codigos/eventocambiarentrada.php`, requestOptions)
        .then((data) => {
            if (data.status !== 200) {
                return data.json()
            }
            else {
                return true;
            }
        })
        .catch((error) => {
            console.log(error);
            return { message: error };
        });

    return response;
}


async function getEntradasCategorias() {

    const requestOptions = {
        method: 'get',
        headers: Constantes.getAuthHeaders(),
    };

    const response = await fetch(`${Constantes.API_URL}usuarios/getentradascategorias.php`, requestOptions)
        .then((data) => {
            return data.json()
        })
        .catch((error) => {
            console.log(error);
            return { message: error };
        });

    return response;
}

async function getEvetosDisponiblesCategoria(formulario) {

    const requestOptions = {
        method: 'post',
        headers: Constantes.getAuthHeaders(),
        body: JSON.stringify(formulario),
    };

    const response = await fetch(`${Constantes.API_URL}codigos/geteventosdisponiblesporcategoria.php`, requestOptions)
        .then((data) => {
            return data.json()
        })
        .catch((error) => {
            console.log(error);
            return { message: error };
        });

    return response;
}


async function obtenerEntradaDeCategoriaDisponible(formulario) {

    const requestOptions = {
        method: 'post',
        headers: Constantes.getAuthHeaders(),
        body: JSON.stringify(formulario),
    };

    const response = await fetch(`${Constantes.API_URL}codigos/seteventosdisponiblesporcategoria.php`, requestOptions)
        .then((data) => {
            if (data.status !== 200) {
                return data.json()
            }
            else {
                return true;
            }
        })
        .catch((error) => {
            console.log(error);
            return { message: error };
        });

    return response;
}


