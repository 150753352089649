import React from 'react';

import Button from '@material-ui/core/Button';
import { FormControl, InputLabel, Select, MenuItem, Container } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Grow from '@material-ui/core/Grow';
import Typography from '@material-ui/core/Typography';

import IconButton from '@material-ui/core/IconButton';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';

import PopUpInfo from '../../component/popUpInfo';

import { Eventos } from '../../entity/eventos';

import '../../css/style.css';
import moment from 'moment';

class EventoResumenView extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
    }
  }

  render() {

    return (
      <Grow in={true}>

        <Container>
          <div style={{ height: 40 }}></div>

          <Typography align='center' variant="h4">¡Esperamos que disfrutes de la Experiencia!</Typography>

          <div style={{ height: 20 }}></div>

          <Typography align='center' variant="h5">Estos son los datos del canje realizado</Typography>

          <div style={{ height: 20 }}></div>

          <Box display='flex' m={1} justifyContent='center'>

            <Box m={2}>
              <img src={this.props.item.eventoimagen} alt={this.props.item.nombre} className="user-list__img" />
            </Box>

            <Box m={2}>
              <Box display='flex' flexDirection='column' alignItems='flex-start'>
                <Typography variant="h5">{this.props.item.evento}</Typography>
                <Typography variant="body1">{this.props.item.centro}</Typography>
                <Typography variant="body2">{this.props.item.direccion}</Typography>
                <Typography variant="body2">{`Entradas ${this.props.item.entradas}`}</Typography>
                <Typography variant="body2">{`Fecha ${moment(this.props.item.fechaEvento).format('DD/MM/YYYY')}`}</Typography>
                <Typography variant="body2">{`Hora ${this.props.item.hora}`}</Typography>

                <div style={{ height: 40 }}></div>

                <Button fullWidth variant="contained" color="primary" onClick={this.props.onClick}>Continuar</Button>

              </Box>
            </Box>

          </Box>

        </Container>

      </Grow>
    );
  }

}

export default EventoResumenView;
