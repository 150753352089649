import { Constantes } from '../util/constantes';


export const Eventos = {
    guardar,
    getEventos,
    borrar,
    
    getEventosCategorias,
    guardarCategoria,
    borrarCategoria
};


async function guardar(formulario) {

    const requestOptions = {
        method: 'post',
        headers: Constantes.getAuthHeadersUpload(),
        body: formulario,
    };

    const response = await fetch(`${Constantes.API_URL}eventos/save.php`, requestOptions)
        .then((data) => {

            if (data.status !== 200) {
                return data.json()
            }
            else {
                return true;
            }
        })
        .catch((error) => {
            console.log(error);
            return { message: error };
        });

    return response;

}

async function getEventos() {

    const requestOptions = {
        method: 'get',
        headers: Constantes.getAuthHeaders()
    };

    const response = await fetch(`${Constantes.API_URL}eventos/read.php`, requestOptions)
        .then((data) => {
            return data.json()
        })
        .catch((error) => {
            console.log(error);
            return { message: error };
        });

    return response;

}

async function borrar(id) {

    const requestOptions = {
        method: 'post',
        headers: Constantes.getAuthHeaders(),
        body: JSON.stringify({id: id}),
    };

    const response = await fetch(`${Constantes.API_URL}eventos/delete.php`, requestOptions)
        .then((data) => {

            if (data.status !== 200) {
                return data.json()
            }
            else {
                return true;
            }
        })
        .catch((error) => {
            console.log(error);
            return { message: error };
        });

    return response;

}

async function getEventosCategorias() {

    const requestOptions = {
        method: 'get',
        headers: Constantes.getAuthHeaders()
    };

    const response = await fetch(`${Constantes.API_URL}eventosCategorias/read.php`, requestOptions)
        .then((data) => {
            return data.json()
        })
        .catch((error) => {
            console.log(error);
            return { message: error };
        });

    return response;

}

async function guardarCategoria(formulario) {

    const requestOptions = {
        method: 'post',
        headers: Constantes.getAuthHeadersUpload(),
        body: formulario,
    };

    const response = await fetch(`${Constantes.API_URL}eventosCategorias/save.php`, requestOptions)
        .then((data) => {

            if (data.status !== 200) {
                return data.json()
            }
            else {
                return true;
            }
        })
        .catch((error) => {
            console.log(error);
            return { message: error };
        });

    return response;

}

async function borrarCategoria(id) {

    const requestOptions = {
        method: 'post',
        headers: Constantes.getAuthHeaders(),
        body: JSON.stringify({id: id}),
    };

    const response = await fetch(`${Constantes.API_URL}eventosCategorias/delete.php`, requestOptions)
        .then((data) => {

            if (data.status !== 200) {
                return data.json()
            }
            else {
                return true;
            }
        })
        .catch((error) => {
            console.log(error);
            return { message: error };
        });

    return response;

}

