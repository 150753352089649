import React from 'react';
import './InfoCarousel.css';
import './InfoCards.css';

class InfoCarousel extends React.Component {

  constructor(props) {
    super(props);

    this.timerID = null;
    this.timerMs = 5000;
    this.switchInputID = '.slideshow-switch .switch input';

    this.state = {
      slideNumber: 1,
      timerEnabled: true
    }

    this.setCurrentSlide = this.setCurrentSlide.bind(this);
    this.forwardSlides = this.forwardSlides.bind(this);
    this.changeTimer = this.changeTimer.bind(this);
  }

  componentDidMount() {
    if (this.state.timerEnabled) this.changeTimer();
  }

  componentWillUnmount() {
    this.clearTimer();
  }

  initTimer() {
    const switchInput = document.querySelector(this.switchInputID);
    switchInput.checked = true;
    this.timerID = setInterval(
      () => this.setTimerSlider(this.state.slideNumber),
      this.timerMs
    );
  }

  clearTimer() {
    const switchInput = document.querySelector(this.switchInputID);
    switchInput.checked = false;
    clearTimeout(this.timerID);
  }

  changeTimer = () => {
    this.setState(() => {
      return { timerEnabled: !this.state.timerEnabled }
    });
    this.state.timerEnabled ? this.initTimer() : this.clearTimer();
  }

  setCurrentSlide(param) {
    this.clearTimer();
    this.showSlides(param);
  }

  forwardSlides(param) {
    this.clearTimer();
    this.showSlides(this.state.slideNumber += param);
  }

  setTimerSlider(n) {
    this.manageSlider(++n);
  }

  showSlides(n) {
    this.manageSlider(n);
  }

  manageSlider(n) {
    let slideIndex = n;
    const slides = document.querySelectorAll('.slides');
    const dots = document.querySelectorAll('.dot');
    if (slideIndex > slides.length) slideIndex = 1;
    if (slideIndex < 1) slideIndex = slides.length;
    for (let i = 0; i < slides.length; i++) {
      slides[i].classList.remove('block');
      slides[i].classList.add('none');
    }
    for (let i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].className.replace(' active', '');
    }
    slides[slideIndex-1].classList.remove('none');
    slides[slideIndex-1].classList.add('block');
    dots[slideIndex-1].className += ' active';
    this.setState({slideNumber: slideIndex});
  }

  render() {
    return (
      <div className="carousel-wrapper">
        <div className="slideshow-wrapper">
          <div className="slideshow-container">
            <div className="slides fade">
              <article id="card_1" className="mp-info__card">
                <div className="mp-info__card_wrapper">
                  <div className="mp-info__card-content">
                    <div className="mp-info__card-content-title">
                      <span className="primary-bg-color p-5">Fideliza</span> a tus clientes y empleados regalando cultura.
                    </div>
                    <div className="mp-info__card-content-text">
                      <span className="bold">musical pass</span> es la forma más cómoda de premiar con cultura y obtener ventajas y descuentos para las experiencias musicales del momento.
                    </div>
                    <div className="mp-info__card-content-highlight-group">
                      <div className="bold primary-bg-color">Teatro Musical</div>
                      <div className="bold secondary-light-bg-color">Conciertos</div>
                      <div className="bold tertiary-light-bg-color">Festivales</div>
                    </div>
                    <div className="mp-info__card-content-highlight p-5 primary-bg-color">
                    <span className="bold">¡Sólo para amantes de la música!</span>
                    </div>
                  </div>
                  <div className="mp-info__card-image">
                    <img src="/images/info-cards/tarjetas.png" alt="" title="Tarjetas" />
                  </div>
                </div>
              </article>
            </div>

            <div className="slides none fade">
              <article id="card_2" className="mp-info__card">
                <div className="mp-info__card_wrapper">
                  <div className="mp-info__card-content">
                    <div className="mp-info__card-content-title">
                      ¿Qué es <span className="primary-bg-color p-5">musical pass?</span>
                    </div>
                    <div className="mp-info__card-content-text">
                      <span className="bold">musical pass</span> es un pasaporte personal que te da acceso y ventajas exclusivas para la comunidad a los mejores espectáculos musicales.
                    </div>
                    <div className="mp-info__card-content-highlight-ticket">
                      <img src="/images/info-cards/icono-logo-negro.png" alt="" title="Tarjetas" />
                      <span className="bold p-5 light-grey-bg-color">¡Infórmate!</span>
                    </div>
                    <div className="mp-info__card-content-highlight p-5">
                      Premia a tus afiliados con cultura, definimos contigo tu programa de puntos.
                    </div>
                  </div>
                  <div className="mp-info__card-image">
                    <img src="/images/info-cards/tarjeton.png" alt="" title="Tarjetón" />
                  </div>
                </div>
              </article>
            </div>

            <div className="slides none fade">
              <article id="card_3" className="mp-info__card">
                <div className="mp-info__card_wrapper">
                  <div className="mp-info__card-content">
                    <div className="mp-info__card-content-title">
                      <span className="primary-bg-color p-5">¿Cómo funciona?</span>
                    </div>
                    <div className="mp-info__card-content-text">
                      <p>Las empresas tienen <span className="bold">la oportunidad de regalar cultura</span> para enriquecer las relaciones o los detalles con clientes, socios o empleados.</p>
                      <p>Las personas que lo reciben pueden elegir el espectáculo al que quieren acudir con su <span className="bold">musical pass</span> y a partir de ese momento podrán beneficiarse de descuentos y precios especiales para la comunidad musical pass para otros eventos y espectáculos futuros.</p>
                    </div>
                    <div className="mp-info__card-content-wizard">
                      <div className="mp-info__card-content-wizard-item">
                        <div className="mp-info__card-content-wizard-item-number">
                          <img src="/images/info-cards/numero-1.png" alt="" title="Uno" />
                        </div>
                        <div className="mp-info__card-content-wizard-item-step">
                          <div className="mp-info__card-content-wizard-item-step-image">
                            <img src="/images/info-cards/paso-1.png" alt="" title="Paso uno" />
                          </div>
                          <div className="mp-info__card-content-wizard-item-step-explanation">
                            <p className="bold">Entra en musical-pass.com, rellena los datos y escoge la fecha.</p>
                          </div>
                        </div>
                      </div>
                      <div className="mp-info__card-content-wizard-item">
                        <div className="mp-info__card-content-wizard-item-number">
                          <img src="/images/info-cards/numero-2.png" alt="" title="Dos" />
                        </div>
                        <div className="mp-info__card-content-wizard-item-step">
                          <div className="mp-info__card-content-wizard-item-step-image">
                            <img src="/images/info-cards/paso-2.png" alt="" title="Paso dos" />
                          </div>
                          <div className="mp-info__card-content-wizard-item-step-explanation">
                            <p className="bold">Elige tu espectáculo y descarga tu pass en pdf o passbook.</p>
                          </div>
                        </div>
                      </div>
                      <div className="mp-info__card-content-wizard-item">
                        <div className="mp-info__card-content-wizard-item-number">
                          <img src="/images/info-cards/numero-3.png" alt="" title="Tres" />
                        </div>
                        <div className="mp-info__card-content-wizard-item-step">
                          <div className="mp-info__card-content-wizard-item-step-image">
                            <img src="/images/info-cards/paso-3.png" alt="" title="Paso tres" />
                          </div>
                          <div className="mp-info__card-content-wizard-item-step-explanation">
                            <p className="bold">Presenta tu pass en la entrada del recinto para acceder al espectáculo.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mp-info__card-content-text">
                      <p>Una vez registrado pertenecerás a la comunidad <span className="bold">musical pass</span> y obtendrás información de todas nuestras producciones para poder acceder con nuestros descuentos y ventajas exclusivas.</p>
                      <p className="bold">¡Muy pronto te informaremos de todas las novedades!</p>
                    </div>
                  </div>
                  <div className="mp-info__card-image">
                    <img src="/images/info-cards/m-amarilla.png" alt="" title="Musical pass" />
                  </div>
                </div>
              </article>
            </div>

            <div className="slides none fade">
              <article id="card_4" className="mp-info__card">
                <div className="mp-info__card_wrapper">
                  <div className="mp-info__card-content">
                    <div className="mp-info__card-content-title">
                      ¡Tu <span className="white-bg-color p-5">pasaporte</span> a la cultura!
                    </div>
                    <div className="mp-info__card-content-text">
                      Conecta tu empresa a <span className="bold">experiencias únicas</span>.
                    </div>
                    <div className="mp-info__card-content-wallets">
                      <div className="mp-info__card-content-wallets-images">
                        <span>
                          <img src="/images/wallet-google.png" alt="" title="Wallet Google" />
                        </span>
                        <span>
                          <img src="/images/wallet-apple.png" alt="" title="Wallet Apple" />
                        </span>
                      </div>
                      <p>Soporte digital de musical pass descargable en tu teléfono o passbook.</p>
                    </div>
                    <div className="mp-info__card-content-highlight-ticket">
                      <img src="/images/info-cards/icono-logo-blanco.png" alt="" title="Tarjetas" />
                      <span className="bold p-5">¡Regala cultura!</span>
                    </div>
                  </div>
                  <div className="mp-info__card-image">
                    <img src="/images/info-cards/general.png" alt="" title="Pasaporte cultural" />
                  </div>
                </div>
              </article>
            </div>

          </div>

          <div className="arrows-wrapper">
            <span className="prev" onClick={() => this.forwardSlides(-1)}>&#10094;</span>
            <span className="next" onClick={() => this.forwardSlides(1)}>&#10095;</span>
          </div>

          <div className="dots-wrapper">
            <span className="dot active" onClick={() => this.setCurrentSlide(1)}></span>
            <span className="dot" onClick={() => this.setCurrentSlide(2)}></span>
            <span className="dot" onClick={() => this.setCurrentSlide(3)}></span>
            <span className="dot" onClick={() => this.setCurrentSlide(4)}></span>
          </div>
          
          <div className="slideshow-switch">
            <label className="switch">
              <input 
                type="checkbox" 
                onChange={this.changeTimer} 
              />
              <span className="slider round"></span>
            </label>
          </div>

        </div>
      </div>
    );
  }
}

export default InfoCarousel;