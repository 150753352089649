import React from 'react';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';

import { ButtonGroup, FormControl, Select, MenuItem, Collapse } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import Typography from '@material-ui/core/Typography';

import Header from '../../component/header';
import EditarEmailPlantilla from './editarEmailPlantilla';
import PopUpInfo from '../../component/popUpInfo';

import { EmailPlantilla } from '../../entity/emailplantilla';


import '../../css/style.css';

class GestionEmailPlantilla extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      rowsPerPage: 10,
      page: 0,
      ordenar: [],

      idbemail_plantilla: '',
      idempresas: '',
      nombre: '',

      data: [],
      seleccionado: {},
      showPanelBusquedas: false,

      buscarNombre: '',
      buscarEmpresa: '',

      loading: false,
      editando: false,
      error: false,
      errorText: ''
    }

    // gestionar la tabla
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.onOrdenar = this.onOrdenar.bind(this);

    this.onChangeInput = this.onChangeInput.bind(this);
    this.onGuardar = this.onGuardar.bind(this);
    this.onNuevo = this.onNuevo.bind(this);
    this.onCargar = this.onCargar.bind(this);
    this.onBack = this.onBack.bind(this);
    this.onBorrarItem = this.onBorrarItem.bind(this);
    this.onSeleccionarItem = this.onSeleccionarItem.bind(this);

    this.showBusquedas = this.showBusquedas.bind(this);

    let searchDelay = {};

  }

  componentDidMount() {
    this.onCargar({});
  }

  handleChangePage(newPage) {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage(event) {
    this.setState({ rowsPerPage: parseInt(event.target.value), page: 0 });
  };

  onOrdenar(por) {

    if (por) {

      let tmpOrdenar = this.state.ordenar;
      if (!tmpOrdenar[por]) {
        tmpOrdenar[por] = 'asc';
      }
      else {
        tmpOrdenar[por] = tmpOrdenar[por] === 'asc' ? 'desc' : 'asc';
      }

      let datosOrdenados = this.state.data;

      // si quieremos ordenar la fecha de vento, entonces se hace un tratamiento especial, ya que es una fecha
      tmpOrdenar[por] === 'asc' ?
        datosOrdenados.sort((a, b) => (a[por] > b[por]) ? 1 : ((b[por] > a[por]) ? -1 : 0))
        : datosOrdenados.sort((a, b) => (a[por] < b[por]) ? 1 : ((b[por] < a[por]) ? -1 : 0));

      this.setState({ data: datosOrdenados, ordenar: tmpOrdenar });
    }
  }

  onChangeInput(event) {

    this.setState({ [event.target.name]: event.target.value });

    clearTimeout(this.searchDelay);

    this.searchDelay = setTimeout(() => {
      this.onCargar({
        nombre: this.state.buscarNombre,
        empresa: this.state.buscarEmpresa,
      })
    }, 1500);
  }


  onCargar(formulario) {

    this.setState({ loading: true });

    EmailPlantilla.getPlantillas(formulario)
      .then(
        data => {
          if (data.records) {
            this.setState({ data: data.records, loading: false, editando: false });
          }
        }
      );
  }

  onGuardar(item) {

    this.setState({ loading: true });

    let formulario = new FormData()
    if (item.idemail_plantilla) formulario.append('idemail_plantilla', item.idemail_plantilla);
    if (item.nombre) formulario.append('nombre', item.nombre);
    if (item.idempresas) formulario.append('idempresas', item.idempresas);
    if (item.plantilla_cabecera) formulario.append('plantilla_cabecera', item.plantilla_cabecera);
    if (item.plantilla_detalle) formulario.append('plantilla_detalle', item.plantilla_detalle);
    if (item.plantilla_pie) formulario.append('plantilla_pie', item.plantilla_pie);

    EmailPlantilla.guardar(formulario)
      .then(
        valid => {
          if (valid === true) {
            this.onCargar({
              nombre: this.state.buscarNombre,
              empresa: this.state.buscarEmpresa,
            });
          }
          else {
            this.setState({ error: true, loading: false, errorText: valid.message });
          }
        }
      );

  }


  onSeleccionarItem(element) {
    this.setState({ seleccionado: element, editando: true, error: false });
  }

  onNuevo() {
    let item = {
      idemail_plantilla: '',
      nombre: '',
      idempresas: ''
    }

    this.setState({ seleccionado: item, editando: true });
  }

  onBorrarItem(item) {

    this.setState({ loading: true });

    let formulario = new FormData()
    formulario.append('id', item.idemail_plantilla);

    EmailPlantilla.borrar(formulario)
      .then(
        valid => {
          if (valid === true) {
            this.onCargar({
              nombre: this.state.buscarNombre,
              empresa: this.state.buscarEmpresa,
            });
          }
          else {
            this.setState({ error: true, loading: false, errorText: valid.message });
          }
        }
      );
  }

  onBack() {
    this.onCargar({
      nombre: this.state.buscarNombre,
      empresa: this.state.buscarEmpresa,
    });
  }

  showBusquedas() {
    this.setState({ showPanelBusquedas: !this.state.showPanelBusquedas });
  }

  render() {

    const emptyRows = 1;

    const AlertloginFailed = <PopUpInfo messageInfo={this.state.errorText} onClose={() => { this.setState({ error: false, errorText: '' }) }} />

    const Buscador =
      (<Box display='flex' flexWrap='wrap'>

        <Box display='flex' style={{ width: '100%' }}>

          <ButtonGroup color="primary" aria-label="outlined primary button group">

            <Button
              variant="contained"
              color="primary"
              onClick={() => { this.showBusquedas(); }}
            >
              Buscar
  </Button>

            <Button
              fullWidth
              variant="contained"
              disabled={this.state.loading}
              color="primary"
              onClick={this.onNuevo}
            >
              Nuevo
          </Button>


          </ButtonGroup>

        </Box>

        <div style={{ height: 20 }}></div>

        <Collapse in={this.state.showPanelBusquedas} >

          <Box display='flex' style={{ width: '100%' }}>

            <TextField
              fullWidth
              id="buscarNombre"
              label="Nombre"
              name="buscarNombre"
              autoComplete="buscarNombre"
              size='small'
              value={this.state.buscarNombre}
              variant='outlined'
              style={{ margin: 5 }}
              margin='dense'
              onChange={this.onChangeInput}
            />

            <TextField
              fullWidth
              id="buscarEmpresa"
              label="Empresa"
              name="buscarEmpresa"
              autoComplete="buscarEmpresa"
              size='small'
              value={this.state.buscarEmpresa}
              variant='outlined'
              style={{ margin: 5 }}
              margin='dense'
              onChange={this.onChangeInput}
            />

          </Box>

        </Collapse>

      </Box>);

    const Registros =
      <Container maxWidth='lg'>

        {Buscador}

        <Box>

          <div style={{ height: 20 }}></div>

          <Box style={{ backgroundColor: 'black' }} borderRadius={5}>

            <TableContainer component={Paper}>
              <Table size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell key={'acciones'}>
                      {'#'}
                    </TableCell>
                    <TableCell key={'idemail_plantilla'}>
                      <TableSortLabel
                        direction={this.state.ordenar['nombre'] ? this.state.ordenar['nombre'] : 'asc'}
                        onClick={() => this.onOrdenar('nombre')}>
                        {'Plantilla'}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell key={'empresa'}>
                      <TableSortLabel
                        direction={this.state.ordenar['empresa'] ? this.state.ordenar['empresa'] : 'asc'}
                        onClick={() => this.onOrdenar('empresa')}>
                        {'Empresa'}
                      </TableSortLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(this.state.rowsPerPage > 0
                    ? this.state.data.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                    : this.state.data
                  ).map((element, index) => (
                    <Grow in={true} timeout={index < 50 ? 100 * index : 0} key={index}>
                      <TableRow>
                        <TableCell>
                          <Box display='flex' alignItems='center'>
                            <IconButton onClick={() => this.onSeleccionarItem(element)} title="Editar registro">
                              <EditIcon />
                            </IconButton>
                            <IconButton onClick={() => {
                              let item = { ...element };
                              item.idemail_plantilla = '';
                              item.nombre = item.nombre + " (copia)";
                              this.onGuardar(item);

                            }} title="Duplicar plantilla">
                              <FileCopyIcon />
                            </IconButton>
                            <IconButton onClick={() => this.onBorrarItem(element)} title="Borrar plantilla">
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        </TableCell>

                        <TableCell>{element.nombre}</TableCell>
                        <TableCell>{element.empresa}</TableCell>
                      </TableRow>
                    </Grow>
                  ))}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 25 * emptyRows }}>
                      <TableCell colSpan={3} />
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[10, 20, 50, { label: 'All', value: -1 }]}
                      colSpan={3}
                      count={this.state.data.length}
                      rowsPerPage={this.state.rowsPerPage}
                      page={this.state.page}
                      onChangePage={(e, page) => this.handleChangePage(page)}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>


          </Box>
        </Box>
      </Container>;


    return (
      <div>

        <Header title="Email Plantillas" history={this.props.history}></Header>

        <div style={{ height: 40 }}></div>

        <Typography align="center" variant="h5">Gestión de Plantillas de Email</Typography>

        <div style={{ height: 20 }}></div>

        {this.state.editando ? <EditarEmailPlantilla item={this.state.seleccionado} onGuardar={this.onGuardar} onBack={this.onBack} /> : Registros}

        <div style={{ height: 20 }}></div>

        {this.state.error === true ? AlertloginFailed : ''}

      </div>
    );
  }

}

export default GestionEmailPlantilla;
