import React from 'react';


import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import '../css/style.css';

class PopUpConfirmacion extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      messageInfo: this.props.messageInfo || ''
    }

    this.handleClose = this.handleClose.bind(this);
    this.onOk = this.onOk.bind(this);

  }

  componentDidMount() {
  }


  handleClose() {
    this.setState({ messageInfo: '' });

    if(this.props.onCancel) {
      this.props.onCancel();
    }
  }

  onOk() {
    if (this.props.onOk) {
      this.props.onOk();
    }
    else {
      this.handleClose();
    }
  }

  render() {


    return (
      < Dialog open={this.state.messageInfo.length > 0} onClose={this.handleClose}>
        <DialogContent>
          <Box>
            <Typography>
              {this.state.messageInfo}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={this.onOk} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog >
    );
  }

}

export default PopUpConfirmacion;
