import React from 'react';


import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import '../css/style.css';

class PopUpInfo extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      messageInfo: this.props.messageInfo
    }

  }

  componentDidMount() {
  }

  render() {


    return (
      < Dialog open={true}>
        <DialogContent style={{ borderRadius: 15 }}>
          <Box p={3}>
            <Typography variant='body1' dangerouslySetInnerHTML={{ __html: this.state.messageInfo }} ></Typography>
          </Box>
          <DialogActions>
            <Button onClick={this.props.onClose ? this.props.onClose : this.setState({ messageInfo: '' })} variant="contained" color="secondary">
              Aceptar
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog >
    );
  }

}

export default PopUpInfo;
