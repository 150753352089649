import React from 'react';

import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Grow from '@material-ui/core/Grow';
import Avatar from '@material-ui/core/Avatar';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import Header from '../../component/header';
import PopUpInfo from '../../component/popUpInfo';

import { Usuarios } from '../../entity/usuarios';

import '../../css/style.css';

class ListadoCodigos extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      errorText: '',
      data: [],
      dataResumen: [],

      buscarEmpresa: '',
      buscarCentro: '',
      buscarEvento: '',
      buscarFecha: ''

    }

    this.onChangeInput = this.onChangeInput.bind(this);

    this.onRefresh = this.onRefresh.bind(this);
    this.cargarResumen = this.cargarResumen.bind(this);
  }

  componentDidMount() {
    this.cargarResumen({});
  }

  onRefresh(formulario) {
    this.cargarResumen(formulario);
  }


  cargarResumen(formulario) {
    Usuarios.getCodigosResumen(formulario)
      .then(
        codigos => {
          if (codigos.records) {
            this.setState({ dataResumen: codigos.records });
          }
        }
      );
  }

  onChangeInput(event) {
    this.setState({ [event.target.name]: event.target.value }, () => {

      this.onRefresh({
        empresa: this.state.buscarEmpresa,
        centro: this.state.buscarCentro,
        evento: this.state.buscarEvento,
        fecha: this.state.buscarFecha
      });

    });
  }

  render() {

    const AlertloginFailed = <PopUpInfo messageInfo={this.state.errorText} onClose={() => { this.setState({ error: false, errorText: '' }) }} />

    const Buscador =
      (<Box display='flex'>
        <TextField
          fullWidth
          style={{ margin: 5 }}
          margin="dense" size='small' variant='outlined'
          id="buscarEmpresa" label="Empresa" name="buscarEmpresa"
          value={this.state.buscarEmpresa}
          onChange={this.onChangeInput}
        />
        <TextField
          fullWidth
          style={{ margin: 5 }}
          margin="dense" size='small' variant='outlined'
          id="buscarCentro" label="Centro" name="buscarCentro"
          value={this.state.buscarCentro}
          onChange={this.onChangeInput}
        />
        <TextField
          fullWidth
          style={{ margin: 5 }}
          margin="dense" size='small' variant='outlined'
          id="buscarEvento" label="Evento" name="buscarEvento"
          value={this.state.buscarEvento}
          onChange={this.onChangeInput}
        />

        <TextField
          fullWidth
          id="buscarFecha"
          label="Fecha"
          name="buscarFecha"
          autoComplete="buscarFecha"
          size='small'
          value={this.state.buscarFecha}
          type='date'
          variant='outlined'
          InputLabelProps={{ shrink: true }}
          style={{ margin: 5 }}
          margin='dense'
          onChange={this.onChangeInput}
        />

      </Box>);


    const Resumen = (
      <Box display='flex' flexWrap='wrap' justifyContent='space-around'>
        {
          this.state.dataResumen.map((element) => {
            return (
              <Box style={{ margin: 5 }} className='box-blanco' display='flex' key={element.ideventos + element.fecha}>
                <Container>
                  <Box>{element.empresa}</Box>
                  <Box>{element.centro}</Box>
                  <Box>{element.evento}</Box>
                  <Box>
                    <Typography>{element.fecha}</Typography>
                  </Box>
                </Container>

                <Container style={{ width: 120 }}>
                  <Tooltip title="Entradas totales"><Box style={{textAlign: 'right'}}>{element.cantidad + ' /E'}</Box></Tooltip>
                  <Tooltip title="Entradas validadas"><Box style={{textAlign: 'right'}}>{element.validadas + ' /V'}</Box></Tooltip>
                  <Tooltip title="Entradas disponibles"><Box style={{textAlign: 'right'}}>{(Number.parseInt(element.cantidad) - Number.parseInt(element.validadas)) + ' /D'}</Box></Tooltip>
                  <Tooltip title="Entradas usadas"><Box style={{textAlign: 'right'}}>{element.usadas + ' /U'}</Box></Tooltip>
                </Container>
              </Box>
            )
          })
        }
      </Box>
    );


    return (
      <div>

        <Header title="Dashboard" history={this.props.history}></Header>

        <div style={{ height: 40 }}></div>

        <Container maxWidth='lg'>

          {Buscador}

          <div style={{ height: 10 }}></div>

          {Resumen}

          <div style={{ height: 10 }}></div>

        </Container>
      </div>
    );
  }

}

export default ListadoCodigos;
