import React from 'react';

import { Button, FormControlLabel } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grow from '@material-ui/core/Grow';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import IconButton from '@material-ui/core/IconButton';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';

import { Empresas } from '../../entity/empresas';
import { Centros } from '../../entity/centros';
import { Usuarios } from '../../entity/usuarios';

import PopUpInfo from '../../component/popUpInfo';

import '../../css/style.css';

class EditarUsuario extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      idusuarios: this.props.item.idusuarios,
      nombre: this.props.item.nombre,
      movil: this.props.item.movil,
      email: this.props.item.email,
      idperfiles: this.props.item.idperfiles,
      idempresas: this.props.item.idempresas,
      idcentros: this.props.item.idcentros,
      clave: '',
      clave2: '',
      usuario: this.props.item.usuario,
      validado: this.props.item.validado === 0 || this.props.item.validado === "0" ? false : true,

      image: this.props.item.imagen,

      empresas: [],
      centros: [],
      perfiles: []

    }

    this.onChangeInput = this.onChangeInput.bind(this);
    this.onChangeInputSelect = this.onChangeInputSelect.bind(this);
    this.onSelectFile = this.onSelectFile.bind(this);

    this.cargarSelect = this.cargarSelect.bind(this);
    this.cargarEmpresas = this.cargarEmpresas.bind(this);
    this.cargarCentros = this.cargarCentros.bind(this);
    this.cargarPerfiles = this.cargarPerfiles.bind(this);
  }

  componentDidMount() {
    this.cargarSelect();
  }

  onChangeInput(event) {
    // si es el switch, lo trato distinto
    if (event.target.id === 'validado') {
      this.setState({ [event.target.id]: event.target.checked });
    }
    else {
      this.setState({ [event.target.id]: event.target.value });
    }

  }

  onChangeInputSelect(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  onSelectFile(event) {
    console.log(event.target.files[0]);
    this.setState({ imagen: event.target.files[0] });
  }

  onGuardar = () => {
    this.props.onGuardar({
      idusuarios: this.state.idusuarios,
      nombre: this.state.nombre,
      movil: this.state.movil,
      email: this.state.email,
      idperfiles: this.state.idperfiles,
      idempresas: this.state.idempresas,
      idcentros: this.state.idcentros,
      clave: this.state.clave,
      clave2: this.state.clave2,
      usuario: this.state.usuario,
      validado: this.state.validado === true ? 1 : 0,
      imagen: this.state.imagen
    });
  }

  onBack = () => {
    this.props.onBack();
  }

  async cargarSelect() {

    this.setState({ loading: true });

    await this.cargarEmpresas();
    await this.cargarCentros();
    await this.cargarPerfiles();

    this.setState({ loading: false });
  }


  async cargarEmpresas() {
    Empresas.getEmpresas()
      .then(
        data => {
          if (data.records) {
            this.setState({ empresas: data.records });
          }
        }
      );
  }

  async cargarCentros() {
    Centros.getCentros()
      .then(
        data => {
          if (data.records) {
            this.setState({ centros: data.records });
          }
        }
      );
  }

  async cargarPerfiles() {
    Usuarios.getPerfiles()
      .then(
        data => {
          if (data.records) {
            this.setState({ perfiles: data.records });
          }
        }
      );
  }

  render() {

    const AlertloginFailed = <PopUpInfo messageInfo={this.state.errorText} onClose={() => { this.setState({ error: false, errorText: '' }) }} />

    return (
      <Container maxWidth='xs'>

        <Grow in={true} timeout={500}>
          <Box className="box-blanco">

            <Typography align="center" variant="h5">Usuarios</Typography>

            <FormControlLabel
              color='red'
              control={
                <Switch checked={this.state.validado} name="validado" id='validado' color='primary' onChange={this.onChangeInput} />
              }
              label={<Typography color='primary'>{this.state.validado === true ? 'Validado' : 'Sin validar'}</Typography>}
            />


            <div style={{ height: 20 }}></div>

            <Box display="flex" alignItems="center">

              <input accept="image/*" id="empresas-upload-imagen" type="file" style={{ display: "none" }} onChange={this.onSelectFile} />
              <label htmlFor="empresas-upload-imagen">
                <IconButton color="primary" component="span" style={{ border: '1px solid black', marginRight: 20 }}>
                  <PhotoCameraIcon />
                </IconButton>
              </label>

              <TextField
                margin="dense"
                required
                fullWidth
                autoFocus
                id="nombre"
                label="Nombre"
                name="nombre"
                autoComplete="nombre"
                size='small'
                value={this.state.nombre}

                onChange={this.onChangeInput}
              />

            </Box>
            <TextField
              margin="dense"
              required
              fullWidth
              id="movil"
              label="Movil"
              name="movil"
              autoComplete="movil"
              size='small'
              value={this.state.movil}

              onChange={this.onChangeInput}
            />

            <TextField
              margin="dense"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              size='small'
              value={this.state.email}

              onChange={this.onChangeInput}
            />

            <FormControl variant="outlined" fullWidth size="small" margin='normal' required>
              <InputLabel>Perfil</InputLabel>
              <Select
                labelId="idperfiles"
                id="idperfiles"
                name="idperfiles"
                value={this.state.idperfiles}
                label="Perfil"
                onChange={this.onChangeInputSelect}
              >
                <MenuItem value="">
                  <em>Seleccionar</em>
                </MenuItem>
                {
                  this.state.perfiles.map((element, index) => {
                    return (<MenuItem value={element.idperfiles} key={element.idperfiles}>{element.idperfiles}</MenuItem>);
                  })
                }
              </Select>
            </FormControl>

            <FormControl variant="outlined" fullWidth size="small" margin='normal' required>
              <InputLabel>Empresa</InputLabel>
              <Select
                labelId="idempresas"
                id="idempresas"
                name="idempresas"
                value={this.state.idempresas}
                label="Empresa"
                onChange={this.onChangeInputSelect}
              >
                <MenuItem value="">
                  <em>Seleccionar</em>
                </MenuItem>
                {
                  this.state.empresas.map((element, index) => {
                    return (<MenuItem value={element.idempresas} key={element.idempresas}>{element.nombre}</MenuItem>);
                  })
                }
              </Select>
            </FormControl>

            <FormControl variant="outlined" fullWidth size="small" margin='normal' required>
              <InputLabel>Centro</InputLabel>
              <Select
                labelId="idcentros"
                id="idcentros"
                name="idcentros"
                value={this.state.idcentros}
                label="Centro"
                onChange={this.onChangeInputSelect}
              >
                <MenuItem value="">
                  <em>Seleccionar</em>
                </MenuItem>
                {
                  this.state.centros.map((element, index) => {
                    return (<MenuItem value={element.idcentros} key={element.idcentros}>{element.nombre}</MenuItem>);
                  })
                }
              </Select>
            </FormControl>

            <TextField
              margin="normal"
              variant="outlined"
              required
              fullWidth
              id="usuario"
              label="Usuario"
              disabled={this.state.idusuarios.length > 0 ? true : false}
              name="usuario"
              autoComplete="usuario"
              size='small'
              value={this.state.usuario}

              onChange={this.onChangeInput}
            />

            <Box display="flex">
              <TextField
                margin="normal"
                variant="outlined"
                name="clave"
                label="Clave"
                type="password"
                id="clave"
                style={{ margin: 5 }}

                onChange={this.onChangeInput}
              />

              <TextField
                margin="normal"
                variant="outlined"
                name="clave2"
                label="Repetir Clave"
                type="password"
                id="clave2"
                style={{ margin: 5 }}

                onChange={this.onChangeInput}
              />
            </Box>

            <div style={{ height: 20 }}></div>

            <Box display="flex" justifyContent="center">

              <Button
                fullWidth
                variant="contained"
                disabled={this.state.loading}
                color="primary"
                style={{ margin: 5 }}
                onClick={this.onGuardar}
              >
                Guardar
              </Button>

              <Button
                fullWidth
                variant="contained"
                color="primary"
                style={{ margin: 5 }}
                onClick={this.onBack}
              >
                Volver
              </Button>

            </Box>

          </Box>

        </Grow>

      </Container>
    );
  }

}

export default EditarUsuario;
