import React from 'react';
import MPheader from '../MPheader/MPheader';
import MPfooter from '../MPfooter/MPfooter';
import '../../css/home.css';
import InfoGrid from '../../component/InfoGrid/InfoGrid';

class Empresa extends React.Component {

  render() {
    return (
      <div className="wrapper">
        <MPheader />
        <InfoGrid />
        <MPfooter />
      </div>
    );
  }
}

export default Empresa;