import React from 'react';

import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';


import { Eventos } from '../../entity/eventos';
import { Usuarios } from '../../entity/usuarios';

import '../../css/style.css';

import { List, ListItem, ListItemText, IconButton } from '@material-ui/core';

class OrdenCompraAsignarCategoriaEvento extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      ideventosCategorias: '',
      message: '',

      eventosCategorias: [],
      categoriasAsignadas: []

    }

    this.CargarCategoriasAsociadas = this.CargarCategoriasAsociadas.bind(this);

    this.onChangeInputSelect = this.onChangeInputSelect.bind(this);

    this.saveCategoria = this.saveCategoria.bind(this);
    this.borrarCategoria = this.borrarCategoria.bind(this);
  }

  componentDidMount() {
    // cargamos las categorías de eventos
    Eventos.getEventosCategorias()
      .then(
        data => {
          if (data.records) {
            this.setState({ eventosCategorias: data.records });
          }
        }
      );

    this.CargarCategoriasAsociadas();
  }

  CargarCategoriasAsociadas() {
    Usuarios.getOrdenCompraEventosCategorias({
      id: this.props.item.idordencompra
    }).then(data => {
      if (data.records) {
        this.setState({ categoriasAsignadas: data.records, message: '' });
      }
    });

  }

  onChangeInputSelect(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  saveCategoria() {

    if(this.state.ideventosCategorias.length == 0) {
      this.setState({message: "Seleccione primero una categoría"});
      return;
    }

    // miramos si essa categoría ya existe
    if(this.state.categoriasAsignadas.filter(x => x.ideventosCategorias == this.state.ideventosCategorias).length > 0) {
      this.setState({message: "Esta categoría ya está asignada"});
      return;
    }

    Usuarios.saveOrdenCompraEventosCategorias({
      idordencompra: this.props.item.idordencompra,
      ideventosCategorias: this.state.ideventosCategorias
    })
      .then(data => {
        this.CargarCategoriasAsociadas();
      });
  }

  borrarCategoria(id) {
    Usuarios.borrarOrdenCompraEventosCategorias({
      id: id
    })
      .then(data => {
        this.CargarCategoriasAsociadas();
      });
  }


  render() {

    return (

      < Dialog open={true} fullWidth maxWidth="sm" style={{ backgroundColor: 'grey' }}>

        <DialogTitle disableTypography>

          <Typography align='center' variant='h5'>Indicar Categorias de cambio</Typography>

        </DialogTitle>

        <DialogContent>

          <Container maxWidth="sm">

            {
              this.state.message.length > 0
              ? <Typography align='center' variant='h5' color='primary'>{this.state.message}</Typography>
              : <div></div>
            }

            <div style={{ height: 20 }}></div>

            <Typography>{this.props.item.empresa}</Typography>
            <Typography>{this.props.item.evento}</Typography>
            <Typography>{this.props.item.centro}</Typography>
            <Typography>{this.props.item.fecha}</Typography>

            <div style={{ width: 20 }}></div>

            <Box display='flex'>

              <FormControl variant="outlined" fullWidth size="small" margin='normal'>
                <InputLabel>Seleccione Categoria</InputLabel>
                <Select
                  labelId="ideventosCategorias"
                  id="ideventosCategorias"
                  name="ideventosCategorias"
                  value={this.state.ideventosCategorias}
                  label="Evento"
                  onChange={this.onChangeInputSelect}
                >
                  <MenuItem value="">
                    <em>Seleccionar</em>
                  </MenuItem>
                  {
                    this.state.eventosCategorias.map((element, index) => {
                      return (<MenuItem value={element.ideventosCategorias} key={index}>{element.nombre}</MenuItem>);
                    })
                  }
                </Select>
              </FormControl>

              <div style={{ width: 20 }}></div>

              <Button
                variant="outlined"
                color="primary"
                size='small'
                onClick={this.saveCategoria}
              >Añadir</Button>

            </Box>

            <List>
              {
                this.state.categoriasAsignadas.map((element, index) => {
                  return (
                    <ListItem button key={index} onClick={() => this.borrarCategoria(element.idordencompraEventosCategorias)}>
                      <ListItemText primary={
                        <Box display='flex' alignItems='center'>
                          <DeleteIcon />

                          <Typography>{element.categoria}</Typography>
                        </Box>
                      } />
                    </ListItem>
                  );
                })
              }
            </List>

          </Container>

        </DialogContent>
        <DialogActions>
          <Button variant='outlined' color='secondary' onClick={this.props.onClose}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog >
    );
  }

}

export default OrdenCompraAsignarCategoriaEvento;
