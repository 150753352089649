import { Constantes } from '../util/constantes';


export const EmailPlantilla = {
    guardar,
    getPlantillas,
    getPlantillasTags,
    borrar,

    enviarEmailUsuariosCodigos,
};


async function guardar(formulario) {

    const requestOptions = {
        method: 'post',
        headers: Constantes.getAuthHeadersUpload(),
        body: formulario,
    };

    const response = await fetch(`${Constantes.API_URL}email_plantilla/save.php`, requestOptions)
        .then((data) => {

            if (data.status !== 200) {
                return data.json()
            }
            else {
                return true;
            }
        })
        .catch((error) => {
            console.log(error);
            return { message: error };
        });

    return response;

}

async function getPlantillas(formulario) {

    const requestOptions = {
        method: 'post',
        headers: Constantes.getAuthHeaders(),
        body: JSON.stringify(formulario),
    };

    const response = await fetch(`${Constantes.API_URL}email_plantilla/read.php`, requestOptions)
        .then((data) => {
            return data.json()
        })
        .catch((error) => {
            console.log(error);
            return { message: error };
        });

    return response;
}

async function getPlantillasTags() {

    const requestOptions = {
        method: 'get',
        headers: Constantes.getAuthHeaders(),
    };

    const response = await fetch(`${Constantes.API_URL}email_plantilla/readTags.php`, requestOptions)
        .then((data) => {
            return data.json()
        })
        .catch((error) => {
            console.log(error);
            return { message: error };
        });

    return response;

}


async function borrar(formulario) {

    const requestOptions = {
        method: 'post',
        headers: Constantes.getAuthHeadersUpload(),
        body: formulario,
    };

    const response = await fetch(`${Constantes.API_URL}email_plantilla/delete.php`, requestOptions)
        .then((data) => {

            if (data.status !== 200) {
                return data.json()
            }
            else {
                return true;
            }
        })
        .catch((error) => {
            console.log(error);
            return { message: error };
        });

    return response;

}


async function enviarEmailUsuariosCodigos(formulario) {

    const requestOptions = {
        method: 'post',
        headers: Constantes.getAuthHeadersUpload(),
        body: formulario,
    };

    const response = await fetch(`${Constantes.API_URL}email_plantilla/enviar_correo_usuarios_entradas.php`, requestOptions)
        .then((data) => {
            return data.json()
        })
        .catch((error) => {
            console.log(error);
            return { message: error };
        });

    return response;

}
