import React from 'react';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';

import { ButtonGroup, FormControl, Select, MenuItem, Collapse } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import Typography from '@material-ui/core/Typography';

import Header from '../../component/header';
import EditarBundle from './editarBundle';
import PopUpInfo from '../../component/popUpInfo';

import { Bundle } from '../../entity/bundle';


import '../../css/style.css';

class GestionBundle extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      rowsPerPage: 10,
      page: 0,
      ordenar: [],

      idbundle: '',
      nombre: '',

      data: [],
      seleccionado: {},
      showPanelBusquedas: false,

      buscarNombre: '',
      buscarEmpresa: '',
      buscarCentro: '',
      buscarEvento: '',

      loading: false,
      editando: false,
      error: false,
      errorText: ''
    }

    // gestionar la tabla
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.onOrdenar = this.onOrdenar.bind(this);

    this.onChangeInput = this.onChangeInput.bind(this);
    this.onGuardar = this.onGuardar.bind(this);
    this.onNuevo = this.onNuevo.bind(this);
    this.onCargar = this.onCargar.bind(this);
    this.onBack = this.onBack.bind(this);
    this.onSeleccionarItem = this.onSeleccionarItem.bind(this);
    this.borrarLinea = this.borrarLinea.bind(this);

    this.showBusquedas = this.showBusquedas.bind(this);

    let searchDelay = {};

  }

  componentDidMount() {
    this.onCargar({});
  }

  handleChangePage(newPage) {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage(event) {
    this.setState({ rowsPerPage: parseInt(event.target.value), page: 0 });
  };

  onOrdenar(por) {

    if (por) {

      let tmpOrdenar = this.state.ordenar;
      if (!tmpOrdenar[por]) {
        tmpOrdenar[por] = 'asc';
      }
      else {
        tmpOrdenar[por] = tmpOrdenar[por] === 'asc' ? 'desc' : 'asc';
      }

      let datosOrdenados = this.state.data;

      // si quieremos ordenar la fecha de vento, entonces se hace un tratamiento especial, ya que es una fecha
      tmpOrdenar[por] === 'asc' ?
        datosOrdenados.sort((a, b) => (a[por] > b[por]) ? 1 : ((b[por] > a[por]) ? -1 : 0))
        : datosOrdenados.sort((a, b) => (a[por] < b[por]) ? 1 : ((b[por] < a[por]) ? -1 : 0));

      this.setState({ data: datosOrdenados, ordenar: tmpOrdenar });
    }
  }

  onChangeInput(event) {

    this.setState({ [event.target.name]: event.target.value });

    clearTimeout(this.searchDelay);

    this.searchDelay = setTimeout(() => {
      this.onCargar({
        nombre: this.state.buscarNombre,
        empresa: this.state.buscarEmpresa,
        centro: this.state.buscarCentro,
        evento: this.state.buscarEvento
      })
    }, 1500);
  }


  onCargar(formulario) {

    this.setState({ loading: true });

    Bundle.getBundle(formulario)
      .then(
        data => {
          console.log(data);
          if (data.records) {
            this.setState({ data: data.records, loading: false, editando: false });
          }
        }
      );
  }

  onGuardar(item) {

    this.setState({ loading: true });

    let formulario = new FormData()
    if (item.idbundle) formulario.append('idbundle', item.idbundle);
    if (item.nombre) formulario.append('nombre', item.nombre);
    if (item.idempresas) formulario.append('idempresas', item.idempresas);

    Bundle.guardar(formulario)
      .then(
        valid => {
          if (valid === true) {
            this.onCargar({
              nombre: this.state.buscarNombre,
              empresa: this.state.buscarEmpresa,
              centro: this.state.buscarCentro,
              evento: this.state.buscarEvento
            });
          }
          else {
            this.setState({ error: true, loading: false, errorText: valid.message });
          }
        }
      );

  }


  onSeleccionarItem(element) {
    this.setState({ seleccionado: element, editando: true, error: false });
  }

  onNuevo() {
    let item = {
      idbundle: '',
      nombre: '',
      idempresas: ''
    }

    this.setState({ seleccionado: item, editando: true });
  }

  onBack() {
    this.onCargar({
      nombre: this.state.buscarNombre,
      empresa: this.state.buscarEmpresa,
      centro: this.state.buscarCentro,
      evento: this.state.buscarEvento
    });
  }

  borrarLinea(element) {
    let formulario = new FormData()
    formulario.append('id', element.idbundle_lineas);

    Bundle.borrarLinea(formulario)
      .then(
        valid => {
          if (valid === true) {
            this.onCargar({
              nombre: this.state.buscarNombre,
              empresa: this.state.buscarEmpresa,
              centro: this.state.buscarCentro,
              evento: this.state.buscarEvento
            });
          }
          else {
            this.setState({ error: true, loading: false, errorText: valid.message });
          }
        }
      );
  }

  showBusquedas() {
    this.setState({ showPanelBusquedas: !this.state.showPanelBusquedas });
  }

  render() {

    const emptyRows = 1;

    const AlertloginFailed = <PopUpInfo messageInfo={this.state.errorText} onClose={() => { this.setState({ error: false, errorText: '' }) }} />

    const Buscador =
      (<Box display='flex' flexWrap='wrap'>

        <Box display='flex' style={{ width: '100%' }}>

          <ButtonGroup color="primary" aria-label="outlined primary button group">

            <Button
              variant="contained"
              color="primary"
              onClick={() => { this.showBusquedas(); }}
            >
              Buscar
  </Button>

            <Button
              fullWidth
              variant="contained"
              disabled={this.state.loading}
              color="primary"
              onClick={this.onNuevo}
            >
              Nuevo
          </Button>


          </ButtonGroup>

        </Box>

        <div style={{ height: 20 }}></div>

        <Collapse in={this.state.showPanelBusquedas} >

          <Box display='flex' style={{ width: '100%' }}>

            <TextField
              fullWidth
              id="buscarNombre"
              label="Nombre"
              name="buscarNombre"
              autoComplete="buscarNombre"
              size='small'
              value={this.state.buscarNombre}
              variant='outlined'
              style={{ margin: 5 }}
              margin='dense'
              onChange={this.onChangeInput}
            />

            <TextField
              fullWidth
              id="buscarEmpresa"
              label="Empresa"
              name="buscarEmpresa"
              autoComplete="buscarEmpresa"
              size='small'
              value={this.state.buscarEmpresa}
              variant='outlined'
              style={{ margin: 5 }}
              margin='dense'
              onChange={this.onChangeInput}
            />

            <TextField
              fullWidth
              id="buscarCentro"
              label="Centro"
              name="buscarCentro"
              autoComplete="buscarCentro"
              size='small'
              value={this.state.buscarCentro}
              variant='outlined'
              style={{ margin: 5 }}
              margin='dense'
              onChange={this.onChangeInput}
            />

            <TextField
              fullWidth
              id="buscarEvento"
              label="Evento"
              name="buscarEvento"
              autoComplete="buscarEvento"
              size='small'
              value={this.state.buscarEvento}
              variant='outlined'
              style={{ margin: 5 }}
              margin='dense'
              onChange={this.onChangeInput}
            />

          </Box>


        </Collapse>

      </Box>);

    const Registros =
      <Container maxWidth='lg'>

        {Buscador}

        <Box>

          <div style={{ height: 20 }}></div>

          <Box style={{ backgroundColor: 'black' }} borderRadius={5}>

            <TableContainer component={Paper}>
              <Table size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell key={'acciones'}>
                      {'#'}
                    </TableCell>
                    <TableCell key={'idbundle'}>
                      <TableSortLabel
                        direction={this.state.ordenar['nombre'] ? this.state.ordenar['nombre'] : 'asc'}
                        onClick={() => this.onOrdenar('nombre')}>
                        {'Bundle'}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell key={'empresa'}>
                      <TableSortLabel
                        direction={this.state.ordenar['empresa'] ? this.state.ordenar['empresa'] : 'asc'}
                        onClick={() => this.onOrdenar('empresa')}>
                        {'Empresa'}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell key={'centro'}>
                      <TableSortLabel
                        direction={this.state.ordenar['centro'] ? this.state.ordenar['centro'] : 'asc'}
                        onClick={() => this.onOrdenar('centro')}>
                        {'Centro'}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell key={'evento'}>
                      <TableSortLabel
                        direction={this.state.ordenar['evento'] ? this.state.ordenar['evento'] : 'asc'}
                        onClick={() => this.onOrdenar('evento')}>
                        {'Evento'}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell key={'entradas_por_usuario'}>
                      <TableSortLabel
                        direction={this.state.ordenar['entradas_por_usuario'] ? this.state.ordenar['entradas_por_usuario'] : 'asc'}
                        onClick={() => this.onOrdenar('entradas_por_usuario')}>
                        {'Entradas/Usuario'}
                      </TableSortLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(this.state.rowsPerPage > 0
                    ? this.state.data.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                    : this.state.data
                  ).map((element, index) => (
                    <Grow in={true} timeout={index < 50 ? 100 * index : 0} key={index}>
                      <TableRow>
                        <TableCell>
                          <Box display='flex' alignItems='center'>
                            <IconButton onClick={() => this.onSeleccionarItem(element)} title="Editar registro">
                              <EditIcon />
                            </IconButton>
                            <IconButton onClick={() => this.borrarLinea(element)} title="Borrar registro">
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        </TableCell>

                        <TableCell>{element.nombre}</TableCell>
                        <TableCell>{element.empresa}</TableCell>
                        <TableCell>{element.centro}</TableCell>
                        <TableCell>{element.evento}</TableCell>
                        <TableCell>{element.entradas}</TableCell>
                      </TableRow>
                    </Grow>
                  ))}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 25 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[10, 20, 50, { label: 'All', value: -1 }]}
                      colSpan={6}
                      count={this.state.data.length}
                      rowsPerPage={this.state.rowsPerPage}
                      page={this.state.page}
                      onChangePage={(e, page) => this.handleChangePage(page)}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>


          </Box>
        </Box>
      </Container>;


    return (
      <div>

        <Header title="Bundle" history={this.props.history}></Header>

        <div style={{ height: 40 }}></div>

        <Typography align="center" variant="h5">Gestión de Bundle</Typography>

        <div style={{ height: 20 }}></div>

        {this.state.editando ? <EditarBundle item={this.state.seleccionado} onGuardar={this.onGuardar} onBack={this.onBack} /> : Registros}

        <div style={{ height: 20 }}></div>

        {this.state.error === true ? AlertloginFailed : ''}

      </div>
    );
  }

}

export default GestionBundle;
