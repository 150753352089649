import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import InstagramIcon from '@material-ui/icons/Instagram';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import SvgIcon from '@material-ui/core/SvgIcon';
import { Link } from 'react-router-dom';
import "./MPheader.css";

class MPheader extends React.Component {

  constructor(props) {  
    super(props);

    this.state = {
      menuOpen: false
    }

    this.menuToggle = this.menuToggle.bind(this);
  }

  menuToggle() {
    this.setState({menuOpen: !this.state.menuOpen});
  }

  render() {
    
    return (
      <header className="mp-header">
        <div className="mp-header__button">
          <IconButton 
            edge="start" 
            color="inherit" 
            aria-label="menu" 
            onClick={this.menuToggle}
          >
            <MenuIcon />
          </IconButton>
        </div>
        <div className="mp-header__logo">
          <img src="/images/musical_pass_blanco.png" alt="" title="Musical Pass logo" />
        </div>
        <div className={`mp-header__navigation ${this.state.menuOpen ? "opened": ""}`}>
          <div className="mp-header__navigation-links">
            <List>
              <ListItem button component="a" href="/">
                <ListItemText primary={"musical pass"} />
              </ListItem>
              <ListItem button component="a" href="empresa">
                <ListItemText primary={"empresas"} />
              </ListItem>
            </List>
          </div>
          <div className="mp-header__navigation-rrss">
            <List>
              <ListItem button component="a" href="#">
                <ListItemIcon><FacebookIcon style={{color: 'white'}} /></ListItemIcon>
              </ListItem>
              <ListItem button component="a" href="#">
                <ListItemIcon><TwitterIcon style={{color: 'white'}} /></ListItemIcon>
              </ListItem>
              <ListItem button component="a" href="#">
                <ListItemIcon><YouTubeIcon style={{color: 'white'}} /></ListItemIcon>
              </ListItem>
              <ListItem button component="a" href="#">
                <ListItemIcon><InstagramIcon style={{color: 'white'}} /></ListItemIcon>
              </ListItem>
              <ListItem button component="a" href="#">
                <ListItemIcon><MailOutlineIcon style={{color: 'white'}} /></ListItemIcon>
              </ListItem>
            </List>
          </div>
        </div>
        <Box className='mp-header__tools' display='flex' justifyContent={'space-around'} alignItems={'center'}>
          <div className="mp-header__tools-login">
            <div className="mp-field">
              <Link to="/main" style={{color: 'white'}}>my pass</Link>
              <div className="mp-field-icon">
                <AccountCircleOutlinedIcon />
              </div>
            </div>
          </div>
          <div className="mp-header__tools-search">
            <div className="mp-field">
              <span>Search</span>
              <div className="mp-field-icon">
                <SvgIcon>
                  <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
                </SvgIcon>
              </div>
            </div>
          </div>
        </Box>
      </header>
    );
  }
}

export default MPheader;