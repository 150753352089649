import React from 'react';
import Advertisement from '../../component/Advertisement/Advertisement';
import ShowsGrid from '../../component/ShowsGrid/ShowsGrid';
import Strip from '../../component/Strip/Strip';
import InfoCarousel from '../../component/InfoCarousel/InfoCarousel';
import '../../css/home.css';

class MPmain extends React.Component {

  render() {
    return (
      <main className="mp-main">
        <Advertisement />
        <ShowsGrid />
        <Strip />
        <InfoCarousel />
        {/* <InfoGrid /> */}
      </main>
    );
  }
}

export default MPmain;