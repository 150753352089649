import { Constantes } from '../util/constantes';


export const Bundle = {
    guardar,
    getBundle,
    borrar,

    getBundleLineas,
    guardarLinea,
    borrarLinea,

    asignaBundleUsuarios
};


async function guardar(formulario) {

    const requestOptions = {
        method: 'post',
        headers: Constantes.getAuthHeadersUpload(),
        body: formulario,
    };

    const response = await fetch(`${Constantes.API_URL}bundle/save.php`, requestOptions)
        .then((data) => {

            if (data.status !== 200) {
                return data.json()
            }
            else {
                return true;
            }
        })
        .catch((error) => {
            console.log(error);
            return { message: error };
        });

    return response;

}

async function getBundle(formulario) {

    const requestOptions = {
        method: 'post',
        headers: Constantes.getAuthHeaders(),
        body: JSON.stringify(formulario),
    };

    const response = await fetch(`${Constantes.API_URL}bundle/read.php`, requestOptions)
        .then((data) => {
            return data.json()
        })
        .catch((error) => {
            console.log(error);
            return { message: error };
        });

    return response;

}

async function borrar(formulario) {

    const requestOptions = {
        method: 'post',
        headers: Constantes.getAuthHeadersUpload(),
        body: formulario,
    };

    const response = await fetch(`${Constantes.API_URL}bundle/delete.php`, requestOptions)
        .then((data) => {

            if (data.status !== 200) {
                return data.json()
            }
            else {
                return true;
            }
        })
        .catch((error) => {
            console.log(error);
            return { message: error };
        });

    return response;

}


async function getBundleLineas(id) {

    const requestOptions = {
        method: 'get',
        headers: Constantes.getAuthHeaders(),
    };

    const response = await fetch(`${Constantes.API_URL}bundle/readLineas.php?id=${id}`, requestOptions)
        .then((data) => {
            return data.json()
        })
        .catch((error) => {
            console.log(error);
            return { message: error };
        });

    return response;

}

async function guardarLinea(formulario) {

    const requestOptions = {
        method: 'post',
        headers: Constantes.getAuthHeadersUpload(),
        body: formulario,
    };

    const response = await fetch(`${Constantes.API_URL}bundle/saveLinea.php`, requestOptions)
        .then((data) => {

            if (data.status !== 200) {
                return data.json()
            }
            else {
                return true;
            }
        })
        .catch((error) => {
            console.log(error);
            return { message: error };
        });

    return response;

}

async function borrarLinea(formulario) {

    const requestOptions = {
        method: 'post',
        headers: Constantes.getAuthHeadersUpload(),
        body: formulario,
    };

    const response = await fetch(`${Constantes.API_URL}bundle/deleteLinea.php`, requestOptions)
        .then((data) => {

            if (data.status !== 200) {
                return data.json()
            }
            else {
                return true;
            }
        })
        .catch((error) => {
            console.log(error);
            return { message: error };
        });

    return response;

}


async function asignaBundleUsuarios(formulario) {

    const requestOptions = {
        method: 'post',
        headers: Constantes.getAuthHeadersUpload(),
        body: formulario,
    };

    const response = await fetch(`${Constantes.API_URL}bundle/asignarBundleUsuarios.php`, requestOptions)
        .then((data) => {
            return data.json()
        })
        .catch((error) => {
            console.log(error);
            return { message: error };
        });

    return response;

}
