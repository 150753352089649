import React from 'react';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import IconButton from '@material-ui/core/IconButton';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import EditIcon from '@material-ui/icons/Edit';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import EmailIcon from '@material-ui/icons/Email';

import moment from 'moment';

import '../../css/style.css';
import { Container, TextField } from '@material-ui/core';
import { Usuarios } from '../../entity/usuarios';

import PopUpConfirmacion from '../../component/popUpConfirmacion';
import PopUpInfo from '../../component/popUpInfo';

class ListadoParaEmpresas extends React.Component {

  constructor(props) {
    super(props);

    this.hiddenFileInput = React.createRef();


    this.state = {
      rowsPerPage: 10,
      page: 0,

      buscarCodigo: '',
      buscarGenerico: '',
      buscarEmpresa: '',
      buscarCentro: '',
      buscarEvento: '',
      buscarValidado: 'SI',
      buscarUsado: 'TODOS',
      buscarFecha: '',
      buscarHora: '',
      buscarNombre: '',
      buscarEmail: '',
      buscarLibre: 'TODOS',
      showPanelBusquedas: false,

      error: false,
      errorText: '',

      showConfirmation: false,
      elementSeleccionado: {},

      dataChanged: []

    }

    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);

    this.onChangeInput = this.onChangeInput.bind(this);
    this.onExport = this.onExport.bind(this);
    this.onImportar = this.onImportar.bind(this);
    this.onCodigoUsado = this.onCodigoUsado.bind(this);

    this.onHideConfirmation = this.onHideConfirmation.bind(this);

    this.setEditingRow = this.setEditingRow.bind(this);
    this.editingRow = this.editingRow.bind(this);
    this.isEditingRow = this.isEditingRow.bind(this);

    this.onGuardarCodigos = this.onGuardarCodigos.bind(this);
    this.liberarCodigo = this.liberarCodigo.bind(this);

    this.recargarDatos = this.recargarDatos.bind(this);

    this.showBusquedas = this.showBusquedas.bind(this);

    let searchDelay = {};

  }

  componentDidMount() {
  }

  handleChangePage(newPage) {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage(event) {
    this.setState({ rowsPerPage: parseInt(event.target.value), page: 0 });
  };

  recargarDatos() {
    this.props.onRefresh({
      codigo: this.state.buscarCodigo,
      generico: this.state.buscarGenerico,
      empresa: this.state.buscarEmpresa,
      centro: this.state.buscarCentro,
      evento: this.state.buscarEvento,
      validado: this.state.buscarValidado,
      usado: this.state.buscarUsado,
      fecha: this.state.buscarFecha,
      hora: this.state.buscarHora,
      nombre: this.state.buscarNombre,
      email: this.state.buscarEmail,
      libre: this.state.buscarLibre,
    });
  }

  onChangeInput(event) {

    this.setState({ [event.target.name]: event.target.value });

    clearTimeout(this.searchDelay);

    this.searchDelay = setTimeout(() => {
      this.recargarDatos();
    }, 1500);
  }

  onExport(event) {
    this.props.toExcel({
      codigo: this.state.buscarCodigo,
      generico: this.state.buscarGenerico,
      empresa: this.state.buscarEmpresa,
      centro: this.state.buscarCentro,
      evento: this.state.buscarEvento,
      validado: this.state.buscarValidado,
      usado: this.state.buscarUsado,
      fecha: this.state.buscarFecha,
      hora: this.state.buscarHora,
      nombre: this.state.buscarNombre,
      email: this.state.buscarEmail,
      libre: this.state.buscarLibre
    });
  }

  onImportar(file) {
    let data = new FormData()
    data.append('file', file, 'importar.csv')

    Usuarios.ImportFromCSV(data)
      .then(
        excel => {
          if (excel === false) {
            this.setState({ error: true, errorText: 'Problemas al importar' });
          }
          else {
            this.setState({ error: true, errorText: 'Importación completada con éxito' });

            this.recargarDatos();

          }
        }
      );

  }

  onCodigoUsado(element) {

    // si aún no está validado, no se puede continuar
    if (element.estavalidado === 'NO') {
      this.setState({ error: true, errorText: 'Esta entrada aún no está canjeada', showConfirmation: false });
      return;
    }


    // si ya está usado, no continuo
    if (element.estausado !== 'NO') {
      this.setState({ error: true, errorText: 'Esta entrada ya está Emitida', showConfirmation: false });
      return;
    }

    let formulario = {
      idcodigos: element.idcodigos,
      fechauso: moment().format('YYYY-MM-DD HH:mm:ss'),
    };


    this.setState({ error: true, errorText: 'Solicitud tramitada', showConfirmation: false });

    Usuarios.saveCodigo(formulario)
      .then(() => {
        this.recargarDatos();
      });

  }

  onHideConfirmation() {
    this.setState({ error: false, showConfirmation: false });
  }


  setEditingRow(element) {
    // guardamos en una variable temporal el array de cambios
    let tmpChanged = this.state.dataChanged;

    let indice = tmpChanged.findIndex(x => x.codigo === element.codigo);

    // si no se encuentra, lo añado
    if (indice < 0) {
      tmpChanged.push(element);
    }

    // actualizamos la lista temporal de cambios
    this.setState({ dataChanged: tmpChanged });
  }

  editingRow(field, event, element) {

    // guardamos el elmento en una variable temporal
    let item = element;

    // obtnego el código de la entrada
    let codigo = item.codigo;

    // asigno el nuevo valor
    item[field] = event.target.value;

    // guardamos en una variable temporal el array de cambios
    let tmpChanged = this.state.dataChanged;

    let indice = tmpChanged.findIndex(x => x.codigo === codigo);
    // si no se encuentra, lo añado
    if (indice < 0) {
      tmpChanged.push(item);
    }
    else {
      tmpChanged[indice] = item;
    }

    // actualizamos la lista temporal de cambios
    this.setState({ dataChanged: tmpChanged });
  }

  isEditingRow(codigo) {
    // guardamos en una variable temporal el array de cambios
    let tmpChanged = this.state.dataChanged;

    let indice = tmpChanged.findIndex(x => x.codigo === codigo);

    // si no se encuentra, es que no está en edición
    if (indice < 0) {
      return false;
    }
    else {
      return true;
    }

  }

  onGuardarCodigos() {

    if (this.state.dataChanged.length > 0) {
      this.state.dataChanged.forEach((element, index) => {

        let formulario = {
          idcodigos: element.idcodigos,
          nombre: element.nombre,
          email: element.email
        };

        Usuarios.saveCodigo(formulario);
      });

      this.setState({ dataChanged: [] });
    }
  }

  liberarCodigo(element) {
    let formulario = {
      idcodigos: element.idcodigos,
      fechaasignacion: 'null',
      fechaevento: 'null'
    };

    Usuarios.saveCodigo(formulario)
      .then(() => {
        this.recargarDatos();

      });
  }


  showBusquedas() {
    this.setState({ showPanelBusquedas: !this.state.showPanelBusquedas });
  }

  render() {

    const emptyRows = 1;

    const AlertloginFailed = <PopUpInfo messageInfo={this.state.errorText} onClose={() => { this.setState({ error: false, errorText: '' }) }} />

    const Buscador =
      (<Box display='flex' flexWrap='wrap'>

        <ButtonGroup color="primary" aria-label="outlined primary button group">

          <Button
            variant="contained"
            color="primary"
            onClick={() => { this.showBusquedas(); }}
          >
            Buscar
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={this.onExport}
          >
            A Excel
          </Button>

          <input ref={this.hiddenFileInput} id="centros-upload-imagen" type="file" accept='.csv' style={{ display: "none" }}
            onChange={e => {
              this.onImportar(e.target.files[0], this.onImportar);
            }} />
          <Button color="primary" variant="contained" onClick={() => { this.hiddenFileInput.current.click(); }}>
            Importar
            </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={this.onGuardarCodigos}
            disabled={(this.state.dataChanged.length === 0)}
          >
            Guardar
          </Button>

        </ButtonGroup>

        <Collapse in={this.state.showPanelBusquedas} >

          <Box display='flex' style={{ width: '100%' }}>
            <TextField
              fullWidth
              style={{ margin: 5 }}
              margin="dense" size='small' variant='outlined'
              id="buscarCodigo" label="Codigo" name="buscarCodigo"
              value={this.state.buscarCodigo}
              onChange={this.onChangeInput}
            />
            <TextField
              fullWidth
              style={{ margin: 5 }}
              margin="dense" size='small' variant='outlined'
              id="buscarGenerico" label="Código Genérico" name="buscarGenerico"
              value={this.state.buscarGenerico}
              onChange={this.onChangeInput}
            />
            <TextField
              fullWidth
              style={{ margin: 5 }}
              margin="dense" size='small' variant='outlined'
              id="buscarCentro" label="Centro" name="buscarCentro"
              value={this.state.buscarCentro}
              onChange={this.onChangeInput}
            />
            <TextField
              fullWidth
              style={{ margin: 5 }}
              margin="dense" size='small' variant='outlined'
              id="buscarEvento" label="Evento" name="buscarEvento"
              value={this.state.buscarEvento}
              onChange={this.onChangeInput}
            />

          </Box>

          <Box display='flex' style={{ width: '100%' }}>

            <FormControl variant="outlined" fullWidth size="small" margin='dense' style={{ margin: 5 }}>
              <Select
                labelId="buscarValidado"
                id="buscarValidado"
                name="buscarValidado"
                value={this.state.buscarValidado}
                onChange={this.onChangeInput}
              >
                <MenuItem value="TODOS"><em>Canjeado (Todos)</em></MenuItem>
                <MenuItem value='SI' key='SI'>Si</MenuItem>
                <MenuItem value='NO' key='NO'>No</MenuItem>
              </Select>
            </FormControl>

            <FormControl variant="outlined" fullWidth size="small" margin='dense' style={{ margin: 5 }}>
              <Select
                labelId="buscarUsado"
                id="buscarUsado"
                name="buscarUsado"
                value={this.state.buscarUsado}
                onChange={this.onChangeInput}
              >
                <MenuItem value="TODOS"><em>Emitido (Todos)</em></MenuItem>
                <MenuItem value='SI' key='SI'>Si</MenuItem>
                <MenuItem value='NO' key='NO'>No</MenuItem>
              </Select>
            </FormControl>

            <FormControl variant="outlined" fullWidth size="small" margin='dense' style={{ margin: 5 }}>
              <Select
                labelId="buscarLibre"
                id="buscarLibre"
                name="buscarLibre"
                value={this.state.buscarLibre}
                onChange={this.onChangeInput}
              >
                <MenuItem value="TODOS"><em>Libre (Todos)</em></MenuItem>
                <MenuItem value='SI' key='SI'>Si</MenuItem>
                <MenuItem value='NO' key='NO'>No</MenuItem>
              </Select>
            </FormControl>

            <TextField
              fullWidth
              id="buscarFecha"
              label="Fecha"
              name="buscarFecha"
              autoComplete="buscarFecha"
              size='small'
              value={this.state.buscarFecha}
              type='date'
              variant='outlined'
              InputLabelProps={{ shrink: true }}
              style={{ margin: 5 }}
              margin='dense'
              onChange={this.onChangeInput}
            />

            <TextField
              fullWidth
              id="buscarHora"
              label="Hora"
              name="buscarHora"
              autoComplete="buscarHora"
              size='small'
              value={this.state.buscarHora}
              variant='outlined'
              style={{ margin: 5 }}
              margin='dense'
              onChange={this.onChangeInput}
            />

          </Box>

          <Box display='flex' style={{ width: '100%' }}>

            <TextField
              fullWidth
              id="buscarNombre"
              label="Nombre"
              name="buscarNombre"
              autoComplete="buscarNombre"
              size='small'
              value={this.state.buscarNombre}
              variant='outlined'
              style={{ margin: 5 }}
              margin='dense'
              onChange={this.onChangeInput}
            />

            <TextField
              fullWidth
              id="buscarEmail"
              label="Email"
              name="buscarEmail"
              autoComplete="buscarEmail"
              size='small'
              value={this.state.buscarEmail}
              variant='outlined'
              style={{ margin: 5 }}
              margin='dense'
              onChange={this.onChangeInput}
            />

          </Box>


        </Collapse>

      </Box>);


    return (
      <div>
        <Container maxWidth='lg'>

          {Buscador}

          <div style={{ height: 10 }}></div>

          <TableContainer component={Paper}>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell key={'codigo'}>
                    <TableSortLabel
                      direction={this.props.ordenar['codigo'] ? this.props.ordenar['codigo'] : 'asc'}
                      onClick={() => this.props.onOrdenar('codigo')}>
                      {'Codigo'}
                    </TableSortLabel>
                  </TableCell>

                  <TableCell key={'generico'}>
                    <TableSortLabel
                      direction={this.props.ordenar['generico'] ? this.props.ordenar['generico'] : 'asc'}
                      onClick={() => this.props.onOrdenar('generico')}>
                      {'Código Genérico'}
                    </TableSortLabel>
                  </TableCell>

                  <TableCell key={'empresa'}>
                    <TableSortLabel
                      direction={this.props.ordenar['empresa'] ? this.props.ordenar['empresa'] : 'asc'}
                      onClick={() => this.props.onOrdenar('empresa')}>
                      {'Empresa / Centro / Evento'}
                    </TableSortLabel>
                  </TableCell>

                  <TableCell key={'fechaevento'}>
                    <TableSortLabel
                      direction={this.props.ordenar['fechaeventooriginal'] ? this.props.ordenar['fechaeventooriginal'] : 'asc'}
                      onClick={() => this.props.onOrdenar('fechaeventooriginal')}>
                      {'Fecha Función'}
                    </TableSortLabel>
                  </TableCell>

                  <TableCell key={'nombre'}>
                    <TableSortLabel
                      direction={this.props.ordenar['nombre'] ? this.props.ordenar['nombre'] : 'asc'}
                      onClick={() => this.props.onOrdenar('nombre')}>
                      {'Nombre'}
                    </TableSortLabel>
                  </TableCell>

                  <TableCell key={'email'}>
                    <TableSortLabel
                      direction={this.props.ordenar['email'] ? this.props.ordenar['email'] : 'asc'}
                      onClick={() => this.props.onOrdenar('email')}>
                      {'Email'}
                    </TableSortLabel>
                  </TableCell>

                  <TableCell key={'canjeado'}>
                    <TableSortLabel
                      direction={this.props.ordenar['validado'] ? this.props.ordenar['validado'] : 'asc'}
                      onClick={() => this.props.onOrdenar('validado')}>
                      {'Invitación Canjeada'}
                    </TableSortLabel>
                  </TableCell>

                  <TableCell key={'usado'}>
                    <TableSortLabel
                      direction={this.props.ordenar['usado'] ? this.props.ordenar['usado'] : 'asc'}
                      onClick={() => this.props.onOrdenar('usado')}>
                      {'Entrada Emitida'}
                    </TableSortLabel>
                  </TableCell>

                  <TableCell key={'emailenviado'}>
                    <TableSortLabel
                      direction={this.props.ordenar['emailenviado'] ? this.props.ordenar['emailenviado'] : 'asc'}
                      onClick={() => this.props.onOrdenar('emailenviado')}>
                      {'Email Env.'}
                    </TableSortLabel>
                  </TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {(this.state.rowsPerPage > 0
                  ? this.props.data.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                  : this.props.data
                ).map((element, index) => (
                  <Grow in={true} timeout={index < 50 ? 100 * index : 0} key={element.idcodigos} style={{ backgroundColor: (this.state.dataChanged.findIndex(x => x.idcodigos === element.idcodigos) >= 0 ? '#9E9E9E' : '') }}>
                    <TableRow>
                      <TableCell>
                        <Box display='flex' alignItems='center'>
                          <IconButton onClick={() => this.liberarCodigo(element)} title="Liberar Codigo">
                            <AutorenewIcon />
                          </IconButton>
                          <IconButton onClick={(e) => this.setEditingRow(element)} title="Editar registro">
                            <EditIcon />
                          </IconButton>
                          <Typography align="center">{element.codigo}</Typography>
                        </Box>
                      </TableCell>

                      <TableCell>{element.generico}</TableCell>

                      <TableCell padding='default'>
                        <Container>
                          <div>{element.empresa}</div>
                          <div>{element.centro}</div>
                          <div>{element.evento}</div>
                        </Container>
                      </TableCell>
                      <TableCell>{element.fechaevento}</TableCell>

                      {
                        this.isEditingRow(element.codigo) ?
                          (<TableCell>
                            <TextField
                              fullWidth
                              size='small'
                              value={this.state.dataChanged.findIndex(x => x.idcodigos === element.idcodigos) >= 0 ? this.state.dataChanged[this.state.dataChanged.findIndex(x => x.idcodigos === element.idcodigos)].nombre || element.nombre : element.nombre}
                              variant='outlined'
                              InputLabelProps={{ shrink: true }}
                              margin='dense'
                              onChange={(e) => this.editingRow('nombre', e, element)}
                            />
                          </TableCell>) :
                          <TableCell>{element.nombre}</TableCell>
                      }

                      {
                        this.isEditingRow(element.codigo) ?
                          (<TableCell>
                            <TextField
                              fullWidth
                              size='small'
                              value={this.state.dataChanged.findIndex(x => x.idcodigos === element.idcodigos) >= 0 ? this.state.dataChanged[this.state.dataChanged.findIndex(x => x.idcodigos === element.idcodigos)].email || element.email : element.email}
                              variant='outlined'
                              InputLabelProps={{ shrink: true }}
                              margin='dense'
                              onChange={(e) => this.editingRow('email', e, element)}
                            />
                          </TableCell>
                          ) :
                          <TableCell>{element.email}</TableCell>
                      }


                      <TableCell>
                        {element.estavalidado === 'SI'
                          ? element.validado
                          : (
                            <ThumbDownIcon />
                          )}
                      </TableCell>

                      <TableCell>
                        {element.estausado === 'SI'
                          ? element.usado
                          : (
                            <IconButton onClick={() => this.setState({ showConfirmation: true, elementSeleccionado: element })}>
                              <ThumbDownIcon />
                            </IconButton>
                          )}
                      </TableCell>

                      <TableCell>
                        {element.emailenviado === 'SI'
                          ? (<EmailIcon />)
                          : ''}
                      </TableCell>

                    </TableRow>
                  </Grow>
                ))}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 25 * emptyRows }}>
                    <TableCell colSpan={9} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 20, 50, { label: 'All', value: -1 }]}
                    colSpan={9}
                    count={this.props.data.length}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onChangePage={(e, page) => this.handleChangePage(page)}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>

          {
            this.state.showConfirmation === true ?
              (<PopUpConfirmacion messageInfo={'¿Emitir entrada?'}
                onOk={() => this.onCodigoUsado(this.state.elementSeleccionado)}
                onCancel={this.onHideConfirmation} />)
              : ''
          }

          {
            this.state.error === true ? AlertloginFailed : ''
          }

        </Container>
      </div >);
  }

}

export default ListadoParaEmpresas;
