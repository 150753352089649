import React from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { Usuarios } from '../../entity/usuarios';

import '../../css/style.css';

class RecuperarCuenta extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      errorLogin: false,
      errorText: ''
    }

    this.onRecuperar = this.onRecuperar.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
  }

  onRecuperar(event) {
    event.preventDefault();

    this.setState({ loading: true, errorLogin: false });

    Usuarios.recuperarcuenta({email: this.state.email})
      .then(
        data => {
          if (data) {
            const { from } = this.props.location.state || { from: { pathname: "/login" } };
            this.props.history.push(from);
          }
          else {
            this.setState({ errorText: data.message }, () => {
              this.setState({ errorLogin: true, loading: false });
            })
          }
        }
      );

  }

  onChangeInput(event) {
    this.setState({ [event.target.id]: event.target.value });
  }


  render() {
    return (
      <div>

        <div style={{ height: 40 }}></div>


        <Container maxWidth="xs">

          <Typography style={{ textAlign: 'center' }}>
            Una vez que indiques tu correo electrónico, te enviaremos una nueva clave para que puedas acceder.
          </Typography>

          <div style={{ height: 20 }}></div>

          <Typography style={{ textAlign: 'center' }}>
            Recuerda que puedes modificar la clave en cualquier momento desde la edición de tu perfil.
          </Typography>


          <Box p={2}>

            <form noValidate autoComplete="off">

              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                inputProps={{ maxLength: 150 }}

                onChange={this.onChangeInput}
              />

              <Button
                fullWidth
                variant="contained"
                disabled={this.state.loading}
                color="primary"
                onClick={this.onRecuperar}
              >
                Recuperar Cuenta
          </Button>

            </form>


          </Box>

          <div style={{ height: 20 }}></div>

          <img src="./images/block/musicalpass.png" width="100%" alt="Musical Pass" />

        </Container>
      </div>
    );
  }
}

export default RecuperarCuenta;