import React from 'react';


import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';


import ShowDataSheet from './ShowDataSheet/ShowDataSheet';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import * as data from '../mocks/data.json';

class PopUpMasInfoEvento extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      id: this.props.id,
      showMasInfo: false,
      info: undefined
    }

    this.masInfo = this.masInfo.bind(this);
  }

  componentDidMount() {
  }

  masInfo() {    
    let eventos = data.shows.sort((a, b) => a.orden - b.orden ).filter(x => x.visible === true && x.id === this.state.id);

    console.log(this.state.id);
    if (eventos.length == 1) {
      this.setState({info: eventos[0]['techSheet'], showMasInfo: true});
    }
  }


  render() {


    return (
      <Box margin={'5px'}>
        < Dialog open={this.state.showMasInfo} onClose={() => this.setState({ showMasInfo: false })}>
          <DialogContent style={{ borderRadius: 15 }}>
            <ShowDataSheet info={this.state.info} />
          </DialogContent>
        </Dialog >

        <Button variant="contained" color="primary" onClick={this.masInfo}>más info</Button>
      </Box>
    );
  }

}

export default PopUpMasInfoEvento;
