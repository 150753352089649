import React from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import Typography from '@material-ui/core/Typography';

import Header from '../../component/header';
import PopUpInfo from '../../component/popUpInfo';

import { Usuarios } from '../../entity/usuarios';



import '../../css/style.css';
import { IconButton } from '@material-ui/core';

class EditPerfil extends React.Component {

  constructor(props) {
    super(props);

    // para obtener la nueva imagen
    this.hiddenFileInput = React.createRef();


    this.state = {
      idusuarios: '',
      nombre: JSON.parse(localStorage.getItem('userLogged')).nombre,
      email: JSON.parse(localStorage.getItem('userLogged')).email,
      movil: JSON.parse(localStorage.getItem('userLogged')).movil,
      imagen: '',
      imagenData: undefined,
      clave: '',
      clave2: '',
      errorLogin: false,
      errorText: ''
    }


    this.onGuardar = this.onGuardar.bind(this);
    this.onBack = this.onBack.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
  }

  componentDidMount() {
    this.onCargar();
  }

  onCargar() {

    Usuarios.getUsuariosMiCuenta()
      .then(
        data => {
          if (data) {
            this.setState({ imagen: data.imagen, idusuarios: data.idusuarios });
          }
        }
      );
  }


  onGuardar(event) {
    event.preventDefault();

    // la clave y repetición de clave deben ser iguales
    if ((this.state.clave || this.state.clave2) && this.state.clave !== this.state.clave2) {
      this.setState({ errorText: "Indique claves iguales", errorLogin: true, loading: false });
      return;
    }

    this.setState({ loading: true, errorLogin: false });

    let data = new FormData()

    // solo mandamos la imagen en el caso de que se haya seleccionado alguna
    if (this.state.imagenData) {
      data.append('imagen', this.state.imagenData)
    }

    data.append('idusuarios', this.state.idusuarios);
    data.append('nombre', this.state.nombre);
    data.append('email', this.state.email);
    data.append('movil', this.state.movil);
    data.append('clave', this.state.clave);
    data.append('clave2', this.state.clave2);

    Usuarios.guardar(data)
      .then(
        user => {
          if (user) {
            // actualizo los datos en memoria
            let userLogged = JSON.parse(localStorage.getItem('userLogged'));
            userLogged.nombre = this.state.nombre;
            userLogged.email = this.state.email;
            userLogged.movil = this.state.movil;
            userLogged.imagen = this.state.imagen;

            localStorage.setItem('userLogged', JSON.stringify(userLogged));


            const { from } = this.props.location.state || { from: { pathname: "/main" } };
            this.props.history.push(from);
          }
          else {
            this.setState({ errorText: user.message, errorLogin: true, loading: false });
          }
        }
      );

  }

  onBack() {
    window.history.back();
  }

  onChangeInput(event) {
    this.setState({ [event.target.id]: event.target.value });
  }

  render() {
    const AlertloginFailed = <PopUpInfo messageInfo={this.state.errorText} onClose={() => { this.setState({ error: false, errorText: '' }) }} />

    return (
      <div>

        <Header title="Inicio" history={this.props.history}></Header>

        <div style={{ height: 40 }}></div>

        <Container maxWidth="xs">

          <Box bgcolor='white' padding={1} borderRadius={15}>

            <Typography align="center" variant="h5">Edición del Perfil</Typography>

            <Box display='flex' justifyContent='center' m={2} alignItems="flex-end">
              <Avatar
                alt={this.state.nombre}
                src={this.state.imagen}
                style={{ width: '150px', height: '150px' }} />

              <input ref={this.hiddenFileInput} accept="image/*" type="file" style={{ display: "none" }}
                onChange={e => {
                  console.log(e.target.files[0]);
                  this.setState({ imagenData: e.target.files[0], imagen: URL.createObjectURL(e.target.files[0]) });
                }} />
              <Avatar>
                <IconButton onClick={() => { this.hiddenFileInput.current.click(); }}>
                  <PhotoCameraIcon />
                </IconButton>
              </Avatar>

            </Box>

            <TextField
              margin="normal"
              variant="outlined"
              required
              fullWidth
              id="nombre"
              label="Nombre"
              name="nombre"
              autoComplete="nombre"
              defaultValue={this.state.nombre}
              autoFocus

              onChange={this.onChangeInput}
            />

            <TextField
              margin="normal"
              variant="outlined"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              defaultValue={this.state.email}

              onChange={this.onChangeInput}
            />

            <TextField
              margin="normal"
              variant="outlined"
              required
              fullWidth
              id="movil"
              label="Móvil"
              name="movil"
              autoComplete="movil"
              defaultValue={this.state.movil}

              onChange={this.onChangeInput}
            />

            <Box display="flex">
              <TextField
                margin="normal"
                variant="outlined"
                name="clave"
                label="Clave"
                type="password"
                id="clave"
                style={{ margin: 5 }}

                onChange={this.onChangeInput}
              />

              <TextField
                margin="normal"
                variant="outlined"
                name="clave2"
                label="Repetir Clave"
                type="password"
                id="clave2"
                style={{ margin: 5 }}

                onChange={this.onChangeInput}
              />
            </Box>

            <div style={{ height: 20 }}></div>

            <Box display="flex" justifyContent="center">

              <Button
                fullWidth
                style={{ margin: 5 }}
                variant="contained"
                disabled={this.state.loading}
                color="primary"
                onClick={this.onGuardar}
              >
                Guardar
          </Button>

              <Button
                fullWidth
                style={{ margin: 5 }}
                variant="contained"
                disabled={this.state.loading}
                color="primary"
                onClick={this.onBack}
              >
                Volver
          </Button>

            </Box>


            {this.state.errorLogin === true ? AlertloginFailed : ''}

          </Box>


        </Container>
      </div>
    );
  }
}

export default EditPerfil;