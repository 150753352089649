import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import grey from '@material-ui/core/colors/grey';

import HomeV1 from './views/home/HomeV1';

import Login from './views/users/login';
import Registro from './views/users/registro';
import Main from './views/main';
import EditPerfil from './views/users/editPerfil';
import Empresa from './component/Empresa/Empresa';

import GestionCentros from './views/centros/gestionCentros';
import GestionEventos from './views/eventos/gestionEventos';
import GestionEventosCategorias from './views/eventos/gestionEventosCategorias';
import GestionEmpresas from './views/empresas/gestionEmpresas';
import RecuperarCuenta from './views/users/recuperarcuenta';
import CrearEntradas from './views/entradas/crearEntradas';
import GestionUsuarios from './views/users/gestionUsuarios';
import GestionBundle from './views/bundle/gestionBundle';
import GestionEmailPlantilla from './views/emailplantilla/gestionEmailPlantilla';
import Dashboard from './views/entradas/dashboard';
import Reservas from './views/entradas/reservas';
import EntradasCategorias from './views/entradas/entradasCategorias';


import { PrivateRoute } from './util/private-route';
import { Constantes } from './util/constantes';
import ValidarCodigos from './views/users/validarCodigos';
import RegistroRealizado from './views/users/registroRealizado';
import RegistroFinalizado from './views/users/registroFinalizado';

/*
const theme = createMuiTheme({
  palette: {
    primary: yellow,
    secondary: grey,
  },
  overrides: {
    MuiInputBase: {
      root: {
        color: 'white',
        borderColor: 'white',
      },
    },

    MuiTypography: {
      colorTextSecondary: {
        color: 'white'
      }
    },
    MuiOutlinedInput: {
      root: {
        color: 'white',
        borderColor: 'white',
      },
      notchedOutline: {
        borderColor: 'yellow',
      },
    },
    MuiFormLabel: {
      root: {
        color: 'white'
      }
    },
    MuiPaper: {
      root: {
        backgroundColor: 'rgb(0, 0, 0)',
        color: 'white'
      }
    },
    MuiSvgIcon: {
      root: {
        color: 'white'
      }
    },
    MuiTablePagination: {
      root: {
        color: 'white'
      },
      select: {
        color: 'red'
      },
    },
    MuiTableRow: {
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: 'rgba(90, 90, 90, 0.3)',
        },
      },
    },
    MuiTableCell: {
      head: {
        color: 'white',
        backgroundColor: '#488dc5'
      },
      body: {
        color: 'white'
      },
    }
  }
});
*/

const theme = createMuiTheme({
  typography: {
    fontFamily: `"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif`,
  },
  palette: {
    primary: grey
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: 'var(--primary-color)',
        color: 'black'
      }
    },
    MuiButton: {
      containedPrimary: {
        backgroundColor: 'var(--primary-color)',
        color: 'black'
      },
    },
  }
});


class App extends React.Component {

  constructor(props) {
    super(props);
  }


  render() {

    if (!Constantes.getUserLogged()) {
      localStorage.removeItem('userLogged');
    }

    return (
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Switch>

            {/* HOME */}
            <Route exact path="/" component={HomeV1}></Route>

            {/* usuarios */}
            <Route exact path="/login" component={Login}></Route>

            <Route exact path="/registro" component={Registro}></Route>
            <Route exact path="/registrorealizado" component={RegistroRealizado}></Route>
            <Route exact path="/correcto" component={RegistroFinalizado}></Route>
            <Route exact path="/empresa" component={Empresa}></Route>

            <PrivateRoute exact path="/editarperfil" component={EditPerfil}></PrivateRoute>
            <PrivateRoute exact path="/validarcodigos" component={ValidarCodigos}></PrivateRoute>

            {/* administrador */}
            <PrivateRoute exact path="/gestioncentros" component={GestionCentros}></PrivateRoute>
            <PrivateRoute exact path="/gestioneventoscategorias" component={GestionEventosCategorias}></PrivateRoute>
            <PrivateRoute exact path="/gestioneventos" component={GestionEventos}></PrivateRoute>
            <PrivateRoute exact path="/gestionempresas" component={GestionEmpresas}></PrivateRoute>
            <PrivateRoute exact path="/crearentradas" component={CrearEntradas}></PrivateRoute>
            <PrivateRoute exact path="/gestionusuarios" component={GestionUsuarios}></PrivateRoute>
            <PrivateRoute exact path="/gestionbundle" component={GestionBundle}></PrivateRoute>
            <PrivateRoute exact path="/gestionemailplantilla" component={GestionEmailPlantilla}></PrivateRoute>
            <PrivateRoute exact path="/dashboard" component={Dashboard}></PrivateRoute>

            <PrivateRoute exact path="/entradascategorias" component={EntradasCategorias}></PrivateRoute>

            <Route exact path="/recuperarcuenta" component={RecuperarCuenta}></Route>


            <PrivateRoute exact path="/reservas" component={Reservas}></PrivateRoute>

            <PrivateRoute component={Main} />

          </Switch>
        </BrowserRouter >
      </ThemeProvider>
    );
  }
}


export default App;