import React from 'react';

import moment from 'moment';


import { FormControl, InputLabel, Select, MenuItem,  Button, Container } from '@material-ui/core';
import { Box } from '@material-ui/core';
import Grow from '@material-ui/core/Grow';
import { Typography } from '@material-ui/core';


import '../../css/style.css';

import { Usuarios } from '../../entity/usuarios';
import PopUpMasInfoEvento from '../../component/popUpMasInfoEvento';



class CambioDeEvento extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      //si hay eventos disponibles para que el usuario elija las entradas
      eventosCategorias: [],
      eventosCategoriasDetalle: [],

      fechaSeleccionada: undefined,
      fecha: undefined

    }
  }

  componentDidMount() {
    Usuarios.getEventosParaCambio({ codigo: this.props.item.codigo })
      .then(
        data => {
          if (data.records) {
            if (data.records.length > 0) {
              // obtenemos el resumen de los eventos disponibles
              let detalle = data.records;

              let eventos = [];
              detalle.forEach(element => {
                if (eventos.filter(x => x.ideventos == element.ideventos && x.idcentros === element.idcentros).length === 0) {
                  eventos.push({
                    ideventos: element.ideventos,
                    idcentros: element.idcentros,
                    evento: element.evento,
                    centro: element.centro,
                    direccion: element.direccion,
                    eventoimagen: element.eventoimagen,
                    libres: element.libres,
                    jsonDataID: element.jsonDataID                    
                  });
                }
                else {
                  // sumno las entradas libres
                  eventos.forEach((evento, indexEvento) => {
                    if (evento.ideventos === element.ideventos && evento.idcentros === element.idcentros) {
                      eventos[indexEvento].libres = parseInt(eventos[indexEvento].libres) + parseInt(element.libres);
                    }
                  });
                }
              });

              this.setState({ eventosCategorias: eventos, eventosCategoriasDetalle: detalle });

            }
            else {
              this.props.setError("No tiene eventos disponibles");
            }
          }
        }
      );
  }

  setCambioEvento() {

    // debemos seleccionar una fecha
    if (this.state.fecha === undefined) {
      this.props.setError("Seleccione una fecha de evento");
      return;
    };

    let formulario = { ...this.state.fechaSeleccionada };
    formulario.idcodigos = this.props.item.idcodigos;



    Usuarios.setCambioEvento(formulario)
      .then(
        data => {
          if (data === true) {
            this.props.onRefresh({});
            this.props.onClose();
          }
          else {
            this.props.setError(data.message);
          }
        }
      );
  }


  render() {


    return (

      <Container>
        <Typography variant='h5' align='center'>Seleccione el evento para cambiar</Typography>

        <Button
          fullWidth
          variant="contained"
          color="primary"
          style={{ marginBottom: 40, marginTop: 20 }}
          onClick={this.props.onClose}
        >
          Volver
        </Button>

        <Box display='flex' justifyContent='space-between' flexWrap='wrap'>
          {
            this.state.eventosCategorias.map((element, index) => {

              return (
                <Grow in={true} key={index} style={{ width: '30%', marginBottom: 20, minWidth: '300px' }}>

                  <Box display='flex' borderRadius={15} bgcolor='white' p={1} width={1}>

                    <Box width='250px' padding={1} display='flex' justifyContent='flex-start' alignItems={'center'} flexDirection={'column'}>
                      <img src={element.eventoimagen} alt={element.nombre} className="user-list__img" />
                      <PopUpMasInfoEvento id={element.jsonDataID} />
                    </Box>

                    <Box display='flex' flexDirection='column' alignItems='flex-start'>
                      <Typography variant="h5">{element.evento}</Typography>
                      <Typography variant="body1">{element.centro}</Typography>
                      <Typography variant="body2">{element.direccion}</Typography>

                      <div style={{ height: 10 }}></div>

                      <FormControl variant="outlined" fullWidth size="small" margin='normal' required>
                        <InputLabel>Seleccione fecha</InputLabel>
                        <Select
                          labelId="fecha"
                          id="fecha"
                          name="fecha"
                          value={this.state.fecha}
                          label="Fecha"
                          onChange={(e) => this.setState({ fechaSeleccionada: e.target.value, fecha: e.target.value.fechaEvento })}
                        >
                          {
                            this.state.eventosCategoriasDetalle.filter(x => x.ideventos === element.ideventos && x.idcentros === element.idcentros).map((elementFecha, indexFecha) => {
                              return (<MenuItem value={elementFecha} key={indexFecha}>
                                <Box display={'flex'}>
                                  <Typography>{`${moment(elementFecha.fechaEvento).format('DD/MM/YYYY')}`}</Typography>
                                  <Typography style={{ fontWeight: 'bold', marginLeft: 5 }}>{`${elementFecha.hora}`}</Typography>
                                </Box>
                              </MenuItem>);
                            })
                          }
                        </Select>
                      </FormControl>

                      <div style={{ height: 10 }}></div>

                      <Button fullWidth variant="contained" color="primary" onClick={() => this.setCambioEvento()}>Seleccionar Evento</Button>

                    </Box>

                  </Box>

                </Grow>
              )
            })
          }
        </Box>

      </Container>
    );
  }

}

export default CambioDeEvento;