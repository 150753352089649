import React from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grow from '@material-ui/core/Grow';
import Typography from '@material-ui/core/Typography';

import { Editor } from '@tinymce/tinymce-react';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { Empresas } from '../../entity/empresas';
import { EmailPlantilla } from '../../entity/emailplantilla';

import PopUpInfo from '../../component/popUpInfo';

import '../../css/style.css';

class EditarEmailPlantilla extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      rowsPerPage: 10,
      page: 0,
      ordenar: [],

      idemail_plantilla: this.props.item.idemail_plantilla,
      nombre: this.props.item.nombre,
      idempresas: this.props.item.idempresas,

      plantilla_cabecera: this.props.item.plantilla_cabecera,
      plantilla_cabeceraInitialValue: this.props.item.plantilla_cabecera,

      plantilla_detalle: this.props.item.plantilla_detalle,
      plantilla_detalleInitialValue: this.props.item.plantilla_detalle,

      plantilla_pie: this.props.item.plantilla_pie,
      plantilla_pieInitialValue: this.props.item.plantilla_pie,

      empresas: [],
      tags: [],

    }

    this.onChangeInput = this.onChangeInput.bind(this);
    this.onChangeInputSelect = this.onChangeInputSelect.bind(this);

    this.borrar = this.borrar.bind(this);

    this.cargarSelect = this.cargarSelect.bind(this);
    this.cargarEmpresas = this.cargarEmpresas.bind(this);
  }

  componentDidMount() {
    this.cargarSelect();
  }


  onChangeInput(event) {
    this.setState({ [event.target.id]: event.target.value });
  }

  onChangeInputSelect(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  onGuardar = () => {
    this.props.onGuardar({
      idemail_plantilla: this.state.idemail_plantilla,
      nombre: this.state.nombre,
      idempresas: this.state.idempresas,
      plantilla_cabecera: this.state.plantilla_cabecera,
      plantilla_detalle: this.state.plantilla_detalle,
      plantilla_pie: this.state.plantilla_pie,
    });
  }

  onBack = () => {
    this.props.onBack();
  }

  async cargarSelect() {

    this.setState({ loading: true });

    await this.cargarEmpresas();
    await this.cargarTags();

    this.setState({ loading: false });
  }

  borrar() {
    let formulario = new FormData()
    formulario.append('id', this.state.idemail_plantilla);

    EmailPlantilla.borrar(formulario)
      .then(
        valid => {
          if (valid === true) {
            this.onBack();
          }
          else {
            this.setState({ error: true, loading: false, errorText: valid.message });
          }
        }
      );
  }

  async cargarEmpresas() {
    Empresas.getEmpresas()
      .then(
        data => {
          if (data.records) {
            this.setState({ empresas: data.records });
          }
        }
      );
  }

  async cargarTags() {
    EmailPlantilla.getPlantillasTags()
      .then(
        data => {
          if (data.records) {
            this.setState({ tags: data.records });
          }
        }
      );
  }


  render() {

    const emptyRows = 1;

    const AlertloginFailed = <PopUpInfo messageInfo={this.state.errorText} onClose={() => { this.setState({ error: false, errorText: '' }) }} />


    return (
      <Container maxWidth='md'>

        <Grow in={true} timeout={500}>

          <Box className="box-blanco">

            <TextField
              margin="dense"
              required
              fullWidth
              autoFocus
              id="nombre"
              label="Nombre"
              name="nombre"
              autoComplete="nombre"
              size='small'
              value={this.state.nombre}

              onChange={this.onChangeInput}
            />

            <FormControl variant="outlined" fullWidth size="small" margin='normal' required>
              <InputLabel>Empresa</InputLabel>
              <Select
                labelId="idempresas"
                id="idempresas"
                name="idempresas"
                value={this.state.idempresas}
                label="Empresa"
                onChange={this.onChangeInputSelect}
              >
                <MenuItem value="">
                  <em>Seleccionar</em>
                </MenuItem>
                {
                  this.state.empresas.map((element, index) => {
                    return (<MenuItem value={element.idempresas} key={element.idempresas}>{element.nombre}</MenuItem>);
                  })
                }
              </Select>
            </FormControl>

            <Typography align="center" variant="h4">Cabecera</Typography>

            <Box display='flex' flexWrap='wrap' m={1}>
              {this.state.tags.map((element, index) => (
                <Chip label={element.tag} color='secondary' style={{ margin: '5px', fontSize: '12px' }} onClick={() => this.setState({ plantilla_cabecera: this.state.plantilla_cabecera + ' ' + element.tag + ' ' })} />
              ))}
            </Box>

            <Editor
              value={this.state.plantilla_cabecera}
              onEditorChange={(e) => this.setState({ plantilla_cabecera: e })}
              init={{
                height: 500,
                menubar: true,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount media'
                ],
                toolbar: 'undo redo | formatselect | ' +
                  'bold italic backcolor fontcolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | help | media',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
              }}
            />

            <Typography align="center" variant="h4">Detalle</Typography>

            <Box display='flex' flexWrap='wrap' m={1}>
              {this.state.tags.map((element, index) => (
                <Chip label={element.tag} color='secondary' style={{ margin: '5px', fontSize: '12px' }} onClick={() => this.setState({ plantilla_detalle: this.state.plantilla_detalle + ' ' + element.tag + ' ' })} />
              ))}
            </Box>

            <Editor
              value={this.state.plantilla_detalle}
              onEditorChange={(e) => this.setState({ plantilla_detalle: e })}
              init={{
                height: 500,
                menubar: true,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount media'
                ],
                toolbar: 'undo redo | formatselect | ' +
                  'bold italic backcolor fontcolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | help | media',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
              }}
            />

            <Typography align="center" variant="h4">Pie</Typography>

            <Box display='flex' flexWrap='wrap' m={1}>
              {this.state.tags.map((element, index) => (
                <Chip label={element.tag} color='secondary' style={{ margin: '5px', fontSize: '12px' }} onClick={() => this.setState({ plantilla_pie: this.state.plantilla_pie + ' ' + element.tag + ' ' })} />
              ))}
            </Box>

            <Editor
              value={this.state.plantilla_pie}
              onEditorChange={(e) => this.setState({ plantilla_pie: e })}
              init={{
                height: 500,
                menubar: true,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount media'
                ],
                toolbar: 'undo redo | formatselect | ' +
                  'bold italic backcolor fontcolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | help | media',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
              }}
            />

            <div style={{ height: 20 }}></div>

            <Box display="flex" justifyContent="center">

              <Button
                fullWidth
                variant="contained"
                disabled={this.state.loading}
                color="primary"
                style={{ margin: 5 }}
                onClick={this.onGuardar}
              >
                Guardar
          </Button>

              <Button
                fullWidth
                variant="contained"
                color="primary"
                style={{ margin: 5 }}
                onClick={this.onBack}
              >
                Volver
          </Button>

              <Button
                fullWidth
                variant="contained"
                color="primary"
                style={{ margin: 5 }}
                onClick={this.borrar}
              >
                Borrar
          </Button>

            </Box>

            <div style={{ height: 20 }}></div>

          </Box>

        </Grow>

        {this.state.error === true ? AlertloginFailed : ''}

      </Container>
    );
  }

}

export default EditarEmailPlantilla;
