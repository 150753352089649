import React, { useState } from 'react';
import Modal from '../Modal';
import { useModal } from '../../hooks/useModal';
import ShowCard from '../ShowCard/ShowCard';
import ShowDataSheet from '../ShowDataSheet/ShowDataSheet';
import './ShowsGrid.css';

import * as data from './../../mocks/data.json';

const ShowsGrid = () => {


  let gridNumber = 0;

  const [isModal, setIsModal] = useState(false);
  const [info, setInfo] = useState(undefined);

  const setGridClass = (param) => {
    let className;
    if (param === 0) {
      className = 'full-card';
    } else {
      ++gridNumber;
      if (gridNumber === 1) {
        className = 'narrow-left-card';
      } else if (gridNumber === 2) {
        className = 'large-right-card';
      } else if (gridNumber === 3) {
        className = 'large-left-card';
      } else if (gridNumber === 4) {
        className = 'narrow-right-card';
        gridNumber = 0;
      }
    }
    return className;
  };

  return (
    <div className="mp-products">
      <div className="mp-products-wrapper">
        {data.shows.sort((a, b) => a.orden - b.orden ).filter(x => x.visible === true).map(
          (item, index) => (
            <ShowCard
              id={item.id}
              key={item.id}
              info={item}
              gridClass={setGridClass(index)}
              clickModal={() => {
                setInfo(item['techSheet']);                
                setIsModal(true);
              }}
            />
          )
        )}
      </div>
      {isModal
        ? <Modal isOpen={isModal} closeModal={() => setIsModal(false)}>
          <ShowDataSheet info={info} />
        </Modal>
        : <div></div>
      }

    </div>
  );
}

export default ShowsGrid;