import React from 'react';

import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Grow from '@material-ui/core/Grow';
import Avatar from '@material-ui/core/Avatar';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import moment from 'moment';

import { Usuarios } from '../../entity/usuarios';
import { Constantes } from '../../util/constantes';

import ListadoParaUsuarios from './listadoParaUsuarios';
import ListadoParaEmpresas from './listadoParaEmpresas';
import ListadoParaAdministradores from './listadoParaAdministradores';
import ListadoParaCentros from './listadoParaCentros';

import '../../css/style.css';

import PopUpInfo from '../../component/popUpInfo';

class ListadoCodigos extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      errorText: '',
      data: [],

      ordenar: []
    }

    this.onRefresh = this.onRefresh.bind(this);
    this.cargarCodigos = this.cargarCodigos.bind(this);
    this.toExcel = this.toExcel.bind(this);
    this.onOrdenar = this.onOrdenar.bind(this);
  }

  componentDidMount() {
    this.cargarCodigos({
      validado: 'SI'
    });
  }

  onRefresh(formulario) {
    this.cargarCodigos(formulario);
  }


  cargarCodigos(formulario) {
    Usuarios.getCodigos(formulario)
      .then(
        codigos => {
          if (codigos.records) {
            this.setState({ data: codigos.records });
          }
        }
      );
  }

  toExcel(formulario) {

    Usuarios.ExportToCSV(formulario)
      .then(
        excel => {
          if (excel === false) {
            this.setState({ error: true, errorText: 'Problemas al exportar a Excel' });
          }
          else {
            window.open(excel, '_blank');
          }
        }
      );
  }

  onOrdenar(por) {

    if (por) {

      let tmpOrdenar = this.state.ordenar;
      if (!tmpOrdenar[por]) {
        tmpOrdenar[por] = 'asc';
      }
      else {
        tmpOrdenar[por] = tmpOrdenar[por] === 'asc' ? 'desc' : 'asc';
      }

      let datosOrdenados = this.state.data;

      // si quieremos ordenar la fecha de vento, entonces se hace un tratamiento especial, ya que es una fecha
      tmpOrdenar[por] === 'asc' ?
        datosOrdenados.sort((a, b) => (a[por] > b[por]) ? 1 : ((b[por] > a[por]) ? -1 : 0))
        : datosOrdenados.sort((a, b) => (a[por] < b[por]) ? 1 : ((b[por] < a[por]) ? -1 : 0));

      this.setState({ data: datosOrdenados, ordenar: tmpOrdenar });
    }
  }

  render() {

    const AlertloginFailed = <PopUpInfo messageInfo={this.state.errorText} onClose={() => { this.setState({ error: false, errorText: '' }) }} />

    const listado = () => {
      if (Constantes.getUserLogged()) {
        if (Constantes.getUserLogged().idperfiles === 'USUARIO') {
          return <ListadoParaUsuarios
            data={this.state.data}
            onRefresh={this.onRefresh} />
        }

        if (Constantes.getUserLogged().idperfiles === 'EMPRESA') {
          return <ListadoParaEmpresas
            data={this.state.data}
            ordenar={this.state.ordenar}
            onRefresh={this.onRefresh}
            toExcel={this.toExcel}
            onOrdenar={this.onOrdenar} />
        }

        if (Constantes.getUserLogged().idperfiles === 'CENTRO') {
          return <ListadoParaCentros
            data={this.state.data}
            ordenar={this.state.ordenar}
            onRefresh={this.onRefresh}
            toExcel={this.toExcel}
            onOrdenar={this.onOrdenar} />
        }

        if (Constantes.getUserLogged().idperfiles === 'ADMINISTRADOR') {
          return <ListadoParaAdministradores
            data={this.state.data}
            ordenar={this.state.ordenar}
            onRefresh={this.onRefresh}
            toExcel={this.toExcel}
            onOrdenar={this.onOrdenar} />
        }

        return '';
      }
    }

    return (
      <div>
        {listado()}
      </div>
    );
  }

}

export default ListadoCodigos;
