import React from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Grow from '@material-ui/core/Grow';
import Typography from '@material-ui/core/Typography';

import IconButton from '@material-ui/core/IconButton';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';

import PopUpInfo from '../../component/popUpInfo';


import '../../css/style.css';

class EditarEmpresa extends React.Component {

  constructor(props) {
    super(props);

    // para obtener la nueva imagen
    this.hiddenFileInput = React.createRef();

    this.state = {
      idempresas: this.props.item.idempresas,
      nombre: this.props.item.nombre,
      nombrecorto: this.props.item.nombrecorto,
      telefono: this.props.item.telefono,
      email: this.props.item.email,
      cif: this.props.item.cif,
      direccion: this.props.item.direccion,
      poblacion: this.props.item.poblacion,
      imagen: this.props.item.imagen,
      imagenData: undefined,
    }

    this.onChangeInput = this.onChangeInput.bind(this);
  }

  componentDidMount() {
  }

  onChangeInput(event) {
    this.setState({ [event.target.id]: event.target.value });
  }

  onGuardar = () => {

    let data = new FormData()

    // solo mandamos la imagen en el caso de que se haya seleccionado alguna
    if (this.state.imagenData) {
      data.append('imagen', this.state.imagenData)
    }

    data.append('idempresas', this.state.idempresas);
    data.append('nombre', this.state.nombre);
    data.append('email', this.state.email);
    data.append('telefono', this.state.telefono);
    data.append('nombrecorto', this.state.nombrecorto);
    data.append('cif', this.state.cif);
    data.append('direccion', this.state.direccion);
    data.append('poblacion', this.state.poblacion);

    this.props.onGuardar(data);
  }

  onBack = () => {
    this.props.onBack();
  }

  render() {

    const AlertloginFailed = <PopUpInfo messageInfo={this.state.errorText} onClose={() => { this.setState({ error: false, errorText: '' }) }} />

    return (
      <Container maxWidth='xs'>

        <Grow in={true} timeout={500}>

          <Box className="box-blanco">

            <div style={{ height: 20 }}></div>

            <Box display='flex' justifyContent='center' m={2} alignItems="flex-end">
              <Avatar
                alt={this.state.nombre}
                src={this.state.imagen}
                style={{ width: '150px', height: '150px' }} />

              <input ref={this.hiddenFileInput} accept="image/*" type="file" style={{ display: "none" }}
                onChange={e => {
                  console.log(e.target.files[0]);
                  this.setState({ imagenData: e.target.files[0], imagen: URL.createObjectURL(e.target.files[0]) });
                }} />
              <Avatar>
                <IconButton onClick={() => { this.hiddenFileInput.current.click(); }}>
                  <PhotoCameraIcon />
                </IconButton>
              </Avatar>

            </Box>

            <TextField
              margin="dense"
              required
              fullWidth
              autoFocus
              id="nombre"
              label="Nombre"
              name="nombre"
              autoComplete="nombre"
              size='small'
              value={this.state.nombre}

              onChange={this.onChangeInput}
            />

            <TextField
              margin="dense"
              required
              fullWidth
              id="nombrecorto"
              label="Nombre Corto"
              name="nombrecorto"
              autoComplete="nombrecorto"
              size='small'
              value={this.state.nombrecorto}

              onChange={this.onChangeInput}
            />

            <TextField
              margin="dense"
              required
              fullWidth
              id="telefono"
              label="Telefono"
              name="telefono"
              autoComplete="telefono"
              size='small'
              value={this.state.telefono}

              onChange={this.onChangeInput}
            />

            <TextField
              margin="dense"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              size='small'
              value={this.state.email}

              onChange={this.onChangeInput}
            />

            <TextField
              margin="dense"
              required
              fullWidth
              id="cif"
              label="C.I.F."
              name="cif"
              autoComplete="cif"
              size='small'
              value={this.state.cif}

              onChange={this.onChangeInput}
            />

            <TextField
              margin="dense"
              required
              fullWidth
              id="direccion"
              label="Direccion"
              name="direccion"
              autoComplete="direccion"
              size='small'
              value={this.state.direccion}

              onChange={this.onChangeInput}
            />

            <TextField
              margin="dense"
              required
              fullWidth
              id="poblacion"
              label="Poblacion"
              name="poblacion"
              autoComplete="poblacion"
              size='small'
              value={this.state.poblacion}

              onChange={this.onChangeInput}
            />


            <div style={{ height: 20 }}></div>

            <Box display="flex" justifyContent="center">

              <Button
                fullWidth
                variant="contained"
                disabled={this.state.loading}
                color="primary"
                style={{ margin: 5 }}
                onClick={this.onGuardar}
              >
                Guardar
          </Button>

              <Button
                fullWidth
                variant="contained"
                color="primary"
                style={{ margin: 5 }}
                onClick={this.onBack}
              >
                Volver
          </Button>

            </Box>

          </Box>

        </Grow>

      </Container>
    );
  }

}

export default EditarEmpresa;
