import React from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grow from '@material-ui/core/Grow';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';

import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Header from '../../component/header';

import { Empresas } from '../../entity/empresas';
import { Centros } from '../../entity/centros';
import { Eventos } from '../../entity/eventos';
import { Usuarios } from '../../entity/usuarios';

import '../../css/style.css';
import moment from 'moment';

import PopUpInfo from '../../component/popUpInfo';
import PopUpConfirmacion from '../../component/popUpConfirmacion';
import OrdenCompraAsignarCategoriaEvento from './ordenCompraAsignarCategoriaEvento';

class CrearEntradas extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      idempresas: '',
      idcentros: '',
      ideventos: '',
      fecha: moment().format('YYYY-MM-DD'),
      cantidad: 0,
      hora: '',
      generico: '',

      empresas: [],
      centros: [],
      eventos: [],

      rowsPerPage: 10,
      page: 0,
      data: [],
      ordenar: [],

      buscarEmpresa: '',
      buscarCentro: '',
      buscarEvento: '',
      buscarFecha: moment().format('YYYY-MM-DD'),
      buscarGenerico: '',

      loading: false,
      editando: false,
      error: false,
      errorText: '',

      showConfirmation: false,
      elementSeleccionado: {},

      dataChanged: [],

      showCategorias: false
    }

    this.onChangeInput = this.onChangeInput.bind(this);
    this.onGenerar = this.onGenerar.bind(this);

    this.cargarSelect = this.cargarSelect.bind(this);
    this.cargarEmpresas = this.cargarEmpresas.bind(this);
    this.cargarCentros = this.cargarCentros.bind(this);
    this.cargarEventos = this.cargarEventos.bind(this);

    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.cargarOrdenCompra = this.cargarOrdenCompra.bind(this);
    this.onOrdenar = this.onOrdenar.bind(this);

    this.onHideConfirmation = this.onHideConfirmation.bind(this);
    this.onActivarOrdenCompra = this.onActivarOrdenCompra.bind(this);

    this.editingRow = this.editingRow.bind(this);    
    this.onGuardarOrden = this.onGuardarOrden.bind(this);
  }

  componentDidMount() {
    this.cargarSelect();

    this.cargarOrdenCompra({ fecha: this.state.buscarFecha });
  }

  handleChangePage(newPage) {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage(event) {
    this.setState({ rowsPerPage: parseInt(event.target.value), page: 0 });
  };


  onChangeInput(event) {
    this.setState({ [event.target.name]: event.target.value }, () => {

      this.cargarOrdenCompra({
        empresa: this.state.buscarEmpresa,
        centro: this.state.buscarCentro,
        evento: this.state.buscarEvento,
        fecha: this.state.buscarFecha,
        generico: this.state.buscarGenerico
      });

    });

  }



  async cargarSelect() {

    this.setState({ loading: true });

    await this.cargarEmpresas();
    await this.cargarCentros();
    await this.cargarEventos();

    this.setState({ loading: false });
  }


  async cargarEmpresas() {
    Empresas.getEmpresas()
      .then(
        data => {
          if (data.records) {
            this.setState({ empresas: data.records });
          }
        }
      );
  }

  async cargarCentros() {
    Centros.getCentros()
      .then(
        data => {
          if (data.records) {
            this.setState({ centros: data.records });
          }
        }
      );
  }

  async cargarEventos() {
    Eventos.getEventos()
      .then(
        data => {
          if (data.records) {
            this.setState({ eventos: data.records });
          }
        }
      );
  }

  async cargarOrdenCompra(formulario) {
    Usuarios.getOrdenCompra(formulario)
      .then(
        data => {
          if (data.records) {
            this.setState({ data: data.records });
          }
        }
      );
  }

  onGenerar() {

    this.setState({ loading: true });

    // todos los camos son obligatorios menos el campo "generico"
    if (
      this.state.idempresas.length === 0
      || this.state.idcentros.length === 0
      || this.state.ideventos.length === 0
      || this.state.hora.length === 0
      || this.state.cantidad === 0
    ) {
      this.setState({ error: true, loading: false, errorText: 'Todos los campos son obligatorios' });
    }

    let formulario = {
      idempresas: this.state.idempresas,
      idcentros: this.state.idcentros,
      ideventos: this.state.ideventos,
      fecha: this.state.fecha,
      cantidad: this.state.cantidad,
      hora: this.state.hora,
      generico: this.state.generico
    }

    Usuarios.generarCodigos(formulario)
      .then(
        valid => {
          this.cargarOrdenCompra({
            empresa: this.state.buscarEmpresa,
            centro: this.state.buscarCentro,
            evento: this.state.buscarEvento,
            fecha: this.state.fecha
          });
          
          this.setState({ errorText: valid.message, error: true, loading: false, buscarFecha: this.state.fecha });
        }
      );


  }

  onOrdenar(por) {

    if (por) {

      let tmpOrdenar = this.state.ordenar;
      if (!tmpOrdenar[por]) {
        tmpOrdenar[por] = 'asc';
      }
      else {
        tmpOrdenar[por] = tmpOrdenar[por] === 'asc' ? 'desc' : 'asc';
      }

      let datosOrdenados = this.state.data;

      // si quieremos ordenar la fecha de vento, entonces se hace un tratamiento especial, ya que es una fecha
      tmpOrdenar[por] === 'asc' ?
        datosOrdenados.sort((a, b) => (a[por] > b[por]) ? 1 : ((b[por] > a[por]) ? -1 : 0))
        : datosOrdenados.sort((a, b) => (a[por] < b[por]) ? 1 : ((b[por] < a[por]) ? -1 : 0));

      this.setState({ data: datosOrdenados, ordenar: tmpOrdenar });
    }
  }

  onHideConfirmation() {
    this.setState({ error: false, showConfirmation: false });
  }

  onActivarOrdenCompra(element) {

    let formulario = {
      idordencompra: element.idordencompra,

      activo: element.activo === '1' ? '0' : '1'
    };

    let message = element.activo === '1' ? 'Se ha desactivado la Orden de Compra' : 'Se ha activado la Orden de Compra';


    this.setState({ error: true, errorText: message, showConfirmation: false });

    Usuarios.saveOrdenCompra(formulario)
      .then(() => {
        this.cargarOrdenCompra({
          empresa: this.state.buscarEmpresa,
          centro: this.state.buscarCentro,
          evento: this.state.buscarEvento,
          fecha: this.state.buscarFecha,
          generico: this.state.buscarGenerico
        }).then(() => {
          this.setState({ error: true, errorText: message, showConfirmation: false });
        });


      });

  }


  editingRow(field, event, element) {

    // guardamos el elmento en una variable temporal
    let item = element;

    // obtnego el ID de la orden de compra
    let idordencompra = item.idordencompra;

    // asigno el nuevo valor
    item[field] = event.target.value;

    // guardamos en una variable temporal el array de cambios
    let tmpChanged = this.state.dataChanged;

    let indice = tmpChanged.findIndex(x => x.idordencompra === idordencompra);
    // si no se encuentra, lo añado
    if (indice < 0) {
      tmpChanged.push(item);
    }
    else {
      tmpChanged[indice] = item;
    }

    // actualizamos la lista temporal de cambios
    this.setState({ dataChanged: tmpChanged });
  }


  onGuardarOrden() {

    if (this.state.dataChanged.length > 0) {
      this.state.dataChanged.forEach((element, index) => {

        let formulario = {
          idordencompra: element.idordencompra,
          cantidad: element.cantidad
        };

        Usuarios.saveOrdenCompra(formulario)
          .then(() => {
            this.cargarOrdenCompra({
              empresa: this.state.buscarEmpresa,
              centro: this.state.buscarCentro,
              evento: this.state.buscarEvento,
              fecha: this.state.buscarFecha,
              generico: this.state.buscarGenerico
            }).then(() => {
              this.setState({ error: true, errorText: "Cambios realizados", showConfirmation: false, dataChanged: [] });
            });


          });

      });
    }
  }

  render() {

    const AlertloginFailed = <PopUpInfo messageInfo={this.state.errorText} onClose={() => { this.setState({ error: false, errorText: '' }) }} />

    const emptyRows = 2;

    const Buscador =
      (
        <Box>
          <Typography align="center" variant="h5">Opciones de búsqueda</Typography>

          <Box display='flex' marginBottom={1}>
            <TextField
              fullWidth
              style={{ margin: 5 }}
              margin="dense" size='small' variant='outlined'
              id="buscarGenerico" label="Código Genérico" name="buscarGenerico"
              value={this.state.buscarGenerico}
              onChange={this.onChangeInput}
            />
            <TextField
              fullWidth
              style={{ margin: 5 }}
              margin="dense" size='small' variant='outlined'
              id="buscarEmpresa" label="Empresa" name="buscarEmpresa"
              value={this.state.buscarEmpresa}
              onChange={this.onChangeInput}
            />
            <TextField
              fullWidth
              style={{ margin: 5 }}
              margin="dense" size='small' variant='outlined'
              id="buscarCentro" label="Centro" name="buscarCentro"
              value={this.state.buscarCentro}
              onChange={this.onChangeInput}
            />
            <TextField
              fullWidth
              style={{ margin: 5 }}
              margin="dense" size='small' variant='outlined'
              id="buscarEvento" label="Evento" name="buscarEvento"
              value={this.state.buscarEvento}
              onChange={this.onChangeInput}
            />

            <TextField
              fullWidth
              id="buscarFecha"
              label="Fecha"
              name="buscarFecha"
              autoComplete="buscarFecha"
              size='small'
              value={this.state.buscarFecha}
              type='date'
              variant='outlined'
              InputLabelProps={{ shrink: true }}
              style={{ margin: 5 }}
              margin='dense'
              onChange={this.onChangeInput}
            />

          </Box>

          <Button
            fullWidth
            variant="contained"
            color="primary"
            margin='dense'
            onClick={this.onGuardarOrden}
          >
            Guardar
          </Button>

        </Box>
      );


    return (
      <div>

        <Header title="Crear Entradas" history={this.props.history}></Header>

        <div style={{ height: 40 }}></div>

        <Container maxWidth="xs">

          <Grow in={true} timeout={500}>
            <Box className="box-blanco">

              <Typography align="center" variant="h5">Crear</Typography>

              <div style={{ height: 20 }}></div>

              <FormControl variant="outlined" fullWidth size="small" margin='normal' required>
                <InputLabel>Empresa</InputLabel>
                <Select
                  labelId="idempresas"
                  id="idempresas"
                  name="idempresas"
                  value={this.state.idempresas}
                  label="Empresa"
                  onChange={this.onChangeInput}
                >
                  <MenuItem value="">
                    <em>Seleccionar</em>
                  </MenuItem>
                  {
                    this.state.empresas.map((element, index) => {
                      return (<MenuItem value={element.idempresas} key={element.idempresas}>{element.nombre}</MenuItem>);
                    })
                  }
                </Select>
              </FormControl>

              <FormControl variant="outlined" fullWidth size="small" margin='normal' required>
                <InputLabel>Centros</InputLabel>
                <Select
                  labelId="idcentros"
                  id="idcentros"
                  name="idcentros"
                  value={this.state.idcentros}
                  label="Centro"
                  onChange={this.onChangeInput}
                >
                  <MenuItem value="">
                    <em>Seleccionar</em>
                  </MenuItem>
                  {
                    this.state.centros.map((element, index) => {
                      return (<MenuItem value={element.idcentros} key={element.idcentros}>{element.nombre}</MenuItem>);
                    })
                  }
                </Select>
              </FormControl>

              <FormControl variant="outlined" fullWidth size="small" margin='normal' required>
                <InputLabel>Evento</InputLabel>
                <Select
                  labelId="ideventos"
                  id="ideventos"
                  name="ideventos"
                  value={this.state.ideventos}
                  label="Evento"
                  onChange={this.onChangeInput}
                >
                  <MenuItem value="">
                    <em>Seleccionar</em>
                  </MenuItem>
                  {
                    this.state.eventos.map((element, index) => {
                      return (<MenuItem value={element.ideventos} key={index}>{element.nombre}{element.categoria.length > 0 ? ` (Categoría ${element.categoria})` : ''}</MenuItem>);
                    })
                  }
                </Select>
              </FormControl>

              <Box display="flex" justifyContent="center">


                <TextField
                  required
                  fullWidth
                  id="fecha"
                  label="Fecha"
                  name="fecha"
                  autoComplete="fecha"
                  size='small'
                  value={this.state.fecha}
                  type='date'
                  variant='outlined'
                  InputLabelProps={{ shrink: true }}
                  margin='normal'
                  onChange={this.onChangeInput}
                />

                <TextField
                  required
                  id="cantidad"
                  label="Entradas"
                  name="cantidad"
                  autoComplete="cantidad"
                  size='small'
                  value={this.state.cantidad}
                  type='number'
                  variant='outlined'
                  style={{ marginLeft: 10 }}
                  margin='normal'
                  onChange={this.onChangeInput}
                />

              </Box>

              <TextField
                required
                fullWidth
                id="hora"
                label="Hora"
                name="hora"
                autoComplete="hora"
                size='small'
                value={this.state.hora}
                variant='outlined'
                InputLabelProps={{ shrink: true }}
                margin='normal'
                onChange={this.onChangeInput}
              />

              <TextField
                required
                fullWidth
                id="generico"
                label="Código Genérico"
                name="generico"
                autoComplete="generico"
                size='small'
                value={this.state.generico}
                variant='outlined'
                InputLabelProps={{ shrink: true }}
                margin='normal'
                onChange={this.onChangeInput}
              />

              <div style={{ height: 20 }}></div>

              <Button
                fullWidth
                variant="contained"
                disabled={this.state.loading}
                color="primary"
                onClick={this.onGenerar}
              >
                Generar
              </Button>


            </Box>

          </Grow>

          <div style={{ height: 20 }}></div>

          {
            this.state.showCategorias
              ? <OrdenCompraAsignarCategoriaEvento item={this.state.elementSeleccionado} onClose={() => this.setState({showCategorias: false, elementSeleccionado: {} })} />
              : <div></div>
          }


          <div style={{ height: 20 }}></div>


          {this.state.error === true ? AlertloginFailed : ''}

        </Container>

        <div style={{ height: 20 }}></div>

        <Container maxWidth='lg'>

          {Buscador}

          <div style={{ height: 20 }}></div>

          <TableContainer component={Paper}>
            <Table size='small'>
              <TableHead>
                <TableRow>

                  <TableCell key={'generico'}>
                    <TableSortLabel
                      direction={this.state.ordenar['generico'] ? this.state.ordenar['generico'] : 'asc'}
                      onClick={() => this.onOrdenar('generico')}>
                      {'Código Genérico'}
                    </TableSortLabel>
                  </TableCell>

                  <TableCell key={'empresa'}>
                    <TableSortLabel
                      direction={this.state.ordenar['empresa'] ? this.state.ordenar['empresa'] : 'asc'}
                      onClick={() => this.onOrdenar('empresa')}>
                      {'Empresa'}
                    </TableSortLabel>
                  </TableCell>

                  <TableCell key={'centro'}>
                    <TableSortLabel
                      direction={this.state.ordenar['centro'] ? this.state.ordenar['centro'] : 'asc'}
                      onClick={() => this.onOrdenar('centro')}>
                      {'Centro'}
                    </TableSortLabel>
                  </TableCell>

                  <TableCell key={'evento'}>
                    <TableSortLabel
                      direction={this.state.ordenar['evento'] ? this.state.ordenar['evento'] : 'asc'}
                      onClick={() => this.onOrdenar('evento')}>
                      {'Evento'}
                    </TableSortLabel>
                  </TableCell>

                  <TableCell key={'fecha'}>
                    <TableSortLabel
                      direction={this.state.ordenar['fecha'] ? this.state.ordenar['fecha'] : 'asc'}
                      onClick={() => this.onOrdenar('fechaeventooriginal')}>
                      {'Fecha Función'}
                    </TableSortLabel>
                  </TableCell>

                  <TableCell key={'cantidad'}>
                    {'Cantidad'}
                  </TableCell>

                  <TableCell key={'activo'}>
                    <TableSortLabel
                      direction={this.state.ordenar['activo'] ? this.state.ordenar['activo'] : 'asc'}
                      onClick={() => this.onOrdenar('activo')}>
                      {'Activo'}
                    </TableSortLabel>
                  </TableCell>

                  <TableCell key={'show'}>
                    {'Categoría'}
                  </TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {(this.state.rowsPerPage > 0
                  ? this.state.data.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                  : this.state.data
                ).map((element, index) => (
                  <Grow in={true} timeout={index < 50 ? 100 * index : 0} key={element.idordencompra} style={{ backgroundColor: (this.state.dataChanged.findIndex(x => x.idordencompra === element.idordencompra) >= 0 ? '#9E9E9E' : '') }}>
                    <TableRow>
                      <TableCell>{element.generico}</TableCell>
                      <TableCell>{element.empresa}</TableCell>
                      <TableCell>{element.centro}</TableCell>
                      <TableCell>{element.evento}</TableCell>
                      <TableCell>{element.fecha}</TableCell>

                      <TableCell>
                        <TextField
                          style={{ width: 60 }}
                          size='small'
                          value={this.state.dataChanged.findIndex(x => x.idordencompra === element.idordencompra) >= 0 ? this.state.dataChanged[this.state.dataChanged.findIndex(x => x.idordencompra === element.idordencompra)].cantidad || element.cantidad : element.cantidad}
                          variant='outlined'
                          InputLabelProps={{ shrink: true }}
                          margin='dense'
                          onChange={(e) => this.editingRow('cantidad', e, element)}
                        />
                      </TableCell>


                      <TableCell>
                        <IconButton onClick={() => this.setState({ showConfirmation: true, elementSeleccionado: element })}>
                          {element.activo === '1'
                            ? <ThumbUpIcon />
                            :
                            <ThumbDownIcon />
                          }
                        </IconButton>
                      </TableCell>

                      <TableCell>
                        <IconButton onClick={() => this.setState({ showCategorias: true, elementSeleccionado: element })} >
                          <VisibilityIcon />
                        </IconButton>
                      </TableCell>

                    </TableRow>
                  </Grow>
                ))}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 25 * emptyRows }}>
                    <TableCell colSpan={8} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 20, 50, { label: 'All', value: -1 }]}
                    colSpan={8}
                    count={this.state.data.length}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onChangePage={(e, page) => this.handleChangePage(page)}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>

        </Container>

        <div style={{ height: 10 }}></div>

        {
          this.state.showConfirmation === true ?
            (<PopUpConfirmacion messageInfo={this.state.elementSeleccionado.activo === '1' ? '¿Desactivar la Orden de Compra?' : '¿Activar la Orden de Compra?'}
              onOk={() => this.onActivarOrdenCompra(this.state.elementSeleccionado)}
              onCancel={this.onHideConfirmation} />)
            : ''
        }



      </div>
    );
  }

}

export default CrearEntradas;
