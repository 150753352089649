
export class Constantes {
    //static API_URL = "http://192.168.102.77:5510/api/";
    //static API_URL = "http://localhost:8080/api/";
    static API_URL = "https://musical-pass.com/api/";
    //static API_URL = "https://test.musical-pass.com/api/";

    static getAuthHeaders() {

        let token = '';

        if (localStorage.getItem('userLogged')
            && JSON.parse(localStorage.getItem('userLogged')).token) {
            token = JSON.parse(localStorage.getItem('userLogged')).token;
        }
        else {
            token = 'no-token';
        }

        return {
            "Authorization": "Bearer " + token,
            'Content-Type': 'application/json'
        }
    };

    static getAuthHeadersUpload() {

        let token = '';

        if (localStorage.getItem('userLogged')
            && JSON.parse(localStorage.getItem('userLogged')).token) {
            token = JSON.parse(localStorage.getItem('userLogged')).token;
        }
        else {
            token = 'no-token';
        }

        return {
            "Authorization": "Bearer " + token
        }
    };

    static getUserLogged() {
        if (localStorage.getItem('userLogged')
            && JSON.parse(localStorage.getItem('userLogged')).token) {
            return JSON.parse(localStorage.getItem('userLogged'));
        }
        else {
            return false;
        }
    }
}