import { Constantes } from '../util/constantes';


export const Centros = {
    guardar,
    getCentros,
    borrar
};


async function guardar(formulario) {

    /*
    var data = new FormData();
    data.append('imagen', formulario.imagen);
    data.append('nombre', formulario.nombre);
    data.append('idcentros', formulario.idcentros);
*/

    const requestOptions = {
        method: 'post',
        headers: Constantes.getAuthHeaders(),
        body: JSON.stringify(formulario),
    };

    const response = await fetch(`${Constantes.API_URL}centros/save.php`, requestOptions)
        .then((data) => {

            if (data.status !== 200) {
                return data.json()
            }
            else {
                return true;
            }
        })
        .catch((error) => {
            console.log(error);
            return { message: error };
        });

    return response;

}

async function getCentros() {

    const requestOptions = {
        method: 'get',
        headers: Constantes.getAuthHeaders()
    };

    const response = await fetch(`${Constantes.API_URL}centros/read.php`, requestOptions)
        .then((data) => {
            return data.json()
        })
        .catch((error) => {
            console.log(error);
            return { message: error };
        });

    return response;

}

async function borrar(id) {

    const requestOptions = {
        method: 'post',
        headers: Constantes.getAuthHeaders(),
        body: JSON.stringify({id: id}),
    };

    const response = await fetch(`${Constantes.API_URL}centros/delete.php`, requestOptions)
        .then((data) => {

            if (data.status !== 200) {
                return data.json()
            }
            else {
                return true;
            }
        })
        .catch((error) => {
            console.log(error);
            return { message: error };
        });

    return response;

}
