import React from 'react';

import Button from '@material-ui/core/Button';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { Dialog, DialogContent } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

import Box from '@material-ui/core/Box';
import Grow from '@material-ui/core/Grow';
import Typography from '@material-ui/core/Typography';

import ShowDataSheet from '../../component/ShowDataSheet/ShowDataSheet';

import '../../css/style.css';
import moment from 'moment';

import PopUpMasInfoEvento from '../../component/popUpMasInfoEvento';

class EventoView extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      entradasSeleccionadas: 0,
      maximasEntradas: parseInt(this.props.entradas.entradasAsignadas) - parseInt(this.props.entradas.entradasSeleccionadas),
      fechaSeleccionada: undefined,
      fecha: undefined,
    }

    this.seleccionar = this.seleccionar.bind(this);
  }

  seleccionar() {
    // debemos seleccionar una fecha
    if (this.state.fecha === undefined) {
      this.props.setError("Seleccione una fecha de evento");
      return;
    };

    // no podemos pedir mas entradas de las que tenemos pendientes
    if (parseInt(this.state.entradasSeleccionadas) > this.state.maximasEntradas) {
      this.props.setError("Como máximo puede seleccionar " + this.state.maximasEntradas + " entradas");
      return;
    }


    let formulario = {
      ideventos: this.props.item.ideventos,
      idcentros: this.props.item.idcentros,
      fechaEvento: this.state.fechaSeleccionada.fechaEvento,
      hora: this.state.fechaSeleccionada.hora,
      entradas: this.state.entradasSeleccionadas,
      ideventosCategorias: this.props.entradas.ideventosCategorias
    }

    this.props.onClick(formulario)
  }


  render() {

    return (
      <Grow in={true} style={{ width: '30%', marginBottom: 20, minWidth:'300px' }}>

        <Box display='flex' borderRadius={15} bgcolor='white' p={1} width={1} flexWrap={'wrap'} justifyContent={'center'}>

          < Dialog open={this.state.showMasInfo} onClose={() => this.setState({ showMasInfo: false })}>
            <DialogContent style={{ borderRadius: 15 }}>
              <ShowDataSheet info={this.state.masInfoElement} />
            </DialogContent>
          </Dialog>

          <Box width={'25%'} minWidth={'150px'} padding={1} display='flex' justifyContent='flex-start' alignItems={'center'} flexDirection={'column'}>
            <img src={this.props.item.eventoimagen} alt={this.props.item.nombre} className="user-list__img" />          

            <PopUpMasInfoEvento id={this.props.item.jsonDataID} />
          </Box>

          <Box width={'70%'} minWidth={'300px'} padding={1} display='flex' justifyContent='flex-start'>
            <Box display='flex' flexDirection='column' alignItems='flex-start' width={1}>
              <Typography variant="h5">{this.props.item.evento}</Typography>
              <Typography variant="body1">{this.props.item.centro}</Typography>
              <Typography variant="body2">{this.props.item.direccion}</Typography>

              <div style={{ height: 10 }}></div>

              <TextField
                margin="dense"
                variant='outlined'
                required
                fullWidth
                autoFocus
                id="seleccionar"
                label="Entradas"
                name="seleccionar"
                size='small'
                type='number'
                InputProps={{ inputProps: { min: 1, max: this.state.maximasEntradas } }}
                value={this.state.entradasSeleccionadas}
                onChange={(e) => this.setState({ entradasSeleccionadas: e.target.value })}
              />

              <FormControl variant="outlined" fullWidth size="small" margin='normal' required>
                <InputLabel>Seleccione fecha</InputLabel>
                <Select
                  labelId="fecha"
                  id="fecha"
                  name="fecha"
                  value={this.state.fecha}
                  label="Fecha"
                  onChange={(e) => this.setState({ fechaSeleccionada: e.target.value, fecha: e.target.value.fechaEvento })}
                >
                  {
                    this.props.detalle.map((element, index) => {
                      return (<MenuItem value={element} key={index}>
                        <Box display={'flex'}>
                          <Typography>{`${moment(element.fechaEvento).format('DD/MM/YYYY')}`}</Typography>
                          <Typography style={{ fontWeight: 'bold', marginLeft: 5 }}>{`${element.hora}`}</Typography>
                        </Box>
                      </MenuItem>);
                    })
                  }
                </Select>
              </FormControl>

              <Button fullWidth variant="contained" color="primary" onClick={this.seleccionar}>Canjear Entradas</Button>

            </Box>
          </Box>

        </Box>

      </Grow>
    );
  }

}

export default EventoView;
