import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import "./Advertisement.css";

const Advertisement = () => {

	return (
		<div className="mp-advertisement">
			<div className="mp-advertisement-text">
				<h3 className="bold">El pasaporte que une</h3>
				<h1 className="bold">empresa <span style={{ color: 'black' }}>y</span> cultura</h1>
				<p><span className='bold'>musical pass</span> es la forma más cómoda de premiar con cultura y obtener ventajas y descuentos para las experiencias musicales del momento.</p>
			</div>
			<div className='mp-advertisement-right'>
				<img src="/images/entradas.png" alt="Musical Pass" style={{ zIndex: 2 }} />
				<Box marginTop={'50px'} position={'relative'}>
					<Box width={1} justifyContent={'center'} display={'flex'} flexDirection={'column'}>
						<Typography variant='h6'>¿Has recibido tus entradas?</Typography>
						<Typography align='center'>Regístrate aquí y disfruta de tu regalo</Typography>
					</Box>
					<Link to={'/main'}>
						<Box bgcolor={'white'} borderRadius={15} display={'flex'} className='mp-advertisement-right-star' justifyContent={'right'} alignItems={'center'}>
							<Typography style={{ fontWeight: 'bold' }}>CANJEA TUS ENTRADAS</Typography>
							<img src="/images/estrella.png" alt="Musical Pass" />
						</Box>
					</Link>
				</Box>
			</div>
		</div>
	)
}

export default Advertisement
