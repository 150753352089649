import React from 'react';
import "./Modal.css";

const Modal = ({children, isOpen, closeModal}) => {
	const handleClickModalContainer = e => e.stopPropagation();
	return (
		<div className={`modal ${isOpen && "is-open"}`} onClick={closeModal}>
			<article className="modal-container" onClick={handleClickModalContainer}>
				<span onClick={closeModal} className="modal-close">&times;</span>
				{children}
			</article>
		</div>
	)
}

export default Modal
