import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import "./MPfooter.css";

class MPfooter extends React.Component {

  render() {
    return (
      <footer className="mp-footer">
        <div className="mp-footer__logo">
          <span>
            <img src="/images/Logo-M-white.png" alt="" title="Musical Pass logo" />
          </span>
        </div>
        <div className="mp-footer__info">
          <div className="mp-footer__links">
            <div className="mp-footer__links_site">
              <List>
                <ListItem button component="a" href="main">
                  <ListItemText primary={"musical pass"} />
                </ListItem>
              </List>
            </div>
            <div className="mp-footer__links_contact">
              <List>
                <ListItem>
                  <ListItemText primary={"Contacto"} />
                </ListItem>
                <ListItem>
                  <ListItemText primary={"musicalpass@musicalpass.com"} />
                </ListItem>
                <ListItem>
                  <ListItemText primary={"Telf: 666 564 666"} />
                </ListItem>
              </List>
            </div>
          </div>
          <div className="mp-footer__web">
            <div className="mp-footer__web_rrss">
              <List>
                <ListItem>
                  <ListItemText primary={"Síguenos en redes sociales"} />
                </ListItem>
              </List>
              <div className="mp-footer__web_rrss-links">
                <List>
                  <ListItem button component="a" href="#">
                    <ListItemIcon><FacebookIcon /></ListItemIcon>
                  </ListItem>
                  <ListItem button component="a" href="#">
                    <ListItemIcon><TwitterIcon /></ListItemIcon>
                  </ListItem>
                  <ListItem button component="a" href="#">
                    <ListItemIcon><InstagramIcon /></ListItemIcon>
                  </ListItem>
                </List>
              </div>
            </div>
            <div className="mp-footer__web_download">
              <List>
                <ListItem>
                  <ListItemText primary={"Descarga con:"} />
                </ListItem>
              </List>
              <div className="mp-footer__web_download-links">
                <List>
                  <ListItem button component="a" href="wallet-google">
                    <img src="/images/wallet-google.png" alt="" />
                  </ListItem>
                  <ListItem button component="a" href="wallet-apple">
                    <img src="/images/wallet-apple.png" alt="" />
                  </ListItem>
                </List>
              </div>
            </div>
          </div>
        </div>
        <div className="mp-footer__notice">
          <p>¡IMPORTANTE! El acto se llevará a cabo con el aforo permitido, bajo estrictas medidas de seguridad recomendadas para el COVID-19. Las butacas mantendrán la distancia de seguridad entre ellas y se tomarán las medidas higiénicas y de desinfección pertinentes antes y durante el espectáculo.</p>
        </div>
      </footer>
    );
  }
}

export default MPfooter;