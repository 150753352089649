import React from 'react';


import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';

import { Usuarios } from '../../entity/usuarios';
import { Divider } from '@material-ui/core';

import PopUpInfo from '../../component/popUpInfo';
import { Link } from 'react-router-dom';

class RegistroRealizado extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="login">

        <div style={{ height: 60 }}></div>

        <Container maxWidth="md">

          <Box display='flex' justifyContent='center'>
            <img src="./images/musical_pass.png" width="400px" alt="Musical Pass" />
          </Box>

          <div style={{ height: 50 }}></div>

          <Box display='flex' justifyContent='center'>
            <Typography align='center' variant="h4">Le hemos enviado un email de confirmación</Typography>
          </Box>

          <div style={{ height: 20 }}></div>

          <Box display='flex' justifyContent='center'>
            <Typography align='center' variant="h4">Para finalizar el proceso de registro confirme su correo electrónico pulsando en el enlace enviado a su correo.</Typography>
          </Box>

          <div style={{ height: 50 }}></div>

          <Box display='flex' justifyContent='center'>
            <Button variant='contained' color='secondary' component={Link} to="/login">Ir al login</Button>
          </Box>

        </Container>
      </div >
    );
  }
}

export default RegistroRealizado;