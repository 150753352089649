import React from 'react';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';

import Button from '@material-ui/core/Button';
import { Avatar } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import Typography from '@material-ui/core/Typography';

import Header from '../../component/header';
import EditarEmpresa from './editarEmpresa';
import PopUpInfo from '../../component/popUpInfo';

import { Empresas } from '../../entity/empresas';


import '../../css/style.css';

class GestionEmpresas extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      rowsPerPage: 10,
      page: 0,
      ordenar: [],

      idempresas: '',
      nombre: '',
      nombrecorto: '',
      telefono: '',
      email: '',
      cif: '',
      direccion: '',
      poblacion: '',
      imagen: {},

      data: [],
      seleccionado: {},

      loading: false,
      editando: false,
      error: false,
      errorText: ''
    }

    // gestionar la tabla
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.onOrdenar = this.onOrdenar.bind(this);

    this.onChangeInput = this.onChangeInput.bind(this);
    this.onSelectFile = this.onSelectFile.bind(this);
    this.onGuardar = this.onGuardar.bind(this);
    this.onNuevo = this.onNuevo.bind(this);
    this.onCargar = this.onCargar.bind(this);
    this.onBack = this.onBack.bind(this);
    this.onSeleccionarItem = this.onSeleccionarItem.bind(this);
    this.onBorrarItem = this.onBorrarItem.bind(this);
  }

  componentDidMount() {
    this.onCargar();
  }

  handleChangePage(newPage) {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage(event) {
    this.setState({ rowsPerPage: parseInt(event.target.value), page: 0 });
  };

  onOrdenar(por) {

    if (por) {

      let tmpOrdenar = this.state.ordenar;
      if (!tmpOrdenar[por]) {
        tmpOrdenar[por] = 'asc';
      }
      else {
        tmpOrdenar[por] = tmpOrdenar[por] === 'asc' ? 'desc' : 'asc';
      }

      let datosOrdenados = this.state.data;

      // si quieremos ordenar la fecha de vento, entonces se hace un tratamiento especial, ya que es una fecha
      tmpOrdenar[por] === 'asc' ?
        datosOrdenados.sort((a, b) => (a[por] > b[por]) ? 1 : ((b[por] > a[por]) ? -1 : 0))
        : datosOrdenados.sort((a, b) => (a[por] < b[por]) ? 1 : ((b[por] < a[por]) ? -1 : 0));

      this.setState({ data: datosOrdenados, ordenar: tmpOrdenar });
    }
  }


  onChangeInput(event) {
    this.setState({ [event.target.id]: event.target.value });
  }

  onCargar() {

    this.setState({ loading: true });

    Empresas.getEmpresas()
      .then(
        data => {
          console.log(data);
          if (data.records) {
            this.setState({ data: data.records, loading: false, editando: false });
          }
        }
      );
  }

  onGuardar(item) {

    /*
    this.setState({ loading: true });

    let formulario = {
      idempresas: item ? item.idempresas : this.state.idempresas,
      nombre: item ? item.nombre : this.state.nombre,
      nombrecorto: item ? item.nombrecorto : this.state.nombrecorto,
      telefono: item ? item.telefono : this.state.telefono,
      email: item ? item.email : this.state.email,
      cif: item ? item.cif : this.state.cif,
      direccion: item ? item.direccion : this.state.direccion,
      poblacion: item ? item.poblacion : this.state.poblacion,
      imagen: item ? item.imagen : this.state.imagen
    }
*/
    Empresas.guardar(item)
      .then(
        valid => {
          if (valid === true) {
            this.onCargar();
          }
          else {
            this.setState({ error: true, loading: false, errorText: valid.message });
          }
        }
      );

  }


  onSelectFile(event) {
    console.log(event.target.files[0]);
    this.setState({ imagen: event.target.files[0] });
  }

  onSeleccionarItem(element) {
    this.setState({ seleccionado: element, editando: true, error: false });
  }

  onNuevo() {
    let item = {
      idemresas: '',
      nombre: '',
      nombrecorto: '',
      telefono: '',
      email: '',
      cif: '',
      direccion: '',
      poblacion: '',
      imagen: {}
    }
    this.setState({ seleccionado: item, editando: true });
  }

  onBack() {
    this.setState({ editando: false });
  }

  onBorrarItem(item) {

    this.setState({ loading: true });

    Empresas.borrar(item.idempresas)
      .then(
        valid => {
          if (valid === true) {
            this.onCargar();
          }
          else {
            this.setState({ error: true, loading: false, errorText: valid.message });
          }
        }
      );
  }


  render() {

    const emptyRows = 1;

    const AlertloginFailed = <PopUpInfo messageInfo={this.state.errorText} onClose={() => { this.setState({ error: false, errorText: '' }) }} />

    const Registros =

      <Container maxWidth='lg'>

        <Box>

          <Button
            fullWidth
            variant="contained"
            disabled={this.state.loading}
            color="primary"
            onClick={this.onNuevo}
          >
            Nuevo
          </Button>

          <div style={{ height: 20 }}></div>

          <Box style={{ backgroundColor: 'black' }} borderRadius={5}>

            <TableContainer component={Paper}>
              <Table size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell key={'acciones'}>
                      {''}
                    </TableCell>

                    <TableCell key={'idempresas'}>
                      <TableSortLabel
                        direction={this.state.ordenar['nombre'] ? this.state.ordenar['nombre'] : 'asc'}
                        onClick={() => this.onOrdenar('nombre')}>
                        {'Empresa'}
                      </TableSortLabel>
                    </TableCell>

                    <TableCell key={'telefono'}>
                      <TableSortLabel
                        direction={this.state.ordenar['telefono'] ? this.state.ordenar['telefomo'] : 'asc'}
                        onClick={() => this.onOrdenar('telefono')}>
                        {'Telefono'}
                      </TableSortLabel>
                    </TableCell>

                    <TableCell key={'email'}>
                      <TableSortLabel
                        direction={this.state.ordenar['email'] ? this.state.ordenar['email'] : 'asc'}
                        onClick={() => this.onOrdenar('email')}>
                        {'Email'}
                      </TableSortLabel>
                    </TableCell>

                    <TableCell key={'cif'}>
                      <TableSortLabel
                        direction={this.state.ordenar['cif'] ? this.state.ordenar['cif'] : 'asc'}
                        onClick={() => this.onOrdenar('cif')}>
                        {'cif'}
                      </TableSortLabel>
                    </TableCell>

                    <TableCell key={'direccion'}>
                      <TableSortLabel
                        direction={this.state.ordenar['direccion'] ? this.state.ordenar['direccion'] : 'asc'}
                        onClick={() => this.onOrdenar('direccion')}>
                        {'Direccion'}
                      </TableSortLabel>
                    </TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {(this.state.rowsPerPage > 0
                    ? this.state.data.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                    : this.state.data
                  ).map((element, index) => (
                    <Grow in={true} timeout={index < 50 ? 100 * index : 0} key={element.ideventos}>
                      <TableRow>
                        <TableCell>
                          <Box display='flex' alignItems='center'>
                            <IconButton onClick={() => this.onSeleccionarItem(element)} title="Liberar Codigo">
                              <EditIcon />
                            </IconButton>
                            <IconButton onClick={() => this.onBorrarItem(element)} title="Editar registro">
                              <DeleteIcon />
                            </IconButton>

                            <Avatar
                              alt={element.nombre}
                              src={element.imagen}
                            />

                          </Box>
                        </TableCell>

                        <TableCell>{element.nombre}</TableCell>
                        <TableCell>{element.telefono}</TableCell>
                        <TableCell>{element.email}</TableCell>
                        <TableCell>{element.cif}</TableCell>
                        <TableCell>{element.direccion}</TableCell>

                      </TableRow>
                    </Grow>
                  ))}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 25 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[10, 20, 50, { label: 'All', value: -1 }]}
                      colSpan={6}
                      count={this.state.data.length}
                      rowsPerPage={this.state.rowsPerPage}
                      page={this.state.page}
                      onChangePage={(e, page) => this.handleChangePage(page)}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>

          </Box>
        </Box>

      </Container>;


    return (
      <div>

        <Header title="Empresas" history={this.props.history}></Header>

        <div style={{ height: 40 }}></div>



        <Typography align="center" variant="h5">Gestión de Empresas</Typography>

        <div style={{ height: 20 }}></div>

        {this.state.editando ? <EditarEmpresa item={this.state.seleccionado} onGuardar={this.onGuardar} onBack={this.onBack} /> : Registros}

        <div style={{ height: 20 }}></div>

        {this.state.error === true ? AlertloginFailed : ''}


      </div >
    );
  }

}

export default GestionEmpresas;
