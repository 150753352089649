import React from 'react';

import Header from '../component/header';
import ListadoCodigos from './entradas/listadoCodigos';

import '../css/style.css';
import HeaderMarcoNegro from '../component/headerMarcoNegro';


class Main extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      openDrawer: false,
    }

    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.handleDrawerClose = this.handleDrawerClose.bind(this);
  }

  componentDidMount() {
  }

  handleDrawerOpen = () => {
    this.setState({ openDrawer: true });
  };
  handleDrawerClose = () => {
    this.setState({ openDrawer: false });
  };

  render() {

    return (
      <div className="main">

        <Header title="Musical Pass" history={this.props.history}></Header>

        <HeaderMarcoNegro />

        <div style={{ height: 40 }}></div>

        <ListadoCodigos />

      </div >
    );
  }

}

export default Main;
