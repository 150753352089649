import React from 'react';
import { Link } from 'react-router-dom';

import { Button, Container, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Grow from '@material-ui/core/Grow';
import Avatar from '@material-ui/core/Avatar';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import Header from '../../component/header';

import { Usuarios } from '../../entity/usuarios';
import { Constantes } from '../../util/constantes';

import '../../css/style.css';

import PopUpInfo from '../../component/popUpInfo';
import EventoView from '../eventos/eventoView';
import Loading from '../../component/loading';
import EventoResumenView from '../eventos/eventoResumenView';
import HeaderMarcoNegro from '../../component/headerMarcoNegro';

class EntradasCategorias extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      errorText: '',

      showResumen: false,
      resumen: undefined,

      // si el usuario tieen entradas pendientes por asignar
      entradasCategorias: [],

      //si hay eventos disponibles para que el usuario elija las entradas
      eventosCategorias: [],
      eventosCategoriasDetalle: [],
    }

    this.getEntradasCategorias = this.getEntradasCategorias.bind(this);
    this.getEventosDisponiblesCategorias = this.getEventosDisponiblesCategorias.bind(this);
    this.asigarEntradaDeCategoria = this.asigarEntradaDeCategoria.bind(this);

    this.setError = this.setError.bind(this);
  }

  componentDidMount() {
    this.getEntradasCategorias();
  }

  getEntradasCategorias() {
    Usuarios.getEntradasCategorias()
      .then(
        data => {
          if (data.records) {
            if (data.records.length > 0) {

              this.setState({ entradasCategorias: data.records.filter(x => (parseInt(x.entradasAsignadas) - parseInt(x.entradasSeleccionadas)) > 0) });

              this.getEventosDisponiblesCategorias({ categoria: data.records[0].ideventosCategorias });
            }
            else {
              window.location.href = "/main";
            }
          }
        }
      );
  }

  getEventosDisponiblesCategorias(formulario) {
    Usuarios.getEvetosDisponiblesCategoria(formulario)
      .then(
        data => {
          if (data.records) {
            if (data.records.length > 0) {

              // obtenemos el resumen de los eventos disponibles
              let detalle = data.records;

              let eventos = [];
              detalle.forEach(element => {
                if (eventos.filter(x => x.ideventos == element.ideventos && x.idcentros === element.idcentros).length === 0) {
                  eventos.push({
                    ideventos: element.ideventos,
                    idcentros: element.idcentros,
                    evento: element.evento,
                    centro: element.centro,
                    direccion: element.direccion,
                    eventoimagen: element.eventoimagen,
                    libres: element.libres,
                    jsonDataID: element.jsonDataID
                  });
                }
                else {
                  // sumno las entradas libres
                  eventos.forEach((evento, indexEvento) => {
                    if (evento.ideventos === element.ideventos && evento.idcentros === element.idcentros) {
                      eventos[indexEvento].libres = parseInt(eventos[indexEvento].libres) + parseInt(element.libres);
                    }
                  });
                }
              });

              this.setState({ eventosCategorias: eventos, eventosCategoriasDetalle: detalle });
            }
            else {
              this.setState({ eventosCategorias: [], eventosCategoriasDetalle: [] });
            }
          }
        }
      );
  }


  asigarEntradaDeCategoria(evento) {


    this.setState({ loading: true });

    Usuarios.obtenerEntradaDeCategoriaDisponible(evento)
      .then(
        data => {
          this.setState({ loading: false });

          if (data === true) {
            this.setState({ showResumen: true });
          }
          else {
            this.setState({ error: true, errorText: data.message });
          }
        }
      );

  }

  setError(texto) {
    if (texto != undefined && texto.length > 0) {
      this.setState({ error: true, errorText: texto });
    }

  }

  render() {

    const AlertloginFailed = <PopUpInfo messageInfo={this.state.errorText} onClose={() => { this.setState({ error: false, errorText: '' }) }} />

    return (
      <div>

        <Header title="Canje de Entradas" history={this.props.history}></Header>

        <HeaderMarcoNegro />

        <div style={{ height: 40 }}></div>

        <Container>

          <Box width={1}>

            {this.state.showResumen === false && this.state.resumen === undefined
              && this.state.entradasCategorias.map((element, index) => {
                let nuevas = (parseInt(element.entradasAsignadas) - parseInt(element.entradasSeleccionadas));

                return <Box key={index}>
                  <Box key={index} display='flex' justifyContent='center'>
                    <Button
                      component={Link} to="/entradascategorias"
                      variant="contained" color="primary">
                      {nuevas === 1 ? `Tiene una entrada pendiente de canjear` : `Tiene ${nuevas} entradas pendientes de canjear`}
                    </Button>
                  </Box>

                  <div style={{ height: 40 }}></div>

                  <Typography align="center" style={{ marginBottom: 30 }}>A continuación le indicamos los pasos a seguir:</Typography>

                  <Box display='flex' justifyContent='space-between' alignItems={'center'} flexWrap={'wrap'}>

                    <Box display="flex" alignItems='center' m={1} p={1} width={'20%'} minWidth={'300px'} bgcolor={'rgb(0 0 0 / 8%)'} borderRadius={15}>
                      <img src="./images/numero1.png" style={{ margin: 20 }} alt="Musical Pass" />
                      <Typography variant='body2'>Elija un evento de los que se muestran a continuación</Typography>
                    </Box>

                    <img src="./images/flecha.png" alt="Musical Pass" width={17} height={30} style={{ margin: '5px' }} />

                    <Box display="flex" alignItems='center' m={1} p={1} width={'20%'} minWidth={'300px'} bgcolor={'rgb(0 0 0 / 8%)'} borderRadius={15}>
                      <img src="./images/numero2.png" style={{ margin: 20 }} alt="Musical Pass" />
                      <Container>
                        <Typography variant='body2'>Indique el número de entradas para canjear</Typography>
                        <Typography style={{ color: 'red' }}>{`(máximo ${nuevas})`}</Typography>
                      </Container>
                    </Box>

                    <img src="./images/flecha.png" alt="Musical Pass" width={17} height={30} style={{ margin: '5px' }} />

                    <Box display="flex" alignItems='center' m={1} p={1} width={'20%'} minWidth={'300px'} bgcolor={'rgb(0 0 0 / 8%)'} borderRadius={15}>
                      <img src="./images/numero3.png" style={{ margin: 20 }} alt="Musical Pass" />
                      <Typography variant='body2'>Seleccione una de las fechas disponibles del evento</Typography>
                    </Box>

                    <img src="./images/flecha.png" alt="Musical Pass" width={17} height={30} style={{ margin: '5px' }} />

                    <Box display="flex" alignItems='center' m={1} p={1} width={'20%'} minWidth={'300px'} bgcolor={'rgb(0 0 0 / 8%)'} borderRadius={15}>
                      <img src="./images/numero4.png" style={{ margin: 20 }} alt="Musical Pass" />
                      <Container>
                        <Typography variant='body2'>Pulse el botón para</Typography>
                        <Typography variant='body2'>"Canjear Entradas"</Typography>
                      </Container>
                    </Box>

                  </Box>

                  <div style={{ height: 40 }}></div>

                  <Box>
                    <Typography align='center' variant='h6'>
                      Si deseas adquirir <span style={{ color: 'red' }}>entradas adicionales</span> al obsequio por favor envianos un correo a butacas@musical-pass.com
                    </Typography>
                  </Box>

                  <div style={{ height: 40 }}></div>

                  <Box position={'relative'}>
                    <hr style={{ position: 'absolute', width: '100%' }}></hr>
                    <Box display={'flex'} justifyContent={'center'} style={{ position: 'absolute', left: '0', right: 0, top: '-15px', backgroundColor: 'transparent' }}>
                      <Typography component={'div'} align="center" style={{ padding: 10, backgroundColor: 'var(--backgroundColor-body)' }}>Eventos disponibles</Typography>
                    </Box>
                  </Box>

                  <div style={{ height: 60 }}></div>

                  <Box display='flex' justifyContent='space-between' flexWrap='wrap' width={1}>
                    {
                      this.state.eventosCategorias.map((evento, indexEvento) => {
                        return <EventoView
                          item={evento}
                          detalle={this.state.eventosCategoriasDetalle.filter(x => x.ideventos === evento.ideventos && x.idcentros === evento.idcentros)}
                          entradas={element}
                          key={indexEvento}
                          setError={this.setError}
                          onClick={(data) => {

                            this.asigarEntradaDeCategoria(data);

                            evento.entradas = data.entradas;
                            evento.fechaEvento = data.fechaEvento;
                            evento.hora = data.hora;

                            this.setState({ resumen: evento });


                          }} />
                      })
                    }
                  </Box>

                </Box>
              })
            }

            {this.state.error === true ? AlertloginFailed : ''}

            <div style={{ height: 20 }}></div>

            {this.state.loading === false ? <div></div> : <Loading />}

            {
              this.state.showResumen === true && this.state.resumen !== undefined
                ? <EventoResumenView item={this.state.resumen} onClick={() => { window.location.href = "/main"; }} />
                : <div></div>
            }

          </Box>

        </Container>

        <div style={{ height: 40 }}></div>

      </div>
    );
  }

}

export default EntradasCategorias;
