import React from 'react';


import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';

import { Usuarios } from '../../entity/usuarios';
import { Divider } from '@material-ui/core';

import PopUpInfo from '../../component/popUpInfo';

class Registro extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      nombre: '',
      email: '',
      movil: '',
      usuario: '',
      direccion: '',
      idprovincias: '',
      cp: '',
      clave: '',
      clave2: '',
      errorLogin: false,
      errorText: '',
      codigo: '',

      provincias: []
    }


    this.onRegistro = this.onRegistro.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onBack = this.onBack.bind(this);
  }

  componentDidMount() {
  }


  onRegistro(event) {
    event.preventDefault();

    // la clave y repetición de clave deben ser iguales
    if (this.state.clave !== this.state.clave2) {
      this.setState({ errorText: "Indique claves iguales", errorLogin: true });
      return;
    }

    // el nombre es obligaotiro
    if (!this.state.nombre) {
      this.setState({ errorText: "Indique el nombre de usuario", errorLogin: true });
      return;
    }

    // el usuario es obligaotiro
    if (!this.state.usuario) {
      this.setState({ errorText: "Indique el usuario", errorLogin: true });
      return;
    }

    // el email es obligaotiro
    if (!this.state.email) {
      this.setState({ errorText: "Indique el email", errorLogin: true });
      return;
    }

    this.setState({ loading: true, errorLogin: false });


    let formulario = {
      nombre: this.state.nombre,
      email: this.state.email,
      movil: this.state.movil,
      direccion: this.state.direccion,
      idprovincias: this.state.idprovincias,
      cp: this.state.cp,
      usuario: this.state.usuario,
      clave: this.state.clave,
      clave2: this.state.clave2,
      codigo: this.state.codigo
    }

    Usuarios.registro(formulario)
      .then(
        user => {
          if (user === true) {
            //const { from } = this.props.location.state || { from: { pathname: "/login" } };
            //this.props.history.push(from);
            window.location.href = "/registrorealizado"
          }
          else {
            this.setState({ errorText: user.message, errorLogin: true, loading: false });
          }
        }
      );

  }

  onChangeInput(event) {
    this.setState({ [event.target.id]: event.target.value });
  }

  onBack() {
    window.history.back();
  }

  render() {
    const AlertloginFailed = <PopUpInfo messageInfo={this.state.errorText} onClose={() => { this.setState({ errorLogin: false, errorText: '' }) }} />

    return (
      <div className="login">

        <div style={{ height: 40 }}></div>

        <Container maxWidth="xs">

          <Box className='box-blanco'>

            <form noValidate autoComplete="off">

              <TextField
                margin="dense"
                variant="outlined"
                autoFocus
                required
                fullWidth
                id="nombre"
                label="Nombre"
                name="nombre"
                autoComplete="nombre"
                size='small'

                onChange={this.onChangeInput}
              />

              <TextField
                margin="dense"
                variant="outlined"
                fullWidth
                required
                id="email"
                label="Indique su Email"
                name="email"
                autoComplete="email"
                size='small'

                onChange={this.onChangeInput}
              />

              <TextField
                margin="dense"
                variant="outlined"
                fullWidth
                id="direccion"
                label="Direccion"
                name="direccion"
                autoComplete="direccion"
                size='small'

                onChange={this.onChangeInput}
              />

              <TextField
                margin="dense"
                variant="outlined"
                fullWidth
                id="cp"
                label="C.P."
                name="cp"
                autoComplete="cp"
                size='small'

                onChange={this.onChangeInput}
              />

              <TextField
                margin="dense"
                variant="outlined"
                fullWidth
                id="movil"
                label="Móvil"
                name="movil"
                autoComplete="movil"
                size='small'

                onChange={this.onChangeInput}
              />

              <TextField
                margin="dense"
                variant="outlined"
                required
                fullWidth
                id="usuario"
                label="Usuario"
                name="usuario"
                autoComplete="usuario"
                size='small'

                onChange={this.onChangeInput}
              />

              <Box display="flex">
                <TextField
                  margin="dense"
                  variant="outlined"
                  required
                  name="clave"
                  label="Clave"
                  type="password"
                  id="clave"
                  size='small'
                  style={{ margin: 5 }}

                  onChange={this.onChangeInput}
                />

                <TextField
                  margin="dense"
                  variant="outlined"
                  required
                  name="clave2"
                  label="Repetir Clave"
                  type="password"
                  id="clave2"
                  size='small'
                  style={{ margin: 5 }}

                  onChange={this.onChangeInput}
                />
              </Box>

              <div style={{ height: 20 }}></div>


              <TextField
                margin="dense"
                variant="outlined"
                fullWidth
                id="codigo"
                label="Código promocional"
                name="codigo"
                autoComplete="codigo"
                size="small"
                color='secondary'

                onChange={this.onChangeInput}
              />


              <div style={{ height: 20 }}></div>

              <Box display="flex" justifyContent="center">

                <Button
                  fullWidth
                  variant="contained"
                  style={{ margin: 5 }}
                  disabled={this.state.loading}
                  color="primary"
                  onClick={this.onRegistro}
                >
                  Registrar
                </Button>

                <Button
                  fullWidth
                  style={{ margin: 5 }}
                  variant="contained"
                  disabled={this.state.loading}
                  color="primary"
                  onClick={this.onBack}
                >
                  Volver
                </Button>

              </Box>
            </form>

            {this.state.errorLogin === true ? AlertloginFailed : ''}

          </Box>


        </Container>
      </div>
    );
  }
}

export default Registro;