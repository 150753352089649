import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HomeIcon from '@material-ui/icons/Home';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import EditIcon from '@material-ui/icons/Edit';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import DashboardIcon from '@material-ui/icons/Dashboard';
import EventIcon from '@material-ui/icons/Event';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import EventSeatIcon from '@material-ui/icons/EventSeat';
import EmailIcon from '@material-ui/icons/Email';

import { withStyles } from '@material-ui/core/styles';
import { deepOrange } from '@material-ui/core/colors';

import Avatar from '@material-ui/core/Avatar';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';

import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import '../css/style.css';
import { Link, Redirect } from 'react-router-dom';

import { Constantes } from '../util/constantes';
import { DialogContent } from '@material-ui/core';

import { Usuarios } from '../entity/usuarios';

const useStyles = theme => ({
  offset: theme.mixins.toolbar,
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
});

class Header extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      userLogged: JSON.parse(localStorage.getItem('userLogged')),
      registros: this.props.registros,
      openDrawer: false,
      userLoggedInfo: false,
      horaSimulacion: '',
      messageInfo: '',
      handleNew: this.props.handleNew,
    }

    this.onShowView = this.onShowView.bind(this);

    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.handleDrawerClose = this.handleDrawerClose.bind(this);

    this.handleShowuserLoggedInfo = this.handleShowuserLoggedInfo.bind(this);
    this.handleHideuserLoggedInfo = this.handleHideuserLoggedInfo.bind(this);

    this.handleSnackBarClose = this.handleSnackBarClose.bind(this);

  }

  componentDidMount() {
  }

  handleDrawerOpen = () => {
    this.setState({ openDrawer: true });
  };
  handleDrawerClose = () => {
    this.setState({ openDrawer: false });
  };

  handleShowuserLoggedInfo() {
    this.setState({ userLoggedInfo: true });
  }
  handleHideuserLoggedInfo() {
    this.setState({ userLoggedInfo: false });
  }

  onShowView(view) {
    return <Redirect to={"/" + view} />
    //window.location.href = "/" + view;
  }

  handleSnackBarClose() {
    this.setState({ messageInfo: '' });
  }

  render() {

    const { classes } = this.props;

    const infoDialog =
      (< Dialog aria-labelledby="simple-dialog-title" open={this.state.userLoggedInfo} onClose={this.handleHideuserLoggedInfo}>
        <DialogTitle>
          <Typography variant='h4' align='center'>Datos del usuario</Typography>
        </DialogTitle>
        <DialogContent>
          <List component="nav">

            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <Avatar alt={this.state.userLogged.nombre}>{this.state.userLogged.avatar}</Avatar>
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={this.state.userLogged.nombre} />
            </ListItem>

            <ListItem>
              <ListItemIcon><AccessTimeIcon /></ListItemIcon>
              <ListItemText primary={`Usuario desde el ${this.state.userLogged.fecharegistro}`} />
            </ListItem>


            <ListItem button component="a" href="editarperfil">
              <ListItemIcon><EditIcon /></ListItemIcon>
              <ListItemText>Editar perfil</ListItemText>
            </ListItem>

          </List>
        </DialogContent>
      </Dialog >);

    return (
      <div>

        <Snackbar open={this.state.messageInfo.length > 0 ? true : false}
          autoHideDuration={6000}
          onClose={this.handleSnackBarClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          key={this.state.userLoggedInfo}>
          <Alert severity="info">{this.state.messageInfo}</Alert>
        </Snackbar>

        {this.state.userLoggedInfo === true ? infoDialog : ''}

        <AppBar position="fixed" variant="outlined">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={this.handleDrawerOpen}
              edge="start">
              <MenuIcon style={{ color: 'black' }} />
            </IconButton>
            <Typography variant="h5" style={{ flexGrow: 1 }}>
              <Box>{this.props.title}
              </Box>
            </Typography>

            <Typography variant="h5">
              <Box>{this.state.userLogged.nombre}</Box>
            </Typography>


            <IconButton
              onClick={this.handleShowuserLoggedInfo}>
              <Avatar
                alt={this.state.userLogged.nombre}
                src={this.state.userLogged.imagen} />
            </IconButton>
          </Toolbar>
          <Box alignContent="center">
          </Box>

        </AppBar>

        <Drawer
          variant="persistent"
          anchor="left"
          open={this.state.openDrawer}
        >
          <div>
            <IconButton onClick={this.handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List>
            <ListItem button component="a" href="main">
              <ListItemIcon><HomeIcon /></ListItemIcon>
              <ListItemText primary={"Mis Entradas"} />
            </ListItem>

          </List>

          <Divider />

          <List>

            {
              Constantes.getUserLogged().idperfiles === 'USUARIO' ?
                (
                  <React.Fragment>

                    <ListItem button component="a" href="editarperfil">
                      <ListItemIcon><DoneOutlineIcon /></ListItemIcon>
                      <ListItemText primary={"Mi Cuenta"}></ListItemText>
                    </ListItem>

                  </React.Fragment>
                )
                : ''
            }

            <ListItem button component="a" href="reservas">
              <ListItemIcon><DoneOutlineIcon /></ListItemIcon>
              <ListItemText primary={"Reservas"}></ListItemText>
            </ListItem>

            {
              Constantes.getUserLogged().idperfiles !== 'USUARIO' ?
                (
                  <ListItem button component="a" href="dashboard">
                    <ListItemIcon><DashboardIcon /></ListItemIcon>
                    <ListItemText primary={"Dashboard"}></ListItemText>
                  </ListItem>
                )
                : ''
            }

            {
              Constantes.getUserLogged().idperfiles === 'ADMINISTRADOR' ?
                (
                  <React.Fragment>

                    <ListItem button component="a" href="gestioncentros">
                      <ListItemIcon><EventSeatIcon /></ListItemIcon>
                      <ListItemText primary={"Centros"}></ListItemText>
                    </ListItem>

                    <ListItem button component="a" href="gestioneventoscategorias">
                      <ListItemIcon><EventIcon /></ListItemIcon>
                      <ListItemText primary={"Categorías Eventos"}></ListItemText>
                    </ListItem>

                    <ListItem button component="a" href="gestioneventos">
                      <ListItemIcon><EventIcon /></ListItemIcon>
                      <ListItemText primary={"Eventos"}></ListItemText>
                    </ListItem>

                    <ListItem button component="a" href="gestionempresas">
                      <ListItemIcon><BusinessCenterIcon /></ListItemIcon>
                      <ListItemText primary={"Empresas"}></ListItemText>
                    </ListItem>

                    <ListItem button component="a" href="crearentradas">
                      <ListItemIcon><ShoppingCartIcon /></ListItemIcon>
                      <ListItemText primary={"Ordenes de Compra"}></ListItemText>
                    </ListItem>
                  </React.Fragment>
                )
                : ''
            }

            {
              Constantes.getUserLogged().idperfiles === 'ADMINISTRADOR' || Constantes.getUserLogged().idperfiles === 'EMPRESA' ?
                (
                  <React.Fragment>

                    <ListItem button component="a" href="gestionbundle">
                      <ListItemIcon><EventSeatIcon /></ListItemIcon>
                      <ListItemText primary={"Bundle"}></ListItemText>
                    </ListItem>

                    <ListItem button component="a" href="gestionemailplantilla">
                      <ListItemIcon><EmailIcon /></ListItemIcon>
                      <ListItemText primary={"Email Plantillas"}></ListItemText>
                    </ListItem>

                    <ListItem button component="a" href="gestionusuarios">
                      <ListItemIcon><AccountCircleIcon /></ListItemIcon>
                      <ListItemText primary={"Usuarios"}></ListItemText>
                    </ListItem>

                  </React.Fragment>
                )
                : ''
            }

            <ListItem button component="a" href="/" onClick={ () => Usuarios.logout()}>
              <ListItemIcon><ExitToAppIcon /></ListItemIcon>
              <ListItemText primary={"Salir"} />
            </ListItem>

          </List>
        </Drawer>

        <div className={classes.offset}></div>

      </div >
    );
  }

}

export default withStyles(useStyles)(Header);
