import React from 'react';

import Button from '@material-ui/core/Button';
import {FormControl, InputLabel, Select, MenuItem} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Grow from '@material-ui/core/Grow';
import Typography from '@material-ui/core/Typography';

import IconButton from '@material-ui/core/IconButton';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';

import PopUpInfo from '../../component/popUpInfo';

import '../../css/style.css';

class EditarEventoCategoria extends React.Component {

  constructor(props) {
    super(props);

    // para obtener la nueva imagen
    this.hiddenFileInput = React.createRef();

    this.state = {
      ideventosCategorias: this.props.item.ideventosCategorias,
      nombre: this.props.item.nombre,
    }

    this.onChangeInput = this.onChangeInput.bind(this);
  }

  componentDidMount() {
  }

  onChangeInput(event) {
    this.setState({ [event.target.id]: event.target.value });
  }


  onGuardar = () => {
    let data = new FormData()

    data.append('ideventosCategorias', this.state.ideventosCategorias);
    data.append('nombre', this.state.nombre);

    this.props.onGuardar(data);
  }

  onBack = () => {
    this.props.onBack();
  }

  render() {

    const AlertloginFailed = <PopUpInfo messageInfo={this.state.errorText} onClose={() => { this.setState({ error: false, errorText: '' }) }} />

    return (
      <Grow in={true} timeout={500}>
        <Box className="box-blanco">

          <div style={{ height: 20 }}></div>

          <TextField
            margin="dense"
            required
            fullWidth
            autoFocus
            id="nombre"
            label="Nombre"
            name="nombre"
            autoComplete="nombre"
            size='small'
            value={this.state.nombre}

            onChange={this.onChangeInput}
          />

          <div style={{ height: 20 }}></div>

          <Box display="flex" justifyContent="center">

            <Button
              fullWidth
              variant="contained"
              disabled={this.state.loading}
              color="primary"
              style={{ margin: 5 }}
              onClick={this.onGuardar}
            >
              Guardar
            </Button>

            <Button
              fullWidth
              variant="contained"
              color="primary"
              style={{ margin: 5 }}
              onClick={this.onBack}
            >
              Volver
            </Button>

          </Box>

        </Box>

      </Grow>
    );
  }

}

export default EditarEventoCategoria;
