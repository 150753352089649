import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HomeIcon from '@material-ui/icons/Home';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import EditIcon from '@material-ui/icons/Edit';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import DashboardIcon from '@material-ui/icons/Dashboard';
import EventIcon from '@material-ui/icons/Event';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import EventSeatIcon from '@material-ui/icons/EventSeat';
import EmailIcon from '@material-ui/icons/Email';

import { withStyles } from '@material-ui/core/styles';
import { deepOrange } from '@material-ui/core/colors';

import Avatar from '@material-ui/core/Avatar';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';

import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import '../css/style.css';
import { Link, Redirect } from 'react-router-dom';

import { Constantes } from '../util/constantes';
import { DialogContent } from '@material-ui/core';

class HeaderMarcoNegro extends React.Component {

  constructor(props) {
    super(props);

  }

  componentDidMount() {
  }

  render() {

    return (
      <Box width={1} height={145} bgcolor={'#2a2429'} position={'relative'}>
        <img src="./images/marca_agua.png" alt="Musical Pass" style={{ position: 'absolute' }} />
        <img src="./images/musical_pass_blanco.png" alt="Musical Pass" style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto' }} />
      </Box>
    );
  }

}

export default HeaderMarcoNegro;
