import React from 'react';
import MPheader from '../../component/MPheader/MPheader';
import MPfooter from '../../component/MPfooter/MPfooter';
import MPmain from './MPmain';
import '../../css/home.css';

class HomeV1 extends React.Component {

  render() {
    return (
      <div className="wrapper">
        <MPheader />
        <MPmain />
        <MPfooter />
      </div>
    );
  }
}

export default HomeV1;