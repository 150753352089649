import React from 'react';
import { Link } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import { Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grow from '@material-ui/core/Grow';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';


import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';


import List from '@material-ui/core/List';


import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

import '../../css/style.css';

import { Usuarios } from '../../entity/usuarios';

import PopUpInfo from '../../component/popUpInfo';
import Loading from '../../component/loading';
import CambioDeEvento from './cambioDeEvento';
import PopUpMasInfoEvento from '../../component/popUpMasInfoEvento';


class ListadoParaUsuarios extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      fechasDisponibles: [],

      error: false,
      errorText: '',

      loading: false,
      elementShow: undefined,

      cantidad: 1,

      pasaportes: [],
      entradasPorPasaporte: [],

      entradasCategorias: [],

      showPasaporteCultural: true,

      showListEventosCambio: false
    }

    this.getFechasDisponibles = this.getFechasDisponibles.bind(this);
    this.setFechaEvento = this.setFechaEvento.bind(this);
    this.cerrarSelectorDeFechas = this.cerrarSelectorDeFechas.bind(this);

    this.onChangeInput = this.onChangeInput.bind(this);

    this.solicitaCodigos = this.solicitaCodigos.bind(this);

    this.liberarEntrada = this.liberarEntrada.bind(this);

    this.renderElementShow = this.renderElementShow.bind(this);

    this.solicitarMasEntradas = this.solicitarMasEntradas.bind(this);

    this.closeEventosCambio = this.closeEventosCambio.bind(this);

    this.setError = this.setError.bind(this);

    this.getEntradasCategorias = this.getEntradasCategorias.bind(this);
  }

  componentDidMount() {

    Usuarios.getPasaportes()
      .then(
        data => {
          if (data.records) {
            if (data.records.length > 0) {
              this.setState({ pasaportes: data.records });
            }
            else {
              this.setState({ pasaportes: [], error: true, errorText: 'No tiene un pasaporte cultural' });
            }
          }
        }
      );

    this.getEntradasCategorias();

  }

  getEntradasCategorias() {
    Usuarios.getEntradasCategorias()
      .then(
        data => {
          if (data.records) {
            if (data.records.length > 0) {
              this.setState({ entradasCategorias: data.records });
            }
            else {
              this.setState({ entradasCategorias: [] });
            }
          }
        }
      );

  }


  onChangeInput(event) {
    this.setState({ [event.target.name]: event.target.value });
  }


  getFechasDisponibles(codigo) {
    Usuarios.getFechasDisponibles({ codigo: codigo })
      .then(
        codigos => {
          if (codigos.records) {
            if (codigos.records.length > 0) {
              this.setState({ fechasDisponibles: codigos.records });
            }
            else {
              this.setState({ fechasDisponibles: [], error: true, errorText: 'No quedan fechas disponibles' });
            }
          }
        }
      );
  }


  setFechaEvento(element) {

    this.setState({ loading: true });

    Usuarios.asignarFechaEventoACodigo(element)
      .then(
        codigos => {
          if (codigos === true) {
            this.setState({ loading: false, fechasDisponibles: [], elementShow: undefined, error: true, errorText: 'Entrada Canjeada' }, () => {
              this.props.onRefresh({});
            });
          }
          else {
            this.setState({ loading: false, error: true, errorText: codigos.message });
          }

        }
      );
  }


  cerrarSelectorDeFechas() {
    this.setState({ fechasDisponibles: [] });
  }

  solicitaCodigos(element) {
    let formulario = {
      codigo: element.codigo,
      cantidad: this.state.cantidad
    };

    this.setState({ fechasDisponibles: [], error: true, errorText: 'Solicitud tramitada' });

    Usuarios.solicitaCodigos(formulario)
      .then(() => {
        //this.props.onRefresh({})
        window.location.href = "/reservas";
      });
  }

  liberarEntrada(element) {

    this.setState({ loading: true });

    let formulario = {
      idcodigos: element.idcodigos
    };

    Usuarios.liberarEntrada(formulario)
      .then(() => {
        this.setState({ showPasaporteCultural: true, elementShow: undefined, entradasPorPasaporte: [], loading: false, error: true, errorText: 'Entrada liberada en la asignación de fecha' });
        this.props.onRefresh();
      });
  }


  renderElementShow() {
    return (
      <Box key={this.state.elementShow.codigo} display='flex' width={1} justifyContent='center'>

        <Box className='box-blanco' width={1}>

          <Box m={2}>
            <Button variant="contained" color="primary" onClick={() => this.setState({ elementShow: undefined })}>Volver</Button>
          </Box>

          <Box display='flex' flexWrap={'wrap'}>
            <Box width='250px' padding={1} display='flex' justifyContent='flex-start' alignItems={'center'} flexDirection={'column'}>
              <img src={this.state.elementShow.eventoimagen} alt="thumbnail" className="user-list__img" />
              <PopUpMasInfoEvento id={this.state.elementShow.jsonDataID} />
            </Box>

            <Box m={1}>
              <Typography variant='h5'>
                {this.state.elementShow.evento}
              </Typography>

              <Typography>
                {this.state.elementShow.centro}
              </Typography>

              <Typography>
                {this.state.elementShow.centrotelefono} {this.state.elementShow.centroemail}
              </Typography>

              <Typography>
                {this.state.elementShow.centrodireccion}
              </Typography>

              <Typography variant='h6'>
                {!this.state.elementShow.fechaevento ? '' : this.state.elementShow.fechaevento}
              </Typography>

            </Box>

            <Box m={1}>

              <Typography variant='h5'>
                {this.state.elementShow.codigo}
              </Typography>

              <Box display='flex' justifyContent='space-around' padding={2}>
                {this.state.elementShow.fechaevento
                  ?
                  <a href={this.state.elementShow.pk} target="blank">
                    <CloudDownloadIcon fontSize='large' color='secondary' />
                  </a>
                  : ''}

                {this.state.elementShow.fechaevento
                  ?
                  <a href={this.state.elementShow.pkpdf} target="blank">
                    <PictureAsPdfIcon fontSize='large' color='secondary' />
                  </a>
                  : ''}
              </Box>

            </Box>
          </Box>

          <div style={{ height: 20 }}></div>

          {
            // solo permitimos cambiar de evento y fecah si la entrada no está usada
            this.state.elementShow.estausado === 'NO'
              ? (
                <Box display='flex' justifyContent='center' mt={2} width={1}>
                  <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={() => this.setState({ showListEventosCambio: true })}>Cambiar de Evento</Button>
                  <Button variant="contained" color="primary" onClick={() => { this.getFechasDisponibles(this.state.elementShow.codigo) }}>Cambiar Fecha</Button>
                </Box>
              )
              : <div></div>
          }

          <div style={{ height: 20 }}></div>

          <Box m={1} display='flex' justifyContent='center'>
            {
              !this.state.elementShow.fechaevento
                ? <div></div>
                : (
                  <Box>
                    {this.solicitarMasEntradas(this.state.elementShow)}
                  </Box>
                )
            }

          </Box>

          <div style={{ height: 10 }}></div>

        </Box>
      </Box >
    );
  }

  solicitarMasEntradas(element) {
    return (
      <Box textAlign='center'>
        <div style={{ height: 20 }}></div>

        <Typography>{'¿Necesitas más entradas?'}</Typography>
        <Typography>{'Podemos gestionarlo por ti.'}</Typography>

        <div style={{ height: 20 }}></div>

        <TextField
          required
          id="cantidad"
          label="Entradas"
          name="cantidad"
          autoComplete="cantidad"
          size='small'
          value={this.state.cantidad}
          type='number'
          variant='outlined'
          style={{ marginRight: 10 }}
          onChange={this.onChangeInput}
        />

        <Button variant="contained" color="primary" onClick={() => { this.solicitaCodigos(element) }}>
          Solicita más Entradas
        </Button>

      </Box>
    )
  };

  closeEventosCambio() {
    this.setState({ showListEventosCambio: !this.state.showListEventosCambio });
  }

  setError(texto) {
    if (texto != undefined && texto.length > 0) {
      this.setState({ error: true, errorText: texto });
    }
  }

  render() {

    const AlertloginFailed = <PopUpInfo messageInfo={this.state.errorText} onClose={() => { this.setState({ error: false, errorText: '' }) }} />

    const SeleccionarFecha =
      (< Dialog aria-labelledby="simple-dialog-title" open={this.state.fechasDisponibles.length > 0} onClose={this.cerrarSelectorDeFechas}>
        <DialogTitle>Seleccione la fecha de asistencia</DialogTitle>
        <div>
          <List component="nav">
            {
              this.state.fechasDisponibles.map((element) => {
                return (
                  <div style={{ padding: 10 }} key={element.fechaevento + element.hora}>
                    <Button fullWidth variant="contained" color="secondary" onClick={this.setFechaEvento.bind(this, element)}>
                      {`${element.fecha} ( ${element.libres} libres )`}
                    </Button>
                    <div style={{ height: 10 }}></div>
                  </div>
                )
              })
            }
          </List>
        </div>
        <DialogActions>
          <Button fullWidth variant="contained" color="primary" onClick={this.cerrarSelectorDeFechas}>Cerrar</Button>
        </DialogActions>
      </Dialog >);

    const VistaCambioEvento =
      (<CambioDeEvento item={this.state.elementShow} onClose={this.closeEventosCambio} setError={this.setError} onRefresh={() => {
        this.setState({ showPasaporteCultural: true, elementShow: undefined, entradasPorPasaporte: [] });
        this.props.onRefresh();
      }} />);

    const Entradas = (
      <Box>
        <div style={{ height: 40 }}></div>

        {
          this.state.entradasCategorias.filter(x => (parseInt(x.entradasAsignadas) - parseInt(x.entradasSeleccionadas)) > 0).map((element, index) => {

            let nuevas = (parseInt(element.entradasAsignadas) - parseInt(element.entradasSeleccionadas));
            return <Box key={index} display='flex' justifyContent='center'>
              <Button
                component={Link} to="/entradascategorias"
                variant="contained" color="primary">
                {nuevas === 1 ? `Tiene una entrada pendiente de canjear` : `Tiene ${nuevas} entradas pendientes de canjear`}
              </Button>

            </Box>
          })
        }

        <div style={{ height: 40 }}></div>

        {this.state.loading === false ? SeleccionarFecha : <Loading />}

        {this.state.elementShow === undefined ?
          this.props.data.map((element, index) => {
            return (
              <Box key={index} display='flex' width={1}>

                <Box className='box-blanco' display='flex' flexWrap={'wrap'} justifyContent={'center'} alignItems='center' width={1}>

                  <Box width='250px' padding={1} display='flex' justifyContent='flex-start' alignItems={'center'} flexDirection={'column'}>
                    <img src={element.eventoimagen} alt="thumbnail" className="user-list__img" />
                    <PopUpMasInfoEvento id={element.jsonDataID} />
                  </Box>

                  <Box margin={1}>

                    <Typography variant='h5'>
                      {element.evento}
                    </Typography>

                    <Typography variant='h6' style={{ fontWeight: 'bold' }}>
                      {element.codigo}
                    </Typography>

                    <Typography>
                      {element.centro}
                    </Typography>

                    <Typography>
                      {`Estado: ${element.estausado === 'SI' ? 'Disfrutado' : 'Pendiente de disfrutar'}`}
                    </Typography>

                    <Typography>
                      {`Fecha ${element.estavalidado === 'SI' ? element.fechaevento : 'sin seleccionar'}`}
                    </Typography>

                  </Box>

                  <Box margin={1}>
                    <Button variant="contained" style={{ padding: 20 }} color="primary" onClick={() => this.setState({ elementShow: element })}>Ver Entrada</Button>
                  </Box>

                </Box>

              </Box>
            )
          })
          : <div></div>

        }

        {this.state.elementShow === undefined ?
          <Box>
            <div style={{ height: 40 }}></div>

            <Typography align='center' variant='h6'>
              Si deseas adquirir <span style={{ color: 'red' }}>entradas adicionales</span> al obsequio por favor envianos un correo a butacas@musical-pass.com
            </Typography>

          </Box>
          : <div></div>
        }


      </Box>
    );

    return (
      <Grow in={true} timeout={500}>

        <Container>

          {
            this.state.showListEventosCambio === false
              ?
              Entradas
              : <div></div>
          }

          {this.state.elementShow && this.state.showListEventosCambio === false
            ?
            this.renderElementShow()
            : <div></div>
          }

          {
            this.state.showListEventosCambio === true
              ?
              VistaCambioEvento
              : <div></div>
          }

          {this.state.error === true ? AlertloginFailed : ''}

          <div style={{ height: 40 }}></div>

        </Container>

      </Grow >
    );
  }

}

export default ListadoParaUsuarios;
